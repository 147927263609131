<template>
  <section class="e-kvslide" ref="kvSlideImg" :class="{ 'e-kvslide--relative': mode === 'relative' }">
    <div class="e-kvslideimg-out" ref="kvSlideImg_bg">
      <div class="e-kvslideimg" :style="'background-image:url(' + pageImage?.value?.src + ');'" v-if="mode != 'relative'" ref="kvSlideImg_bg_in" :class="{ 'e-kvslide--scale': addStatus == 1 }"></div>
    </div>
    <img v-bind="pageImage?.value" :alt="imageAlt" v-show="mode === 'relative'" loading="lazy" />
    <div class="e-kvslidebg" :class="{ 'e-kvslidebg--up': addStatus == 1 }" v-if="hasAnimation && (animate === 'moveon' || animate === 'basic')"></div>
    <div class="e-kvslide__triggerCenter" ref="triggerCenter"></div>
  </section>
</template>

<script>
import { debounce } from 'lodash';
import { reactive, toRefs, onMounted, onUnmounted, getCurrentInstance, computed, inject, watch } from 'vue';
import { getAdaptiveImageField } from '@/utils/site-utils';
import { getFileNameNoExt } from '@/utils/dom-utils';

export default {
  name: 'KvSlideBg',
  props: {
    image: {
      type: Object,
      default: () => {}
    },
    positionImage: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: String,
      default: ''
    },
    animate: {
      type: String,
      default: 'moveon'
    },
    parallax: {
      type: Boolean,
      default: false
    },
    hasAnimation: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    let ScrollMagic, controller, scene, tl;
    const { deviceState } = inject('device-common');
    const state = reactive({
      kvSlideImg: null,
      thisCount: 0,
      addStatus: 0,
      thisOfTop: 0,
      kvSlideImg_bg: null,
      kvSlideImg_bg_in: null,
      pageImage: props.image?.value ? props.image : props.image?.fields?.mobileImage,
      triggerCenter: null
    });
    const computes = {
      imageAlt: computed(() => {
        const { alt } = state.pageImage?.value || {};
        return getFileNameNoExt(alt);
      })
    };
    const _methods = {
      updateImage() {
        if (props.image?.value) {
          state.pageImage = props.image;
        } else {
          state.pageImage = getAdaptiveImageField(deviceState, props.image?.fields?.desktopImage, props.image?.fields?.mobileImage, props.image?.fields?.tabletImage);
        }
      }
    };
    const methods = {
      pageInit() {
        if (state.kvSlideImg_bg_in) {
          state.kvSlideImg_bg_in.style.height = state.kvSlideImg.clientHeight + 'px';
        }
      },
      onScroll(e) {
        if (props.hasAnimation) {
          let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

          let thisOfTopG = state.kvSlideImg?.getBoundingClientRect().top;

          if (props.animate === 'moveon' || props.animate === 'basic') {
            if (thisOfTopG < windowHeight * 1.5) {
              setTimeout(() => {
                state.addStatus = 1;
              }, 100);
            }

            if (thisOfTopG > windowHeight) {
              setTimeout(() => {
                state.addStatus = 0;
              }, 100);
            }
          }
        }
      },
      resizePageSet: debounce(async (e) => {
        if (state.kvSlideImg_bg_in) {
          state.kvSlideImg_bg_in.style.height = state.kvSlideImg.clientHeight + 'px';
        }

        if (controller && scene) {
          controller.updateScene(scene, true);
        }
      }, 300)
    };
    watch(
      () => deviceState,
      () => {
        _methods.updateImage();
      },
      {
        immediate: true
      }
    );
    onMounted(async () => {
      methods.pageInit();
      methods.onScroll();

      window.addEventListener('scroll', methods.onScroll);
      window.addEventListener('resize', methods.resizePageSet);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', methods.onScroll);
      window.removeEventListener('resize', methods.resizePageSet);
      if (scene) {
        scene.destroy();
        scene = null;
        controller.destroy();
        controller = null;
      }
    });

    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
.e-kvslide__triggerCenter {
  position: absolute;
  width: 100%;
  height: 60%;
  z-index: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
.e-kvslide {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: 0;
  top: 0;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &.e-kvslide--relative {
    position: relative;
  }
}
.e-kvslideimg-out {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
.e-kvslideimg-out--varHeight {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
.e-kvslideimg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  &.e-kvslide--scale {
    transform: scale(1.1);
    transition: transform 1s ease 0.2s;
  }
}
.e-kvslidebg {
  background: #101724;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  transition: height 1s;

  &.e-kvslidebg--up {
    height: 0;
  }
}
</style>
