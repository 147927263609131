<template>
  <div class="e-gee-captcha" ref="rootEl" />
</template>

<script>
/**
 * @typedef GeeCaptchaRef
 * @property {() => void} init
 * @property {() => GeeTestValidateResult} validate
 * @property {() => void} reset
 * */
/**
 * @typedef GeeTestValidateResult
 * */
import { getCurrentInstance, onMounted, reactive, toRefs, ref } from 'vue';
import { getGeeTestCaptchaId, getPageLanguage, initializeGeeTest, validateGeeTest } from '@/utils/site-utils';
import { toCamelObject } from '@/utils/obj-utils';

export default {
  name: 'GeeCaptcha',
  props: {
    captchaProduct: {
      type: String,
      default: 'bind'
    },
    hideSuccess: {
      type: Boolean,
      default: true
    },
    hideBar: {
      type: Array,
      default() {
        return ['close', 'refresh'];
      }
    },
    captchaName: {
      type: String,
      default: 'web-common'
    },
    autoInit: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    let captchaId = null;
    let captcha = null;
    const MAX_RETRY_TIMES = 400;
    const validateResult = ref(null);
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const methods = {
      async init() {
        const page = $jss.routeData();
        captchaId = getGeeTestCaptchaId(page, props.captchaName);
        const onSuccess = (data) => {
          const result = toCamelObject(data);
          result.platform = props.captchaName;
          validateResult.value = result;
        };
        captcha = await initializeGeeTest(
          page,
          captchaId,
          props.captchaProduct,
          state.rootEl,
          {
            hideSuccess: props.hideSuccess,
            hideBar: props.hideBar
          },
          onSuccess
        );
      },
      /**
       * @return {Promise<unknown>}
       */
      validate() {
        let retryTimes = 0;
        try {
          validateResult.value = null;
          captcha.showCaptcha();
          return new Promise((resolve) => {
            let interval = setInterval(() => {
              if (validateResult.value || retryTimes >= MAX_RETRY_TIMES) {
                // console.log('validateResult.value', validateResult.value);
                clearInterval(interval);
                resolve(validateResult.value);
              }
            }, 500);
          });
        } catch (ex) {
          console.log(ex);
        }
      },
      reset() {
        if (!captcha) return;
        captcha.reset();
      }
    };
    const state = reactive({
      rootEl: null
    });
    onMounted(() => {
      if (props.autoInit) {
        methods.init().catch();
      }
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss"></style>
