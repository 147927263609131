import { onBeforeUnmount, onMounted } from 'vue';

export const onWidthChanged = ({ callback }) => {
  let width = 0;
  const _methods = {
    onResize: () => {
      const _width = document.body.scrollWidth;
      if (width !== _width) {
        width = _width;
        callback && callback(_width);
      }
    }
  };
  onMounted(() => {
    width = document.body.scrollWidth;
    global.addEventListener('resize', _methods.onResize, { passive: true });
  });
  onBeforeUnmount(() => {
    global.removeEventListener('resize', _methods.onResize, { passive: true });
  });
};
