<template>
  <div class="e-avatar">
    <img :src="src" :alt="userName" v-if="src" />
    <span v-else>{{ shortName }}</span>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'avatar',
  props: {
    src: {
      type: String
    },
    userName: {
      type: String
    }
  },
  setup(props) {
    const computes = {
      shortName: computed(() => {
        return props.userName
          ? props.userName
              .split(' ')
              .map((x) => x[0].toUpperCase())
              .join('')
          : null;
      })
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: $grey;
  display: inline-block;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  span {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    line-height: 1;
  }
}
</style>
