<script setup>
import { computed, ref, inject, nextTick } from 'vue';
import { ifEmpty } from '@/utils/string-utils';
import svgIcons from '@/svg-icons';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { sitecoreProps } from '@/utils/constants';
import { getScrollTop } from '@/utils/dom-utils';

const emits = defineEmits(['menu-clicked']);
const props = defineProps({
  fields: {
    type: Object
  },
  asModalInMobile: {
    type: Boolean
  },
  position: {
    type: String,
    default: 'bottom-left'
  },
  ...sitecoreProps
});
console.log('props.fields', props.fields);
let blurTimeout;
const { /**@type DeviceComputes*/ deviceComputes } = inject('device-common');
const active = ref(false);
const rootEl = ref();
const modalRef = ref();
const menuOptions = computed(() => {
  return props.fields.items.map((x) => ({
    code: x.fields?.code.value,
    name: x.fields?.name.value,
    icon: x.fields?.icon.value?.svgCode
  }));
});
const toggleMenu = () => {
  if (deviceComputes.isMobileOrTablet.value && props.asModalInMobile) {
    modalRef.value.open();
  } else {
    active.value = !active.value;
    if (active.value) {
      nextTick(() => {
        checkContent();
      });
    }
  }
};
const onBlur = () => {
  if (blurTimeout) clearTimeout(blurTimeout);
  if (!props.asModalInMobile || !deviceComputes.isMobileOrTablet.value) {
    blurTimeout = setTimeout(() => {
      active.value = false;
    }, 100);
  }
};
const checkContent = () => {
  const optionsEl = rootEl.value?.querySelector('.s-pop-menu__items');
  const rect = optionsEl?.getBoundingClientRect();
  const scrollTop = getScrollTop();
  if (isNullOrEmpty(rect)) return;
  if (rect.bottom > global.innerHeight) {
    global.scrollTo({
      top: rect.bottom + scrollTop - global.innerHeight
    });
  }
};
const onMenuClick = (menu) => {
  toggleMenu();
  emits('menu-clicked', menu);
};
</script>

<template>
  <div class="s-pop-menu" :class="[{ active }]" tabindex="-1" v-if="fields" @blur="onBlur" ref="rootEl">
    <div class="s-pop-menu__name" @click="toggleMenu">
      <slot name="name" v-if="$slots.name" />
      <div class="s-pop-menu__name-icon" v-else>
        <span />
        <span />
        <span />
      </div>
    </div>
    <transition name="options-ani">
      <ul class="s-pop-menu__items" :class="[{ [`pos-${position}`]: !$isNullOrEmpty(position) }]" v-if="($deviceComputes.largeThanTablet.value || !props.asModalInMobile) && active">
        <li class="s-pop-menu__item" v-for="(menu, index) of menuOptions" :key="index" @click="onMenuClick(menu)">
          <icon :svg="menu.icon" size="tiny" />
          <span class="s-pop-menu__item-text" v-html="menu.name" />
        </li>
      </ul>
    </transition>
    <modal class="s-pop-menu__modal" ref="modalRef" animation="bottom-slide-in" closable>
      <ul class="s-pop-menu__items">
        <li class="s-pop-menu__item" v-for="(menu, index) of menuOptions" :key="index" @click="onMenuClick(menu)">
          <icon :svg="menu.icon" size="tiny" />
          <span class="s-pop-menu__item-text" v-html="menu.name" />
        </li>
      </ul>
    </modal>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-pop-menu {
  $c: &;
  $m: '.e-modal';
  position: relative;
  width: 20px;
  height: 20px;
  &__name {
    width: 20px;
    cursor: pointer;
    &-icon {
      width: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      span {
        display: block;
        width: 4px;
        height: 4px;
        background-color: $black;
      }
    }
  }
  &__items {
    position: absolute;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    border: 1px solid $grey-neutral;
    border-radius: 2px;
    backdrop-filter: blur(20px);
    background: radial-gradient(49.87% 50% at 50.13% 100%, rgba(46, 46, 46, 0.8) 0%, #000000 100%);
    color: $white;
    padding: 0;
    &.pos- {
      &bottom-left {
        top: calc(100% + 4px);
        right: 8px;
      }
    }
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 12px;
    cursor: pointer;
    .e-icon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      color: $yellow;
    }
    &-text {
      flex-grow: 1;
      white-space: nowrap;
    }
    &:hover {
      color: $yellow;
    }
    & + & {
      border-top: 1px solid $grey-neutral;
    }
  }
  &__modal {
    align-items: flex-end;
    #{$m}__content {
      width: 100%;
      color: $white;
    }
    #{$c}__items {
      position: static;
      border: none;
      padding-top: 40px;
    }
    #{$c}__item {
      justify-content: center;
      &-text {
        flex-grow: unset;
      }
    }
  }
  &.active {
    z-index: 5;
  }
}
</style>
