<template>
  <teleport to="body" tag="div" ref="rootEl">
    <div class="e-modal" :class="[{ [`ani-${animation}`]: animation }]" v-bind="$attrs" v-show="show" v-if="!removeOnHidden || show">
      <div class="e-modal__mask" ref="maskEl" @click="onMaskClick" />
      <div class="e-modal__content" :class="[`theme-${theme}`, ...$handleComClass(contentClass), { hasLabel: closeLabel }]" ref="contentEl">
        <Icon class="e-modal__close" name="close" @click="onClose" v-if="closable" />
        <div class="e-modal__close-label" v-else-if="closeLabel" @click="onClose">
          <span class="e-modal__close-label-text">{{ $tu('Close') }}</span>
          <Icon class="e-modal__close-label-icon" name="close" size="tiny" />
        </div>
        <slot />
      </div>
    </div>
  </teleport>
</template>

<script>
import { getCurrentInstance, nextTick, reactive, toRefs } from 'vue';
import gsap from 'gsap';
import modalAnimations from '@/utils/modal-animations';
import { gtmPush } from '@/utils/gtm-utils';
import { addModalClass, largeThanTablet, removeModalClasses } from '@/utils/dom-utils';
import { sitecoreProps } from '@/utils/constants';
import { getGlobalConfigs } from '@/utils/site-utils';
import modalAnimationsRtl from '@/utils/modal-animations-rtl';
export default {
  name: 'Modal',
  inheritAttrs: false,
  emits: ['opened', 'close-click', 'closed', 'mask-click'],
  props: {
    removeOnHidden: {
      type: Boolean,
      default: true
    },
    closable: {
      type: Boolean,
      default: false
    },
    closeLabel: {
      type: Boolean,
      default: false
    },
    animation: {
      type: String,
      default: 'bottom-fade-in'
    },
    theme: {
      type: String,
      default: 'white'
    },
    fireGtm: {
      type: Boolean,
      default: true
    },
    sticky: {
      type: Boolean,
      default: true
    },
    contentClass: null,
    ...sitecoreProps
  },
  setup(props, ctx) {
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const state = reactive({
      rootEl: null,
      maskEl: null,
      contentEl: null,
      show: false,
      showMask: false,
      showContent: false
    });
    const methods = {
      largeThanTablet,
      open(options) {
        const page = $jss.routeData();
        const [rtl] = getGlobalConfigs(page, 'rtl');
        state.show = true;
        gtmPush({
          event: 'modal_open'
        });
        addModalClass('modal-open');
        const animations = (rtl?.value ? modalAnimationsRtl[props.animation] : modalAnimations[props.animation]) ?? modalAnimations[props.animation];
        return new Promise((resolve) => {
          nextTick(() => {
            if (!state.contentEl || !state.maskEl) {
              resolve();
              return;
            }
            gsap.killTweensOf([state.contentEl, state.maskEl]);
            const tl = gsap.timeline({
              paused: true
            });
            tl.to(
              state.maskEl,
              {
                opacity: 1,
                onComplete() {
                  resolve();
                  ctx.emit('opened');
                }
              },
              'first'
            );
            tl.fromTo(state.contentEl, animations.in.from, { ...(options || {}), ...animations.in.to }, 'first').play();
          });
        });
      },
      close(options) {
        const page = $jss.routeData();
        const [rtl] = getGlobalConfigs(page, 'rtl');
        removeModalClasses('modal-open');
        if (props.fireGtm) {
          gtmPush({
            event: 'modal_close'
          });
        }
        return new Promise((resolve) => {
          if (!state.contentEl || !state.maskEl) {
            resolve();
            return;
          }
          const animations = (rtl?.value ? modalAnimationsRtl[props.animation] : modalAnimations[props.animation]) ?? modalAnimations[props.animation];
          const outAnimations = animations.out ?? {
            from: animations.in.to,
            to: animations.in.from
          };
          gsap.killTweensOf([state.contentEl, state.maskEl]);
          const tl = gsap.timeline({
            paused: true
          });
          tl.fromTo(state.contentEl, outAnimations.from, { ...(options || {}), ...outAnimations.to }, 'first');
          tl.to(
            state.maskEl,
            {
              opacity: 0,
              onComplete() {
                state.show = false;
                resolve();
                ctx.emit('closed');
              }
            },
            'first'
          );
          tl.play();
        });
      },
      onMaskClick() {
        ctx.emit('mask-click');
        if (!props.sticky) {
          ctx.emit('close-click');
          methods.close();
        }
      },
      onClose() {
        ctx.emit('close-click');
        methods.close();
      }
    };
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-modal {
  $c: &;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.5);
    backdrop-filter: blur(10px);
    opacity: 0;
  }
  &__content {
    background: $white;
    color: $black;
    max-height: 100%;
    overflow-y: auto;
    transform: translateY(100%);
    position: relative;
    @include component-themes;
    &.hasLabel {
      overflow: initial;
    }
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;
    color: currentColor;
    .e-icon {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    &-label {
      position: absolute;
      top: 0;
      right: 0;
      width: fit-content;
      padding: 12px 32px;
      transform: translateY(-100%);
      background-color: $black;
      color: $white;
      display: flex;
      align-items: center;
      gap: 24px;
      cursor: pointer;
      &-text {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  &.ani-right-skew-in {
    justify-content: flex-end;
    #{$c}__content {
      width: 100%;
      transform: translate(150%, 0);
    }
  }
  &.ani-left-slide-in {
    justify-content: flex-start;
    #{$c}__close {
      top: 32px;
      right: 28px;
    }
    #{$c}__content {
      height: 100vh;
      width: 100%;
      padding-top: 96px;
    }
  }
  &.ani-right-slide-in {
    justify-content: flex-end;
    #{$c}__close {
      top: 32px;
      right: 28px;
    }
    #{$c}__content {
      height: 100vh;
      width: 100%;
      padding-top: 96px;
    }
  }
  @include tablet-landscape {
    &__close {
      top: 20px;
      right: 20px;
      &-label {
        top: 52px;
        right: -102px;
        transform: rotate(-90deg);
      }
    }
    &__content {
      width: grid-width(20);
    }
    &.ani-left-slide-in {
      #{$c}__content {
        width: grid-width(8);
        min-width: 500px;
        max-width: 595px;
      }
    }
    &.ani-right-slide-in {
      #{$c}__content {
        width: grid-width(8);
        min-width: 500px;
        max-width: 595px;
      }
    }
    &.ani-right-skew-in {
      justify-content: flex-end;
      #{$c}__content {
        width: grid-width(10);
        transform: translate(150%, 0);
      }
    }
  }
}
</style>
