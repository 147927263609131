<script setup>
import { computed, getCurrentInstance, inject, nextTick, ref } from 'vue';
import api from '@/api';
import useAppStore from '@/store/appStore';
import { PAYMENT_TYPE, sitecoreProps, STRIPE_PAYMENT_TYPE } from '@/utils/constants';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { loadStripe } from '@/utils/stripe-utils';
import { appendQuery } from '@/utils/uri-utils';
import querystring from 'querystring';
import { equalString } from '@/utils/string-utils';

const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreProps
});
let confirmPayment;
const { proxy } = getCurrentInstance();
const { $jss } = proxy;
/**@type AppStore*/
const appStore = useAppStore();
const toast = inject('toast');
const loading = ref(false);
const orderDetail = ref(null);
const totalPrice = ref(0);
const totalAmountPayable = ref(0);
const payInfo = ref(null);
const bankInfoKeys = computed(() => (isNullOrEmpty(props.fields?.bankInfoKeys?.value) ? Object.create(null) : querystring.parse(props.fields.bankInfoKeys.value)));
/**@type Ref<ModalRef>*/
const modalRef = ref(null);
const payWidgetRef = ref(null);
const openFinalPay = async (detail) => {
  orderDetail.value = detail;
  totalPrice.value = detail.additionalTaxInfo.find((x) => equalString(x.elementName, 'Total Price'));
  totalAmountPayable.value = totalPrice.value?.price - (detail?.salesOrderInfo?.totalAmountNumber ?? 0) - (detail?.financeAmount ?? 0);
  console.log('totalPrice', detail, totalPrice);
  loading.value = true;
  await modalRef.value.open();
  await nextTick();
  const [res, ex] = await api.order.getPayInfo(null, {
    oneId: detail?.userInfo?.lotusId,
    orderId: detail?.intactOrderDateDto.id,
    stripePaymentType: STRIPE_PAYMENT_TYPE.finalPay,
    payType: PAYMENT_TYPE.balancePayment
  });
  if (!res) {
    await modalRef.value.close();
    loading.value = false;
    toast.showEx(ex);
    return;
  }
  payInfo.value = res;
  loading.value = false;
};
const openFinalPayById = async (orderId) => {
  loading.value = true;
  const [resDetail, exDetail] = await api.order.intactDetail({
    orderId
  });
  if (!resDetail) {
    await modalRef.value.close();
    loading.value = false;
    toast.showEx(exDetail);
    return;
  }
  await openFinalPay(resDetail);
};
defineExpose({ openFinalPay, openFinalPayById });
</script>

<template>
  <modal class="s-payment-modal" animation="scale-fade-in" ref="modalRef" closable>
    <content-loading :loading="loading">
      <template v-if="payInfo?.transferBankInfo">
        <div class="s-payment-modal__price">{{ $formatInclMoney(totalAmountPayable, orderDetail?.currency) }}</div>
        <jss-rich-text class="s-payment-modal__title" :field="fields?.bankTitle" />
        <jss-rich-text class="s-payment-modal__desc" :field="fields?.bankDescription" />
        <div class="s-payment-modal__info">
          <div class="s-payment-modal__item">
            <div class="s-payment-modal__item__label">{{ $t('Order number') }}</div>
            <div class="s-payment-modal__item__value">{{ orderDetail?.salesOrderInfo?.orderId }}</div>
          </div>
          <div class="s-payment-modal__item" v-for="key of Object.keys(bankInfoKeys)" :key="key">
            <div class="s-payment-modal__item__label">{{ bankInfoKeys[key] }}</div>
            <div class="s-payment-modal__item__value">{{ payInfo.transferBankInfo[key] }}</div>
          </div>
        </div>
        <jss-rich-text class="c-order-details__body" :field="fields?.bankBody" />
      </template>
      <div ref="payWidgetRef" />
    </content-loading>
  </modal>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-payment-modal {
  .s-content-loading {
    min-height: 70vh;
    padding: 40px 16px;
    &.loading {
      overflow: hidden;
    }
  }
  &.e-modal {
    align-items: flex-end;
    .e-modal__mask {
      backdrop-filter: blur(50px);
    }
    .e-modal__close {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__price {
    @include h6;
    padding-bottom: 12px;
    border-bottom: 1px solid $yellow;
  }
  &__title {
    margin-top: 16px;
  }
  &__info {
    background: $grey-light;
    padding: 12px;
    margin-top: 16px;
  }
  &__body {
    margin-top: 16px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    & + & {
      margin-top: 8px;
    }
  }
  @include tablet-landscape {
    .s-content-loading {
      padding: 40px 24px;
      min-height: 300px;
    }
    &.e-modal {
      align-items: center;
      .e-modal__content {
        width: grid-width(8);
      }
      .e-modal__close {
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    &__price {
      @include h6;
    }
  }
}
</style>
