<template>
  <div v-bind="$data" class="e-background-image" :class="[...rootClasses]" ref="rootEl">
    <div class="e-background-image__img" :class="[{ 'background-image': isBackground }]" ref="imageEl">
      <jss-image :media="image" :alt="imageAlt" :style="imgStyle" v-if="visible" loading="lazy" />
    </div>
    <div class="e-background-image__content" v-if="$slots.default">
      <slot />
    </div>
    <div class="e-background-image__fullscreen-btn" v-if="fullscreenEnabled" @click="toggleFullScreen" @touchstart.passive="toggleFullScreen">
      <icon name="arrow-right" />
      <icon name="arrow-right" />
    </div>
    <teleport to="body">
      <div
        class="e-background-image__fullscreen-view"
        v-if="fullscreen"
        ref="fullscreenEl"
        @mousedown="onFullMousedown"
        @mousemove="onFullMousemove"
        @mouseup="onFullMouseup"
        @touchstart.passive="onFullTouchstart"
        @touchmove.passive="onFullTouchmove"
        @touchend.passive="onFullTouchend"
      >
        <div class="e-background-image__fullscreen-box" :class="[{ dragging }, { narrow: ratio <= 1.78 && !$deviceComputes.isMobileOrTablet.value }]" ref="fullBoxRef">
          <img
            class="e-background-image__fullscreen-img"
            :class="{ narrow: ratio <= 1.78 && !$deviceComputes.isMobileOrTablet.value }"
            :src="(fullImage ?? image)?.value?.src"
            :alt="(fullImage ?? image)?.value?.alt"
            loading="lazy"
          />
        </div>
        <div class="e-background-image__fullscreen-view-btn" @click="toggleFullScreen">
          <icon name="arrow-right" />
          <icon name="arrow-right" />
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, toRefs, watch } from 'vue';
import { loadScrollMagic } from '@/utils/site-utils';
import gsap from 'gsap';
import { gtmPush } from '@/utils/gtm-utils';
import { canUseDOM, getFileNameNoExt } from '@/utils/dom-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';

export default {
  name: 'BackgroundImage',
  props: {
    fullImage: {
      type: Object
    },
    image: {
      type: Object
    },
    position: {
      type: String,
      default: 'center'
    },
    hasAnimation: {
      type: Boolean,
      default: false
    },
    enlargement: {
      type: Boolean,
      default: false
    },
    parallax: {
      type: Boolean,
      default: false
    },
    enlarge: {
      type: Boolean,
      default: false
    },
    parallaxName: {
      type: String,
      default: ''
    },
    fullscreenEnabled: {
      type: Boolean,
      default: false
    },
    isBackground: {
      type: Boolean,
      default: true
    },
    lazyLoad: {
      type: Boolean,
      default: true
    },
    useContentHeight: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const state = reactive({
      rootEl: null,
      imageEl: null,
      visible: true,
      inScreen: false,
      outScreen: false,
      fullscreen: false,
      fullscreenEl: null,
      dragging: false,
      dragOffset: null,
      fullBoxRef: null
    });
    let ScrollMagic,
      controller,
      scene,
      tl,
      fullscreenAnimating = false;
    const computes = {
      ratio: computed(() => props.image?.value?.width / props.image?.value?.height),
      imageAlt: computed(() => {
        const { alt } = props.image?.value || {};
        return getFileNameNoExt(alt);
      }),
      imgStyle: computed(() => {
        return {
          objectPosition: props.position
        };
      }),
      rootClasses: computed(() => [
        {
          'has-animation': props.hasAnimation,
          'in-screen': state.inScreen,
          'out-screen': state.outScreen,
          enlargement: props.enlargement,
          fullscreen: state.fullscreen,
          'use-content-height': props.useContentHeight
        }
      ])
    };
    const judgeImage = () => {
      const rect = state.rootEl?.getBoundingClientRect();
      if (isNullOrEmpty(rect)) return;
      if (rect.top < window.innerHeight + 500) {
        state.visible = true;
      }
      if (props.hasAnimation) {
        state.inScreen = rect.top < window.innerHeight;
        state.outScreen = rect.bottom < 100;
      }
    };
    const slide = () => {
      ScrollMagic = loadScrollMagic();
      controller = new ScrollMagic.Controller();
      let triggerHook = 0;
      const imageOffset = state.imageEl.getBoundingClientRect();
      if (props.parallax) {
        gsap.to(state.imageEl, { scrollTrigger: state.imageEl, duration: 2, ease: 'cubic-bezier(0.265, 0.005, 0, 0.965)', scale: 1.25 });
        tl = gsap.timeline();
        if (props.parallaxName === 'modelHeroImage') {
          tl.to(state.imageEl, { y: 200 }, 'first').to(state.imageEl, { y: -100 }, 'second');
          scene = new ScrollMagic.Scene({
            triggerElement: state.rootEl,
            triggerHook,
            duration: imageOffset.height
          })
            .setTween(tl)
            .addTo(controller);
        } else {
          triggerHook = 'onEnter';
          tl.fromTo(state.imageEl, { y: '-12.5%' }, { y: '12.5%' }, 'first');
          scene = new ScrollMagic.Scene({
            triggerElement: state.rootEl,
            triggerHook,
            duration: window.innerHeight + imageOffset.height
          })
            .setTween(tl)
            .addTo(controller);
        }
      }
    };
    const onscroll = async (e) => {
      await nextTick();
      judgeImage();
    };
    const methods = {
      async toggleFullScreen(e) {
        e.stopPropagation();
        if (fullscreenAnimating) return;
        fullscreenAnimating = true;
        const fullscreen = !state.fullscreen;
        const rootRect = state.rootEl.getBoundingClientRect();
        gtmPush({
          event: fullscreen ? 'image_open' : 'image_close'
        });
        if (fullscreen) {
          document.documentElement.classList.add('modal-open');
          state.fullscreen = true;
          await nextTick();
          const tl = gsap.timeline();
          state.fullBoxRef.style.opacity = 0;
          tl.fromTo(
            state.fullscreenEl,
            {
              left: rootRect.left,
              top: rootRect.top,
              width: rootRect.width,
              height: rootRect.height,
              opacity: 0
            },
            {
              left: 0,
              top: 0,
              width: '100vw',
              height: '100vh',
              opacity: 1,
              duration: 1
            }
          );
          tl.fromTo(
            state.fullBoxRef,
            {
              opacity: 0
            },
            {
              opacity: 1,
              duration: 0.2
            }
          );
        } else {
          document.documentElement.classList.remove('modal-open');

          const tl = gsap.timeline();
          tl.to(state.fullBoxRef, {
            opacity: 0,
            duration: 0.2
          });
          tl.to(state.fullscreenEl, {
            left: rootRect.left,
            top: rootRect.top,
            width: rootRect.width,
            height: rootRect.height,
            opacity: 0,
            duration: 0.6
          });
          tl.then(() => {
            state.fullscreen = false;
            state.dragging = false;
          });
        }
        fullscreenAnimating = false;
      },
      onFullMousedown(e) {
        e.preventDefault();
        state.dragging = true;
        const { scrollLeft, scrollTop } = state.fullBoxRef;
        state.dragOffset = {
          left: scrollLeft + e.pageX,
          top: scrollTop + e.pageY
        };
      },
      onFullTouchstart(e) {
        e.preventDefault();
        if (e.touches[0].target.classList.contains('e-background-image__fullscreen-view-btn')) {
          methods.toggleFullScreen(e);
          return;
        }
        state.dragging = true;
        const { scrollLeft, scrollTop } = state.fullBoxRef;
        state.dragOffset = {
          left: scrollLeft + e.touches[0].pageX,
          top: scrollTop + e.touches[0].pageY
        };
      },
      onFullMousemove(e) {
        if (state.dragging) {
          state.fullBoxRef.scrollLeft = state.dragOffset.left - e.pageX;
          state.fullBoxRef.scrollTop = state.dragOffset.top - e.pageY;
        }
      },
      onFullTouchmove(e) {
        if (state.dragging) {
          state.fullBoxRef.scrollLeft = state.dragOffset.left - e.touches[0].pageX;
          state.fullBoxRef.scrollTop = state.dragOffset.top - e.touches[0].pageY;
        }
      },
      onFullMouseup(e) {
        state.dragOffset = null;
        state.dragging = false;
      },
      onFullTouchend(e) {
        state.dragOffset = null;
        state.dragging = false;
      },
      onTransEnd() {}
    };
    if (canUseDOM()) {
      state.visible = props.lazyLoad ? false : true;
    }
    onMounted(async () => {
      await nextTick();
      window.addEventListener('scroll', onscroll, { passive: false });
      judgeImage();
      if (props.enlarge) {
        slide();
      }
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onscroll);
      if (scene) {
        scene.destroy();
        scene = null;
        controller.destroy();
        controller = null;
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-background-image {
  $c: &;
  position: relative;
  overflow: hidden;
  &__img {
    width: 100%;
    height: auto;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    &.background-image {
      position: absolute;
      height: 100%;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      + #{$c}__content {
        position: relative;
        min-height: 100%;
        height: auto;
        overflow: visible;
      }
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    color: $white;
  }
  &__fullscreen-btn {
    position: absolute;
    right: $space-16;
    bottom: $space-16;
    color: $yellow;
    border: 1px solid $yellow;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: transform 600ms ease;
    .e-icon {
      position: absolute;
      left: calc(50% - 5px);
      top: calc(50% - 5px);
      transition: transform 600ms ease;
      svg {
        width: 10px;
        height: 10px;
      }
      &:first-child {
        transform: rotate(-135deg) translateX(8px);
      }
      &:last-child {
        transform: rotate(45deg) translateX(8px);
      }
    }
    &:hover {
      background-color: $yellow;
      color: $black;
      .e-icon {
        &:first-child {
          transform: rotate(-135deg) translateX(15px);
        }
        &:last-child {
          transform: rotate(45deg) translateX(15px);
        }
      }
    }
  }
  &__fullscreen-view {
    position: fixed;
    z-index: 12;
    background: $black;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    overflow: hidden;
  }
  &__fullscreen-box {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    background: $black;
    overflow-scrolling: touch;
    display: flex;
    align-items: center;
    &.dragging {
      cursor: grabbing;
    }
    &.narrow {
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
  &__fullscreen-img {
    min-height: 100%;
    width: auto;
    height: 100%;
    -webkit-user-drag: none;
    -moz-user-select: none;
    user-select: none;
    object-position: center;
    &.narrow {
      width: 100%;
      height: auto;
    }
  }
  &__fullscreen-view-btn {
    position: absolute;
    top: $space-20;
    // right: $space-20;
    left: $space-20;
    color: $yellow;
    border: 1px solid $yellow;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: transform 600ms ease;
    .e-icon {
      position: absolute;
      left: calc(50% - 5px);
      top: calc(50% - 5px);
      transition: transform 600ms ease;
      svg {
        width: 10px;
        height: 10px;
      }
      &:first-child {
        transform: rotate(-135deg) translateX(-15px);
      }
      &:last-child {
        transform: rotate(45deg) translateX(-15px);
      }
    }
    &:hover {
      background-color: $yellow;
      color: $black;
      .e-icon {
        &:first-child {
          transform: rotate(-135deg) translateX(-8px);
        }
        &:last-child {
          transform: rotate(45deg) translateX(-8px);
        }
      }
    }
  }
  &.use-content-height {
    #{$c}__content {
      position: relative;
      height: auto;
      overflow: visible;
    }
  }
  &.has-animation {
    #{$c}__img {
      &:before {
        content: '';
        display: block;
        height: 100%;
        background-color: rgba($blue-night, 1);
        box-shadow: 0 2px 2px $blue-night;
        transition: height 2s cubic-bezier(0.38, 0.015, 0, 0.995);
      }
    }
    &.in-screen {
      #{$c}__img {
        &:before {
          height: 0;
        }
      }
    }
    &.enlargement {
      &:hover {
        #{$c}__img {
          transform: scale(1.25);
        }
      }
    }
    &.enlargement {
      &.out-screen {
        #{$c}__img {
          transform: scale(1);
        }
      }
    }
    @keyframes bounce-In {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.25);
      }
    }
  }
}

@include tablet-landscape {
  .e-background-image {
    &__fullscreen-box {
      justify-content: center;
    }
  }
}
</style>
