import { onBeforeUnmount, onMounted } from 'vue';
import { isNull, isString } from '@/utils/obj-utils';

const onScrollToView = ({ offset, triggerOnInit = true, getContainer, callback }) => {
  let inView = false;
  const onScroll = () => {
    const container = getContainer();
    const winHeight = window.outerHeight;
    const rect = container.getBoundingClientRect();
    let offsetTop = 0.9 * winHeight;
    if (!isNull(offset)) {
      if (isString(offset) && offset.endsWith('%')) {
        offsetTop = (parseInt(offset) * winHeight) / 100;
      } else if (!isNaN(offset)) {
        offsetTop = parseInt(offset);
      }
    }
    // console.log('offsetTop', offsetTop, rect.top);
    const _inView = rect.top <= offsetTop;
    if (inView !== _inView) {
      inView = _inView;
      callback && callback(inView);
    }
  };
  onMounted(() => {
    if (triggerOnInit) {
      setTimeout(() => {
        onScroll();
      }, 100);
    }
    window.addEventListener('scroll', onScroll);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScroll);
  });
};
export default onScrollToView;
