<template>
  <div class="e-captcha-input" :class="[{ error: !$isNullOrEmpty(errorMsg), success: !$isNullOrEmpty(successMsg) }]">
    <div class="e-captcha-input__label" v-html="label" />
    <div class="e-captcha-input__boxes" ref="boxesRef">
      <input type="number" inputmode="numeric" @input="onInput($event, 0)" @keydown="onKeydown($event, 0)" />
      <input type="number" inputmode="numeric" @input="onInput($event, 1)" @keydown="onKeydown($event, 1)" />
      <input type="number" inputmode="numeric" @input="onInput($event, 2)" @keydown="onKeydown($event, 2)" />
      <input type="number" inputmode="numeric" @input="onInput($event, 3)" @keydown="onKeydown($event, 3)" />
      <input type="number" inputmode="numeric" @input="onInput($event, 4)" @keydown="onKeydown($event, 4)" />
      <input type="number" inputmode="numeric" @input="onInput($event, 5)" @keydown="onKeydown($event, 5)" />
    </div>
    <div class="e-captcha-input__messages" v-if="!$isNullOrEmpty(errorMsg) || !$isNullOrEmpty(successMsg) || !$isNullOrEmpty(infoMsg)">
      <div class="e-captcha-input__message error" v-if="!$isNullOrEmpty(errorMsg)">
        <icon class="e-captcha-input__message-icon" name="close" size="tiny" />
        <div class="e-captcha-input__message-body" v-html="errorMsg" />
      </div>
      <div class="e-captcha-input__message success" v-else-if="!$isNullOrEmpty(successMsg)">
        <icon class="e-captcha-input__message-icon" name="check" size="tiny" />
        <div class="e-captcha-input__message-body" v-html="successMsg" />
      </div>
      <div class="e-captcha-input__message info" v-html="infoMsg" v-if="!$isNullOrEmpty(infoMsg)" />
    </div>
    <div class="e-captcha-input__description" v-html="description" v-if="description" />
  </div>
</template>

<script>
/**
 * @typedef {FormFields} CaptchaInputFields
 * @property {SimpleField} label
 * @property {SimpleField} description
 * @property {SimpleField} errorMsg
 * @property {SimpleField} successMsg
 * @property {SimpleField} infoMsg
 * */
/**
 * @typedef CaptchaInputRef
 * @property {SetSuccessMsg} setSuccessMsg
 * @property {SetErrorMsg} setErrorMsg
 * @property {SetInfoMsg} setInfoMsg
 * */
/**
 * @callback SetSuccessMsg
 * @property {String} msg
 * */
/**
 * @callback SetErrorMsg
 * @property {String} msg
 * */
/**
 * @callback SetInfoMsg
 * @property {String} msg
 * */
import { reactive, toRefs } from 'vue';
import { isNullOrEmpty } from '@/utils/obj-utils';

export default {
  name: 'CaptchaInput',
  emits: ['update:value'],
  props: {
    value: {
      type: String
    },
    label: {
      type: String
    },
    description: {
      type: String
    }
  },
  setup(props, ctx) {
    const state = reactive({
      boxesRef: null,
      errorMsg: null,
      successMsg: null,
      infoMsg: null
    });
    const methods = {
      onInput(e, index) {
        const inputs = state.boxesRef.querySelectorAll('input');
        if (e.inputType === 'deleteContentBackward') {
          return;
        }
        let { value } = e.target;
        value = value.replace(/\D/gi, '');
        if (value === '') {
          e.target.value = '';
          return;
        }
        const len = value.length;
        if (len === 1) {
          if (index < 5) {
            inputs[index + 1].focus();
          } else {
            e.target.blur();
          }
        } else if (len <= 6 - index) {
          for (let i = index; i < 6; i++) {
            inputs[i].value = value[i - index] ?? null;
          }
        } else if (len >= 6) {
          for (let i = 0; i < 6; i++) {
            inputs[i].value = value[i] ?? null;
          }
        }
        let captchaVal = '';
        for (let i = 0; i < 6; i++) {
          captchaVal += inputs[i].value;
        }
        ctx.emit('update:value', captchaVal);
      },
      onKeydown(e, index) {
        const inputs = state.boxesRef.querySelectorAll('input');
        const keyCode = e.keyCode ?? e.which ?? e.charCode;
        if (keyCode === 8) {
          if (!isNullOrEmpty(e.target.value)) {
            e.target.value = '';
          } else {
            if (index > 0) {
              inputs[index - 1].value = '';
              inputs[index - 1].focus();
            }
          }
        }
        let captchaVal = '';
        for (let i = 0; i < 6; i++) {
          captchaVal += inputs[i].value;
        }
        ctx.emit('update:value', captchaVal);
      },
      setSuccessMsg(msg) {
        state.successMsg = msg;
        state.errorMsg = null;
        state.infoMsg = null;
      },
      setErrorMsg(msg) {
        state.successMsg = null;
        state.errorMsg = msg;
        state.infoMsg = null;
      },
      setInfoMsg(msg) {
        state.successMsg = null;
        state.errorMsg = null;
        state.infoMsg = msg;
      }
    };
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-captcha-input {
  $c: &;

  &__label {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 4px;
  }

  &__boxes {
    display: flex;
    width: 100%;

    input {
      @include h7;
      flex-grow: 1;
      flex-shrink: 0;
      width: 44px;
      //width: calc((100% - 12px * 5) / 6);
      height: 56px;
      border: 1px solid #000 !important;
      border-radius: 0;
      outline-width: 0 !important;
      padding: 0 !important;
      margin-bottom: 0 !important;
      text-align: center;
      appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
      + input {
        margin-left: 12px;
      }
    }
  }
  &__messages {
    margin-top: 20px;
  }
  &__message {
    display: flex;
    align-items: center;
    gap: 4px;
    line-height: 1;
    &.error {
      color: $red-medium;
    }
    &.success {
      color: $green;
    }
  }

  &__description {
    margin-top: 16px;
  }

  &.success {
    #{$c}__boxes {
      input {
        border-color: $green;
      }
    }
  }

  &.error {
    #{$c}__boxes {
      input {
        border-color: $red-medium;
      }
    }
  }
}
html.rtl {
  .e-captcha-input {
    &__boxes {
      input {
        + input {
          margin-left: 0;
          margin-right: 12px;
        }
      }
    }
    &__message {
      &-icon {
        float: right;
        margin-right: 0;
        margin-left: 12px;
      }
    }
  }
}
</style>
