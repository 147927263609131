<template>
  <span :class="['e-icon', { [`size-${size}`]: size, 'not-fill': !fill }]" v-html="code" v-if="code" ref="rootEl" />
  <span :class="['e-icon', { [`size-${size}`]: size, 'not-fill': !fill }]" v-else-if="!!$slots.default" ref="rootEl"><slot /></span>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';
import svgIcons from '../svg-icons';
/**@typedef Icon
 * @property {HTMLElement} rootEl
 * */
export default {
  name: 'Icon',
  props: {
    field: {
      type: Object
    },
    svg: {
      type: String,
      default: null
    },
    /**@type {String} [left|right]*/
    name: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    fill: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const state = reactive({
      svg: props.svg,
      rootEl: null
    });
    const computes = {
      code: computed(() => props.svg ?? props.field?.value?.svgCode ?? svgIcons[props.name])
    };
    return {
      ...toRefs(state),
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
.e-icon {
  display: inline-block;
  font-size: 0;
  letter-spacing: -3px;
  vertical-align: middle;
  > svg {
    display: inline-block;
    fill: currentColor;
    width: auto;
  }
  &.size- {
    &xs {
      height: 12px;
      > svg {
        height: 12px;
      }
    }
    &tiny {
      height: 16px;
      > svg {
        height: 16px;
      }
    }
    &small {
      height: 16px;
      > svg {
        height: 16px;
      }
    }
    &l {
      height: 18px;
      > svg {
        height: 18px;
      }
    }
    &xl {
      height: 20px;
      > svg {
        height: 20px;
      }
    }
    &large {
      height: 28px;
      > svg {
        height: 28px;
      }
    }
    &large-x {
      height: 30px;
      > svg {
        height: 30px;
      }
    }
    &auto {
      > svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &.not-fill {
    > svg {
      fill: none;
    }
  }
  @include desktop {
    &.size- {
      &tiny {
        height: 16px;
        > svg {
          height: 16px;
        }
      }
      &small {
        height: 24px;
        > svg {
          height: 24px;
        }
      }
      &large {
        height: 40px;
        > svg {
          height: 40px;
        }
      }
      &large-x {
        height: 56px;
        > svg {
          height: 56px;
        }
      }
    }
  }
}
</style>
