<script setup>
import { ref, watch } from 'vue';

const emit = defineEmits(['update:value', 'change']);
const props = defineProps({
  value: {
    type: Number
  },
  max: {
    type: Number,
    default: 5
  },
  disabled: {
    type: Boolean,
    default: false
  }
});
const val = ref(props.value);
const doMinus = () => {
  if (val.value > 1) {
    val.value -= 1;
    emit('update:value', val.value);
    emit('change', val.value);
  }
};
const doPlus = () => {
  if (isNaN(props.max) || val.value < props.max) {
    console.log('doPlus');
    val.value += 1;
    emit('update:value', val.value);
    emit('change', val.value);
  }
};
const onInput = (e) => {
  let value = parseInt(e.target.value);
  if (isNaN(value)) return;
  if (value < 1) value = 1;
  if (value > props.max) value = props.max;
  e.target.value = value;
  emit('update:value', value);
  emit('change', value);
};
const onBlur = (e) => {
  let value = parseInt(e.target.value);
  if (isNaN(value) || value < 1) value = 1;
  if (value > props.max) value = props.max;
  e.target.value = value;
  emit('update:value', value);
  emit('change', value);
};
watch(
  () => props.value,
  (_value) => {
    val.value = _value;
  }
);
</script>

<template>
  <div class="e-num-stepper">
    <icon :class="[{ disabled: val <= 1 }]" name="minus" @click="doMinus" />
    <input type="number" :value="val" @input="onInput" @blur="onBlur" />
    <icon :class="[{ disabled: val >= max }]" name="plus" @click="doPlus" />
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-num-stepper {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  vertical-align: middle;
  padding: 2px;
  border: 1px solid $black;
  width: fit-content;
  .e-icon {
    cursor: pointer;
    user-select: none;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.disabled {
      color: $grey-taubmans;
    }
  }
  > input {
    border: none;
    width: 36px;
    text-align: center;
    font-size: 14px;
  }
}
</style>
