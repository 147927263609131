<template>
  <div class="fade" ref="rootEl">
    <slot />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from 'vue';
import { loadScrollMagic } from '@/utils/site-utils';
import gsap from 'gsap';
export default {
  name: 'Fade',
  props: {
    direction: {
      type: String
    },
    delay: {
      type: Number
    },
    distance: {
      type: Number
    }
  },
  setup(props, context) {
    let ScrollMagic, controller, scene, tl, fadeDirection;
    const state = reactive({
      rootEl: null
    });
    ScrollMagic = loadScrollMagic();
    controller = new ScrollMagic.Controller();
    switch (props.direction) {
      case 'left':
        fadeDirection = { x: -props.distance };
        break;
      case 'right':
        fadeDirection = { x: props.distance };
        break;
      case 'up':
        fadeDirection = { y: props.distance };
        break;
      case 'down':
        fadeDirection = { y: -props.distance };
        break;
      default:
        fadeDirection = { x: 0 };
    }
    onMounted(() => {
      tl = gsap.fromTo(
        state.rootEl,
        { opacity: 0 },
        {
          ...fadeDirection,
          opacity: 1,
          duration: props.delay
        }
      );
      scene = new ScrollMagic.Scene({
        triggerElement: state.rootEl,
        triggerHook: 'onEnter',
        offset: 10,
        duration: state.rootEl.offsetHeight
      })
        .setTween(tl)
        .addTo(controller);
    });
    onUnmounted(() => {
      scene.destroy(true);
      controller.destroy(true);
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>
