<template>
  <div class="e-flipped-number" ref="rootEl">
    <div class="e-flipped-number__item" v-for="(n, index) in numbers" :key="index">
      <div class="e-flipped-number__other" v-if="isNaN(n)">
        {{ n }}
      </div>
      <div class="e-flipped-number__card" :data-number="n" v-else>
        <div class="e-flipped-number__card-flow">
          <span>0</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
import gsap from 'gsap';
import { isNull, isString } from '@/utils/obj-utils';
import { debounce } from 'lodash';

export default {
  name: 'FlippedNumber',
  props: {
    number: {
      type: String
    },
    offset: {
      default: '90%'
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    delay: {
      type: Number,
      default: 0
    },
    speed: {
      type: Number,
      default: 1000
    },
    container: {
      type: Object
    }
  },
  setup(props) {
    let tl,
      inView = false;
    const state = reactive({
      rootEl: null
    });
    const computes = {
      numbers: computed(() => {
        const result = [];
        for (let c of props.number) {
          if (isNaN(c)) {
            result.push(c);
          } else {
            result.push(Number(c));
          }
        }
        return result;
      })
    };
    const methods = {
      initCards(triggeredByResize) {
        inView = false;
        const cards = gsap.utils.toArray(state.rootEl.querySelectorAll('.e-flipped-number__card'));
        if (cards.length === 0) return;
        const cardRect = cards[0].getBoundingClientRect();
        tl = new gsap.timeline({
          paused: true
        });
        for (let card of cards) {
          const number = Number(card.dataset['number']);
          const cardFlow = card.querySelector('.e-flipped-number__card-flow');
          let cardHtml = '';
          for (let i = 0; i < 10; i++) {
            for (let j = 0; j < 10; j++) {
              cardHtml += `<span>${j}</span>`;
            }
          }
          card.style.height = cardRect.height + 'px';
          card.style.overflow = 'hidden';
          cardFlow.style.width = cardRect.width + 3 + 'px';
          cardFlow.innerHTML = cardHtml;
          gsap.killTweensOf(cardFlow);
          tl.fromTo(
            cardFlow,
            {
              translateY: 0
            },
            {
              translateY: -cardRect.height * (number + Math.max(Math.floor(Math.random() * 5), 1) * 10) + 'px',
              delay: triggeredByResize ? 0 : props.delay / 1000,
              duration: props.speed / 1000,
              onComplete() {
                cardFlow.innerHTML = `<span>${number}</span>`;
                cardFlow.style.transform = '';
              }
            },
            0
          );
        }
      },
      onScroll() {
        methods.checkPlayable();
      },
      checkPlayable() {
        const winHeight = window.outerHeight;
        const container = props.container ?? state.rootEl;
        const rect = container.getBoundingClientRect();
        let offsetTop = 0.9 * winHeight;
        if (!isNull(props.offset)) {
          if (isString(props.offset) && props.offset.endsWith('%')) {
            offsetTop = (parseInt(props.offset) * winHeight) / 100;
          } else if (!isNaN(props.offset)) {
            offsetTop = parseInt(props.offset);
          }
        }
        const _inView = rect.top <= offsetTop;
        if (inView !== _inView) {
          inView = _inView;
          if (inView) {
            methods.play();
          } else if (rect.bottom < 0 || rect.top > winHeight) {
            methods.reset();
          }
        }
      },
      play() {
        tl.play();
      },
      reset(triggeredByResize) {
        tl.pause();
        methods.initCards(triggeredByResize);
      },
      onResize: debounce(() => {
        methods.reset(true);
        methods.checkPlayable();
      }, 100)
    };
    onMounted(() => {
      methods.initCards();
      methods.onScroll();
      window.addEventListener('scroll', methods.onScroll);
      //window.addEventListener('resize', methods.onResize);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', methods.onScroll);
      //window.removeEventListener('resize', methods.onResize);
    });
    return {
      ...toRefs(state),
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-flipped-number {
  $c: &;
  display: flex;
  overflow: hidden;
  &__card {
    max-height: 64px;
  }
  &__card,
  &__other {
    line-height: 1;
  }
  &__card-flow {
    > span {
      display: block;
      text-align: center;
    }
  }
}
</style>
