<template>
  <component class="e-spinner" :is="spinner" :size="size" :class="$props['class']" />
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'Spinner',
  props: {
    name: {
      type: String,
      default: 'circle'
    },
    size: {
      type: String,
      default: null
    },
    class: null
  },
  setup(props) {
    const spinnerTypes = ['climbing', 'jumping', 'ball-beat', 'ball-grid-pulse', 'lotus'];
    const computes = {
      spinner: computed(() => {
        if (spinnerTypes.includes(props.name)) {
          return `spinner-${props.name}`;
        } else {
          return 'spinner-jumping';
        }
      })
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss"></style>
