<template>
  <site-button-old :fields="fields" v-bind="$props" @click="onOldClick" v-if="$isNullOrEmpty($settingValue(fields?.buttonType))" />
  <component
    :is="comType"
    v-bind="comAttrs"
    :class="rootClasses"
    v-else-if="fields && $appVisible($route, fields) && btnText"
    @touchstart="onTouchStart"
    @mousedown="onTouchStart"
    @click.passive="onClick"
  >
    <div class="e-site-button__loading" v-if="loading">
      <spinner name="ball-beat" size="small" />
    </div>
    <template v-else>
      <span class="e-site-button__text" v-html="btnText" />
      <slot class="e-site-button__icon" @transitionend="onIconTranEnd" v-if="$slots.icon" />
      <Icon class="e-site-button__icon" :field="fields.icon" @transitionend="onIconTranEnd" v-else-if="!$isNullOrEmpty(fields.icon?.value.svgCode)" />
      <Icon class="e-site-button__icon" :name="$ifEmpty(icon, 'forward')" @transitionend="onIconTranEnd" v-else />
    </template>
  </component>
</template>

<script>
import { computed, reactive, toRefs, watch, getCurrentInstance, inject, onMounted, onBeforeUnmount } from 'vue';
import { settingValue } from '@/utils/site-utils';
import { debounce, merge } from 'lodash';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { sitecoreProps } from '@/utils/constants';
import { gtmPush } from '@/utils/gtm-utils';
import { qtUtils } from '@/utils/ali-tracker-utils';
import * as querystring from 'querystring';
import SiteButtonOld from '@/sections/site-button-old';
import { equalString, ifEmpty } from '@/utils/string-utils';
import { isMouseSupported, isTouchable, openWindow } from '@/utils/dom-utils';
import { getBetterUrl } from '@/utils/uri-utils';

export default {
  name: 'SiteButton',
  components: { SiteButtonOld },
  emits: ['click'],
  props: {
    /**@type ButtonField*/
    fields: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    hasAnimation: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    qtData: {
      type: Object
    },
    moduleName: {
      type: String
    },
    ...sitecoreProps
  },
  setup(props, ctx) {
    let touchTimeout, clickTriggered;
    const { deviceState } = inject('device-common');
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const _methods = {
      getTheme: () => settingValue(props.fields?.buttonTheme, 'black'),
      getType: () => settingValue(props.fields?.buttonType, 'primary-button'),
      qtTrackClick() {
        /**@type SitePage */
        const page = $jss.routeData();
        const { aliQtEnabled } = props.fields || {};
        if (aliQtEnabled?.value) {
          const qtEventCode = qtUtils.getBtnCode(page, props);
          if (isNullOrEmpty(qtEventCode)) return;
          const qtParams = methods.getTrackParams();
          qtUtils.trackClick(qtEventCode, qtParams);
        }
      },
      clicking: debounce((e) => {
        if (props.disabled || props.loading) return false;
        _methods.qtTrackClick();
        gtmPush({
          event: `button_${state.type}_click`
        });
        ctx.emit('click', e);
        const { anchorId } = props.fields || {};
        if (!anchorId?.value) return;
        const anchor = document.getElementById(anchorId?.value);
        if (!anchor) return;
        const offset = anchor.getBoundingClientRect();
        window.scrollTo(0, offset.top);
      }, 150)
    };
    const state = reactive({
      theme: _methods.getTheme(),
      type: _methods.getType(),
      startY: 0
    });
    const computes = {
      btnText: computed(() => ifEmpty(props?.fields?.text?.value, props?.fields?.link?.value?.text)),
      comType: computed(() => (isNullOrEmpty(props?.fields?.link?.value?.href) ? 'button' : 'site-link')),
      comAttrs: computed(() => {
        if (!isNullOrEmpty(props?.fields?.link?.value?.href)) {
          const { value } = props?.fields?.link;
          let { href } = value;
          if (/http:\/\/(mailto|tel):/gi.test(href)) {
            href = href.replace(/http:\/\//gi, '');
          }
          return merge({}, ctx.attrs, {
            link: {
              value: {
                ...value,
                href
              }
            }
          });
        }
        return ctx.attrs;
      }),
      rootClasses: computed(() => {
        let size = props.size ?? settingValue(props.fields.size, 'medium');
        return [
          'e-site-button',
          state.type,
          `theme-${state.theme}`,
          {
            disabled: props.disabled,
            [`size-${size}`]: !isNullOrEmpty(size) && size !== 'medium',
            reverse: props?.fields?.reverse?.value ?? false,
            fullWidthOnMobile: props?.fields?.fullWidthOnMobile?.value ?? false,
            loading: props.loading,
            'with-ani': isNullOrEmpty(props?.fields?.hasAnimation?.value) ? true : props?.fields?.hasAnimation?.value
          }
        ];
      }),
      isSafari: computed(() => equalString(deviceState.browser, 'safari'))
    };
    const methods = {
      merge,
      onClick: debounce((e) => {
        if (clickTriggered) return false;
        if (!computes.isSafari.value) {
          _methods.clicking(e);
        }
      }, 110),
      onOldClick(e) {
        ctx.emit('click', e);
      },
      onIconTranEnd: debounce((e) => {
        // if (Math.abs(e.target.clientY - state.startY) > 60) return;
        // if (isTouchable() && computes.isSafari.value) {
        //   _methods.clicking(e);
        //   const { href, target } = props.fields?.link.value;
        //   if (!isNullOrEmpty(href)) {
        //     const url = getBetterUrl(href, null, true);
        //     openWindow(url, target);
        //   }
        // }
      }, 200),
      onTouchStart: debounce((e) => {
        if (!computes.isSafari.value) return;
        if (clickTriggered) return;
        clickTriggered = true;
        const startRect = e.target.getBoundingClientRect();
        const startY = startRect.top;
        if (touchTimeout) clearTimeout(touchTimeout);
        touchTimeout = setTimeout(() => {
          clickTriggered = false;
          const endRect = e.target.getBoundingClientRect();
          if (Math.abs(endRect.top - startY) > 50) return;
          e.preventDefault();
          _methods.clicking(e);
          const { href, target } = props.fields?.link?.value || {};
          if (!isNullOrEmpty(href)) {
            const url = getBetterUrl(href, null, true);
            openWindow(url, target);
          }
        }, 420);
      }, 100),
      onMouseup: debounce((e) => {
        if (clickTriggered) return false;
        if (!computes.isSafari.value) return;
        e.preventDefault();
        _methods.clicking(e);
        const { href, target } = props.fields?.link?.value || {};
        if (!isNullOrEmpty(href)) {
          const url = getBetterUrl(href, null, true);
          openWindow(url, target);
        }
        return false;
      }, 100),
      getTrackParams() {
        const { aliQtParams } = props.fields || {};
        const qtParams = {
          button_id: props.id ?? props?.fields?.link?.value.href,
          button_name: props.name ?? props?.fields?.link?.value.href,
          customized_button_name: props.fields?.text.value
        };
        if (!isNullOrEmpty(aliQtParams?.value)) {
          merge(qtParams, querystring.parse(aliQtParams?.value));
        }
        merge(qtParams, props.qtData);
        return qtParams;
      }
    };
    watch(
      () => props.fields,
      (n) => {
        state.theme = _methods.getTheme();
        state.type = _methods.getType();
      },
      {
        deep: true
      }
    );
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-site-button {
  $c: &;
  width: fit-content;
  height: 56px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 8px;
  border: 1px solid transparent;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  color: currentColor;
  background: transparent;
  user-select: none;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 24px;
  transition-timing-function: cubic-bezier(0.38, 0.015, 0, 0.995);
  &__text {
    line-height: 1;
    padding-top: 2px;
  }
  &__icon {
    transition-timing-function: cubic-bezier(0.38, 0.015, 0, 0.995);
    svg {
      height: 16px;
    }
  }
  &.primary-button {
    border-color: $yellow;
    background-color: $yellow;
    color: $black;
    padding: 0 24px 0 48px;
    transition: background-color 0.3s;
    #{$c}__icon {
      opacity: 1;
      transform: translateX(0);
    }
    &:focus {
      background-color: $yellow-active;
    }
    &.with-ani {
      #{$c}__icon {
        opacity: 0;
        transition: opacity 0.3s, transform 0.3s;
      }
      &:hover {
        background-color: $yellow-active;
        #{$c}__icon {
          opacity: 1;
          transform: translateX(8px);
        }
        &.reverse {
          #{$c}__icon {
            transform: rotate(180deg) translateX(8px);
          }
        }
      }
    }
    &.theme {
      &-white {
        color: $black;
        &.disabled {
          border-color: $grey-light !important;
          background: $grey-light !important;
          color: rgba($grey-next, 0.3) !important;
        }
      }
      &-black {
        color: $black;
        &.disabled {
          border-color: $grey-light !important;
          background: $grey-light !important;
          color: rgba($grey-next, 0.3) !important;
        }
      }
    }
  }
  &.secondary-button {
    padding: 0 24px 0 48px;
    transition: color 0.3s, border-color 0.3s;
    #{$c}__icon {
      opacity: 1;
      transform: translateX(0);
    }
    &.theme {
      &-black {
        border-color: $black;
        color: $black;
      }
      &-white {
        border-color: $white;
        color: $white;
      }
    }
    &.with-ani {
      #{$c}__icon {
        opacity: 0;
        transition: opacity 0.3s, transform 0.3s;
      }
      &:hover {
        #{$c}__icon {
          opacity: 1;
          transform: translateX(8px);
        }
        &.reverse {
          #{$c}__icon {
            transform: rotate(180deg) translateX(8px);
          }
        }
      }
    }
    &.disabled {
      border-color: $grey-next !important;
      color: $grey-next !important;
    }
  }
  &.tertiary-button {
    padding: 0;
    border-color: transparent;
    gap: 8px;
    justify-content: flex-start;
    transition: color 0.3s;
    &.theme {
      &-black {
        color: $black;
      }
      &-white {
        color: $white;
        &:hover {
          color: $yellow-active;
        }
      }
    }
    #{$c}__icon {
      transform: translateX(0);
    }
    &.with-ani {
      #{$c}__icon {
        transition: transform 0.3s;
      }
      &:hover {
        #{$c}__icon {
          transform: translateX(8px);
        }
        &.reverse {
          #{$c}__icon {
            transform: rotate(180deg) translateX(8px);
          }
        }
      }
    }
    &.disabled {
      color: rgba($grey-next, 0.3) !important;
    }
  }
  &.reverse {
    gap: 8px;
    #{$c}__text {
      order: 2;
    }
    &.primary-button,
    &.secondary-button,
    &.tertiary-button {
      #{$c}__icon {
        opacity: 1;
        transform: rotate(180deg) translateX(0);
      }
    }
  }
  &.size- {
    &small {
      height: 40px;
      font-size: 12px;
      line-height: 1;
      gap: 4px;
    }
    &.primary-button,
    &.secondary-button {
      padding: 0 12px 0 28px;
    }
    &.tertiary-button {
      gap: 8px;
    }
  }
  &.loading {
    padding: 0 48px;
    &.size- {
      &small {
        padding: 0 28px;
      }
    }
  }
  &.fullWidthOnMobile {
    width: 100%;
  }
  @include tablet-landscape {
    &.fullWidthOnMobile {
      width: fit-content;
    }
  }
  @include desktop {
    &.tertiary-button {
      gap: 8px;
      #{$c}__icon {
        opacity: 1;
      }
    }

    &.size- {
      &small {
        gap: 4px;
        #{$c}__icon {
          svg {
            width: 12px;
            height: 12px;
          }
        }
        &.tertiary-button {
          gap: 8px;
        }
      }
    }
    &.reverse {
      #{$c}__icon {
        opacity: 1;
      }
      &.primary-button,
      &.secondary-button {
        padding: 0 48px 0 24px;
      }
      &.size- {
        &small {
          &.primary-button,
          &.secondary-button {
            padding: 0 28px 0 12px;
          }
        }
      }
      &.loading {
        padding: 0 48px;
        &.size- {
          &small {
            padding: 0 28px;
          }
        }
      }
    }
  }
}
html.rtl {
  .e-site-button {
    $c: '.e-site-button';
    direction: rtl;
    &__icon {
      transform: rotate(180deg) translateX(0);
    }
    &.primary-button,
    &.secondary-button {
      padding: 0 24px 0 48px;
    }
    &.size- {
      &small {
        &.primary-button,
        &.secondary-button {
          padding: 0 12px 0 28px;
        }
      }
    }
    &.reverse {
      &.primary-button,
      &.secondary-button {
        padding: 0 48px 0 24px;
      }
      &.size- {
        &small {
          &.primary-button,
          &.secondary-button {
            padding: 0 28px 0 12px;
          }
        }
      }
      &.loading {
        padding: 0 48px;
        &.size- {
          &small {
            padding: 0 28px;
          }
        }
      }
    }
  }
}
</style>
