<script setup>
const props = defineProps({
  fields: {
    type: Object
  },
  data: {
    type: Object
  },
  dealers: {
    type: Array
  },
  dealer: {
    type: Object
  },
  renderType: {
    type: String,
    default: 'dealer-card-v1'
  }
});
const emit = defineEmits(['dealer-click']);
const onDealerClick = (dealer) => {
  emit('dealer-click', dealer);
};
</script>

<template>
  <div class="e-dealer-radio-list">
    <template v-for="(d, i) in dealers" :key="i">
      <component :is="renderType" v-if="renderType === 'dealer-card-v3'" class="e-dealer-radio-list__dealer" :fields="fields" :selected-dealer="dealer" :dealer="d" @contact="onDealerClick(d)" />
      <component v-else :is="renderType" class="e-dealer-radio-list__dealer" :fields="fields" :data="data" :selected-dealer="dealer" :dealer="d" @click="onDealerClick(d)" />
    </template>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-dealer-radio-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .s-dealer-card-v2 {
    + .s-dealer-card-v2 {
      margin-top: -24px;
    }
  }
  .s-dealer-card-v4 {
    + .s-dealer-card-v4 {
      margin-top: -24px;
    }
  }
  &.list-only {
    margin-top: 24px;
  }
}
</style>
