<template>
  <div class="e-adaptive-media" :class="rootClasses" v-bind="$attrs" :data-ratio="ratio" ref="rootEl" v-if="field">
    <video :src="field?.fields.desktopVideo.value" preload="none" style="display: none" v-if="visible" />
    <template v-if="hasAnimation">
      <animated-content class="e-adaptive-media__main" :type="animationType" :turnOff="false" @animated="onAnimated" :duration="duration" :replay="replay">
        <video-player
          class="e-adaptive-media__video"
          v-if="!$isNullOrEmpty(video?.value)"
          v-show="animationFinished"
          :options="videoOptions"
          :show-play-button="showPlayButton"
          @ended="(e) => end(e)"
          ref="playerRef"
        />
        <div
          class="e-adaptive-media__image"
          :role="isBackground ? 'img' : null"
          :aria-label="image?.value?.alt"
          :class="[{ 'as-bg': isBackground }, { 'animation-finished': animationFinished }]"
          :style="imageStyles"
          v-else-if="image"
        >
          <jss-image :media="image" :style="imageStyles" v-if="visible" loading="lazy" />
        </div>
      </animated-content>
      <div class="e-adaptive-media__content"><slot /></div>
    </template>
    <template v-else>
      <div class="e-adaptive-media__main">
        <video-player class="e-adaptive-media__video" v-if="!$isNullOrEmpty(video?.value)" :options="videoOptions" :show-play-button="showPlayButton" @ended="(e) => end(e)" ref="playerRef" />
        <div class="e-adaptive-media__image" :class="[{ 'as-bg': isBackground }]" v-else-if="image">
          <jss-image :media="image" :style="imageStyles" v-if="visible" loading="lazy" />
        </div>
        <div class="e-adaptive-media__content"><slot /></div>
      </div>
    </template>
  </div>
</template>

<script>
/**
 * @typedef AdaptiveMedia
 * @property {{
 *   mobileImage: ImageField,
 *   mobileVideo: SimpleField,
 *   tabletImage: ImageField,
 *   tabletVideo: SimpleField,
 *   desktopImage: ImageField,
 *   desktopVideo: SimpleField,
 *   autoplay: CheckField,
 *   hasControls: CheckField
 * }} fields
 * */
import { computed, onBeforeUnmount, onMounted, reactive, toRefs, watch } from 'vue';
import { merge, round } from 'lodash';
import useDevice from '@/hooks/useDevice';
import { settingValue } from '@/utils/site-utils';
import { equalString } from '@/utils/string-utils';
import { canUseDOM, getFileNameNoExt } from '@/utils/dom-utils';

export default {
  name: 'AdaptiveMedia',
  inheritAttrs: false,
  props: {
    /**@type AdaptiveMedia*/
    field: {
      type: Object
    },
    isBackground: {
      type: Boolean,
      default: true
    },
    backgroundPosition: {
      type: String,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    showPlayButton: {
      type: Boolean,
      default: false
    },
    originRatio: {
      type: Boolean,
      default: false
    },
    // hasAnimation: {
    //   type: Boolean,
    //   default: false
    // },
    lazyLoad: {
      type: Boolean,
      default: true
    },
    replay: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 600
    },
    animationType: {
      type: String,
      default: 'open-curtain'
    }
  },
  setup(props, ctx) {
    const { deviceState } = useDevice();
    let isTrackingScrolling = false;
    const state = reactive({
      rootEl: null,
      /**@type VideoPlayer*/
      playerRef: null,
      /**@type SimpleField|null*/
      video: null,
      image: null,
      animationFinished: false,
      ratio: null,
      visible: true
    });
    const computes = {
      rootClasses: computed(() => {
        const gradientColor = settingValue(props.field.fields.gradientColor);
        const gradientDirection = settingValue(props.field.fields.gradientDirection);
        return [
          {
            [`gradient-${gradientColor}`]: !!gradientColor,
            [`gradient-${gradientDirection}`]: !!gradientDirection
          }
        ];
      }),
      imageAlt: computed(() => {
        const { alt } = state.image?.value || {};
        return getFileNameNoExt(alt);
      }),
      imageStyles: computed(() => {
        return {
          '--ratio': state.ratio,
          objectPosition: props.backgroundPosition
        };
      }),
      videoOptions: computed(() =>
        !props.lazyLoad || state.visible
          ? merge(
              {
                controls: props.field.fields.hasControls?.value ?? false,
                autoplay: props.field.fields.autoplay?.value ?? false,
                muted: props.field.fields.autoplay?.value ?? false,
                loop: props.field.fields.loop?.value ?? false,
                poster: state.image.value?.src ?? null,
                disableFullscreen: props.field.fields.videoDisablefullscreen?.value ?? false,
                disableUnmute: props.field.fields.videoDisableUnmute?.value ?? false,
                sources: [
                  {
                    type: 'video/mp4',
                    src: state.video.value ?? null
                  }
                ]
              },
              props.options
            )
          : null
      ),
      videoType: computed(() => settingValue(props.field.fields.videoType)),
      hasAnimation: computed(() => props.field.fields.enterAnimation?.value ?? false)
    };
    const _methods = {
      judgeMedia() {
        const { mobileVideo, mobileImage, mobileRatio, tabletVideo, tabletImage, tabletRatio, desktopVideo, desktopImage, desktopRatio } = props.field?.fields || {};
        let w, h;
        switch (deviceState.deviceType) {
          case 'mobile':
            {
              [w, h] = settingValue(mobileRatio, '9_16')
                .split('_')
                .map((x) => Number(x));
              state.video = mobileVideo;
              state.image = mobileImage;
            }
            break;
          case 'tablet':
            {
              [w, h] = settingValue(tabletRatio, '4_3')
                .split('_')
                .map((x) => Number(x));
              state.video = tabletVideo;
              state.image = tabletImage;
            }
            break;
          default:
            {
              [w, h] = settingValue(desktopRatio, '4_3')
                .split('_')
                .map((x) => Number(x));
              state.video = desktopVideo;
              state.image = desktopImage;
            }
            break;
        }
        state.ratio = round(w / h, 2);
      },
      checkMediaVisible() {
        const rect = state.rootEl?.getBoundingClientRect();
        if (rect?.top < window.innerHeight + 500) {
          state.visible = true;
          global.removeEventListener('scroll', _methods.onScroll);
          isTrackingScrolling = false;
        }
      },
      onScroll() {
        _methods.checkMediaVisible();
      }
    };
    const methods = {
      onAnimated() {
        state.animationFinished = true;
      },
      end: (e) => {
        ctx.emit('ended', e);
      }
    };
    watch(
      () => deviceState.deviceType,
      () => {
        _methods.judgeMedia();
      },
      { immediate: true }
    );
    watch(
      () => props.field,
      () => {
        _methods.judgeMedia();
      },
      {
        immediate: true,
        deep: true
      }
    );
    watch(
      () => props.replay,
      (v) => {
        if (v) {
          state.animationFinished = false;
          _methods.checkMediaVisible();
        }
      }
    );
    if (canUseDOM()) {
      state.visible = false;
      _methods.judgeMedia();
    }
    onMounted(() => {
      _methods.checkMediaVisible();
      global.addEventListener('scroll', _methods.onScroll);
      isTrackingScrolling = true;
    });
    onBeforeUnmount(() => {
      if (isTrackingScrolling) {
        window.removeEventListener('scroll', _methods.onScroll);
        isTrackingScrolling = false;
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
.e-adaptive-media {
  $e: &;
  position: relative;
  height: 100%;

  &__image {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: $blue-night;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    > img {
      width: 100%;
      height: auto;
    }
    &.as-bg {
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  &__main {
    height: 100%;
    background-color: $blue-night;
    .e-animated-content__main {
      height: 100%;
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .open-curtain {
    &__image {
      &.animation-finished {
        animation: scale-up 600ms ease forwards;
      }
    }
  }
  @keyframes scale-up {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }

  @each $color-name, $color-value in $colors {
    &.#{'gradient-' + $color-name} {
      position: relative;
      &.gradient-ltr,
      &.gradient-rtl,
      &.gradient-ttb,
      &.gradient-btt {
        #{$e}__content {
          z-index: 1;
          transform: translateZ(1px);
        }
        #{$e}__image {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            transform: translateZ(1px);
          }
        }
        .video-js {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            transform: translateZ(1px);
          }
        }
      }
      &.gradient-ltr {
        #{$e}__image,
        .video-js {
          &:before {
            background-image: linear-gradient(to right, rgba($color-value, 0.75), rgba($color-value, 0.5) 25%, transparent 60%, transparent) !important;
          }
        }
      }
      &.gradient-rtl {
        #{$e}__image,
        .video-js {
          &:before {
            background-image: linear-gradient(to left, rgba($color-value, 0.75), rgba($color-value, 0.5) 25%, transparent 60%, transparent) !important;
          }
        }
      }
      &.gradient-ttb {
        #{$e}__image,
        .video-js {
          &:before {
            background-image: linear-gradient(to bottom, rgba($color-value, 0.75), rgba($color-value, 0.5) 25%, transparent 60%, transparent) !important;
          }
        }
      }
      &.gradient-btt {
        #{$e}__image,
        .video-js {
          &:before {
            background-image: linear-gradient(to top, rgba($color-value, 0.75), rgba($color-value, 0.5) 25%, transparent 60%, transparent) !important;
          }
        }
      }
    }
  }
}
</style>
