<template>
  <div v-bind="$data" class="e-phone-input" :class="[...rootClasses]">
    <div class="e-phone-input__label" v-html="`${label}${required ? '*' : ''}`" v-if="label" />
    <div class="e-phone-input__wrapper">
      <div class="e-phone-input__main">
        <div class="e-phone-input__main-input">
          <div class="e-phone-input__code" tabindex="-1" @click="toggleCountry" @blur="onCountryBlur">
            <div class="e-phone-input__region">
              <template v-if="selectedCountry">
                <!--                <div class="e-phone-input__region-val">-->
                <!--                  <img :src="selectedCountry.countryIcon" v-if="!$isNullOrEmpty(selectedCountry.countryIcon)" />-->
                <!--                </div>-->
                <span>{{ selectedCountry.countryPhoneCode }}</span>
              </template>
            </div>
            <icon name="down" class="e-phone-input__down" />
          </div>
          <div class="e-phone-input__mobile">
            <input
              type="tel"
              inputmode="numeric"
              :value="value?.number"
              ref="inputEl"
              :name="dataName"
              :placeholder="placeholder"
              :maxlength="maxLength"
              @input="onInput"
              :disabled="disabled"
              @focus="onFocus"
              @blur="onBlur"
            />
          </div>
        </div>
      </div>
      <div class="e-phone-input__side" v-if="$slots.default">
        <slot />
      </div>
      <transition name="options-ani">
        <div class="e-phone-input__countries" v-if="deviceType.includes('desktop') && countryActive">
          <div class="e-phone-input__search">
            <div class="e-phone-input__search-control">
              <icon name="search" />
              <input type="text" autocomplete="newPassword" v-model="keyword" ref="searchEl" @blur="onSearchBlur" />
            </div>
          </div>
          <div class="e-phone-input__country-options" ref="countriesEl">
            <div
              class="e-phone-input__country-option"
              :class="[{ selected: value?.data?.alpha2Code === c.alpha2Code }]"
              @click="(e) => onCountryOptionClick(e, c)"
              v-for="(c, i) in filteredCountries"
              :key="i"
            >
              <!--              <div class="e-phone-input__country-icon">-->
              <!--                <img :src="c.countryIcon" v-if="!$isNullOrEmpty(c.countryIcon)" alt="" />-->
              <!--              </div>-->
              <div>{{ c.countryName }}</div>
              <div>{{ c.countryPhoneCode }}</div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="e-phone-input__messages">
      <div class="e-phone-input__error-msg" v-if="errorMsg">
        <span class="e-dropdown__msg">{{ errorMsg }}</span>
        <icon name="close-circle-solid" size="tiny" />
      </div>
      <div class="e-phone-input__success-msg" v-else-if="successMsg">{{ successMsg }}</div>
      <div class="e-phone-input__info-msg" v-else-if="infoMsg">{{ infoMsg }}</div>
    </div>
    <modal ref="countryModalRef" class="e-phone-input__phone-modal" animation="bottom-slide-in" :fire-gtm="false" closable>
      <div class="e-phone-input__phone-modal__search">
        <div class="e-phone-input__phone-modal__input">
          <icon name="search" />
          <input type="text" autocomplete="newPassword" v-model="keyword" />
        </div>
      </div>
      <div class="e-phone-input__phone-modal__content">
        <div
          class="e-phone-input__phone-modal__country"
          v-for="(c, i) in filteredCountries"
          :key="i"
          :class="[{ selected: value?.area?.code === c.countryPhoneCode }]"
          @click="(e) => onCountryOptionClick(e, c)"
        >
          <!-- <div class="e-phone-input__country-icon">-->
          <!--                <img :src="c.countryIcon" v-if="!$isNullOrEmpty(c.countryIcon)" />-->
          <!--              </div>-->
          <div>{{ c.countryName }}</div>
          <div>+{{ c.countryPhoneCode }}</div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
/**
 * @typedef PhoneInput
 * @property {GlobalSettingEntry} theme
 * */
import { computed, getCurrentInstance, nextTick, onMounted, reactive, toRefs, watch } from 'vue';
import services from '@/services';
import useDevice from '@/hooks/useDevice';
import { getPageAlpha2Code, settingValue } from '@/utils/site-utils';
import { isNullOrWhitespace } from '@/utils/obj-utils';

export default {
  name: 'PhoneInput',
  emits: ['change', 'focus', 'blur'],
  props: {
    label: {
      type: String
    },
    dataName: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: Object
    },
    required: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    errorMsg: {
      type: String
    },
    successMsg: {
      type: String
    },
    infoMsg: {
      type: String
    },
    theme: {
      type: Object
    },
    maxLength: {
      type: Number,
      default: null
    }
  },
  setup(props, ctx) {
    let blurTimeout = null;
    const { deviceState } = useDevice();
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const state = reactive({
      keyword: '',
      /**@type HTMLInputElement*/
      inputEl: null,
      countriesEl: null,
      countryModalRef: null,
      countryActive: false,
      focused: false,
      /**@type Array<Country>*/
      countries: [],
      searchEl: null
    });
    const computes = {
      rootClasses: computed(() => [
        {
          focus: state.focused,
          [`theme-${settingValue(props.theme)}`]: settingValue(props.theme),
          'has-value': !!props.value,
          'has-error': !!props.errorMsg,
          succeed: !props.errorMsg && !!props.successMsg,
          disabled: props.disabled
        }
      ]),
      filteredCountries: computed(() => {
        if (isNullOrWhitespace(state.keyword)) return state.countries;
        if (!state.countries) return [];
        return state.countries.filter((x) => x.countryName.toLowerCase().startsWith(state.keyword.toLowerCase()));
      }),
      selectedCountry: computed(() => {
        if (!state.countries) return null;
        const page = $jss.routeData();
        let country = state.countries.find((x) => x.alpha2Code === props.value?.area?.data?.alpha2Code);
        if (!country) {
          const code = getPageAlpha2Code(page);
          country = state.countries.find((x) => x.alpha2Code === code);
        }
        return country;
      })
    };
    const methods = {
      onSearchBlur() {
        state.countryActive = false;
      },
      onInput: (e) => {
        e.target.value = e.target.value.replace(/(^\s+)|(\s+$)|[^0-9]/gi, '');
        let val = e.target.value;
        ctx.emit('change', e, {
          area: {
            code: computes.selectedCountry.value?.countryPhoneCode,
            name: computes.selectedCountry.value?.countryName,
            data: computes.selectedCountry.value
          },
          number: val
        });
      },
      onFocus(e) {
        ctx.emit('focus', e);
        state.focused = true;
      },
      onBlur(e) {
        ctx.emit('blur', e, {
          area: {
            code: computes.selectedCountry.value?.countryPhoneCode,
            name: computes.selectedCountry.value?.countryName,
            data: computes.selectedCountry.value
          },
          number: e.target.value
        });
        state.focused = false;
      },
      toggleCountry(e) {
        if (props.disabled) return;
        state.countryActive = !state.countryActive;
      },
      onCountryBlur(e) {
        if (blurTimeout) {
          clearTimeout(blurTimeout);
        }
        blurTimeout = setTimeout(() => {
          if (document.activeElement === state.searchEl) return;
          if (deviceState.deviceType.includes('desktop')) {
            state.countryActive = false;
            ctx.emit('blur', e, {
              area: {
                code: computes.selectedCountry.value?.countryPhoneCode,
                name: computes.selectedCountry.value?.countryName,
                data: computes.selectedCountry.value
              },
              number: state.inputEl.value
            });
          }
        }, 50);
      },
      onCountryOptionClick(e, country) {
        state.countryActive = false;
        state.focused = false;
        ctx.emit('change', e, {
          area: {
            code: country.countryPhoneCode,
            name: country.countryName,
            data: country
          },
          number: state.inputEl.value
        });
      },
      async checkMobileMotion() {
        if (!deviceState.deviceType.includes('desktop')) {
          if (state.countryActive) {
            state.countryModalRef.open();
            await nextTick();
          } else {
            await state.countryModalRef.close();
          }
        } else {
          if (state.countryModalRef) {
            await state.countryModalRef.close();
          }
        }
      }
    };

    watch(
      () => state.countryActive,
      () => {
        methods.checkMobileMotion();
      }
    );
    watch(
      () => deviceState.deviceType,
      () => {
        methods.checkMobileMotion();
      }
    );
    onMounted(async () => {
      state.countries = await services.site.getCountries();
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      ...toRefs(deviceState)
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-phone-input {
  $c: &;
  width: 100%;
  position: relative;
  &__label {
    margin-bottom: 4px;
  }
  &__wrapper {
    display: flex;
    position: relative;
    z-index: 1;
  }
  &__main {
    flex-grow: 1;
  }
  &__main-input {
    height: 56px;
    display: flex;
    align-items: center;
    border: 1px solid currentColor;
  }
  &__region {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $space-8;
  }
  &__down {
    flex-shrink: 0;
    > svg {
      height: 20px;
    }
  }
  &__region-val {
    width: 24px;
    height: 18px;
    overflow: hidden;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__code {
    width: 110px;
    flex-shrink: 0;
    border-right: 1px solid $grey-taubmans;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 8px;
    cursor: pointer;
  }
  &__mobile {
    flex-grow: 1;
    padding-left: 10px;
    > input {
      width: 100%;
      border: none !important;
      outline-width: 0 !important;
      padding: 0 !important;
      margin-bottom: 0 !important;
      &::placeholder {
        @include h9;
        color: $grey;
      }
      &:focus {
        border: none !important;
        outline-width: 0 !important;
        padding: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
  &__search {
    padding: $space-16;
    background: $grey-light;
  }
  &__search-control {
    background: $white;
    color: $black;
    display: flex;
    padding: $space-4 $space-16;
    > input {
      flex-grow: 1;
      background: transparent;
      border: none !important;
      outline-width: 0 !important;
    }
    .e-icon {
      margin-right: $space-16;
      color: $grey;
    }
  }
  &__countries {
    box-shadow: 0 1px 2px rgba($white, 0.5);
    position: absolute;
    top: 55px;
    left: 0;
    border: 1px solid $black;
    width: 500px;
  }
  &__country-options {
    max-height: 200px;
    background-color: $white;
    overflow-y: auto;
  }
  &__country-option {
    height: 40px;
    line-height: 40px;
    background: $white;
    color: $black;
    padding: 0 20px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    &.selected {
      background: $black;
      color: $white;
    }
    &:hover {
      font-weight: bold;
    }
    // > div {
    //   &:nth-child(1) {
    //     margin-right: auto;
    //   }
    //   &:nth-child(2) {
    //     padding-left: $space-12;
    //   }
    //   + div {
    //     margin-left: 10px;
    //   }
    // }
    &:last-child {
      margin-bottom: $space-8;
    }
  }
  &__country-icon {
    width: 28px;
    height: 21px;
    overflow: hidden;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__side {
    margin-left: 16px;
    width: 65px;
    flex-shrink: 0;
    .e-site-button {
      width: 100%;
      min-width: auto !important;
      height: 56px !important;
    }
    &:empty {
      display: none;
    }
  }
  &__messages {
    @include h11;
    height: 40px;
    padding-top: $space-8;
    text-align: end;
  }
  &__error-msg {
    color: $red;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  &__success-msg {
    color: $turquoise;
  }
  //&.focus {
  //  #{$c}__down {
  //    color: $black;
  //  }
  //  #{$c}__main-input {
  //    border: 1px solid $grey-dark;
  //  }
  //}
  &.has-error {
    #{$c}__main-input {
      border-color: $red;
      border-bottom-width: 2px;
    }
  }
  //&.succeed {
  //  #{$c}__main-input {
  //    border-color: $turquoise;
  //  }
  //}
  &.disabled {
    #{$c}__main-input {
      background: $grey-light;
    }
  }
  &__phone-modal {
    align-items: flex-end;
    .e-modal__content {
      width: 100%;
      padding: $space-40 0 0;
    }
    .e-modal__close {
      top: 12px;
    }
    &__content {
      height: 280px;
      overflow-y: auto;
      padding-top: 8px;
    }
    &__search {
      padding: $space-16;
      background: $grey-light;
    }
    &__input {
      padding: $space-8 $space-16;
      background: $white;
      color: $black;
      display: flex;
      .e-icon {
        margin-right: $space-8;
        color: $grey;
      }
      input {
        flex-grow: 1;
        background: transparent;
        border: none;
      }
    }
    &__country {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 20px;
      // > div {
      //   &:nth-child(1) {
      //     margin-right: auto;
      //   }
      //   &:nth-child(2) {
      //     padding-left: $space-12;
      //   }
      // }
      &.selected {
        background-color: $black;
        color: $white;
      }
    }
    .swiper {
      height: 300px;
      background: $white;
      color: $black;
      position: relative;
      top: -130px;
      .swiper-slide {
        opacity: 0.5;
        height: 36px;
        &-active {
          opacity: 1;
          background: $black;
          color: $white;
        }
      }
    }
  }
  @include tablet-landscape {
    &__code {
      width: 104px;
    }
    &__side {
      width: 140px;
    }
  }
}
html.rtl {
  .e-phone-input {
    &__code {
      border-left: 1px solid $grey-taubmans;
      padding-left: 8px;
    }
    &__mobile {
      padding-left: 0;
      padding-right: 10px;
    }
    &__phone-modal {
      &__input {
        .e-icon {
          margin-right: 0;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
