<template>
  <template v-if="!!$slots.default">
    <a class="e-site-link" v-bind="componentAttrs" v-if="isExternal">
      <slot />
    </a>
    <router-link class="e-site-link" v-bind="$attrs" :to="href" v-else-if="!$isNullOrEmpty(href)">
      <slot />
    </router-link>
    <span class="e-site-link" v-bind="$attrs" v-else>
      <slot />
    </span>
  </template>
  <template v-else-if="href">
    <jss-link class="e-site-link" v-bind="$attrs" :field="link" v-if="isExternal" @click="onJssLinkClick" />
    <router-link class="e-site-link" v-bind="$attrs" :to="href" v-else>{{ link.value?.text }}</router-link>
  </template>
</template>

<script>
import { computed, onMounted, reactive, toRefs, watch } from 'vue';
import { canUseDOM, openWindow } from '@/utils/dom-utils';
import { appendQuery, concatUrls, getBetterUrl, isAbsoluteUri } from '@/utils/uri-utils';
import { equalString } from '@/utils/string-utils';
import config from '../temp/config';
import { useRouter } from 'vue-router';
import { isNullOrEmpty } from '@/utils/obj-utils';

export default {
  name: 'SiteLink',
  inheritAttrs: false,
  props: {
    link: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const router = useRouter();
    const getHref = () => {
      let url = props.link?.value.href;
      if (/^(mailto|tel):/gi.test(url)) {
        return url;
      }
      if (/^http:\/\/tel:/gi.test(url)) {
        return url.replace(/^http:\/\//gi, '');
      }
      const { lang = 'en' } = router.currentRoute.value?.params ?? {};
      const { aplus_track_debug_id } = router.currentRoute.value?.query ?? {};
      if (!isNullOrEmpty(aplus_track_debug_id)) {
        url = appendQuery(url, {
          aplus_track_debug_id
        });
      }
      return getBetterUrl(url, lang) ?? '';
    };
    const state = reactive({
      href: getHref(),
      isExternal: false
    });
    const computes = {
      componentAttrs: computed(() => {
        let result = {
          ...(props.link?.value ?? {}),
          ...(ctx.attrs ?? {}),
          href: state.href
        };
        if (ctx.slots.default && result.text) {
          delete result.text;
        }
        return result;
      })
    };
    const methods = {
      onJssLinkClick(e) {
        e.preventDefault();
        openWindow(getHref(), props.link?.value.target);
        return false;
      }
    };
    watch(
      () => props.link,
      (link) => {
        state.href = getHref();
        state.isExternal = isAbsoluteUri(state.href) || ['mailto', 'tel', 'external'].includes(link?.value.linktype);
      },
      {
        deep: true,
        immediate: true
      }
    );
    onMounted(() => {
      state.href = getHref();
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss"></style>
