<script setup>
import { sitecoreProps } from '@/utils/constants';
import { computed } from 'vue';
import services from '../services';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  fields: {
    type: Object
  },
  store: {
    type: Object
  },
  /**@type ComponentTheme*/
  theme: {
    type: String
  },
  ...sitecoreProps
});
const { t } = useI18n();
const salesSchedule = computed(() => {
  if (!props.store) return [];
  return services.agent.getAgentSchedule(props.store, t);
});
</script>

<template>
  <div class="e-store-info" :class="[{ [`theme-${theme}`]: !$isNullOrEmpty(theme) }]" v-if="fields && store">
    <template-string class="e-store-info__title mg-b-64" :field="fields.title" :data="{ storeName: store.storeName }" />
    <div class="e-store-info__main">
      <jss-text class="__ft-cs fw-700 ttu" :field="fields.addressTitle" />
      <div class="text-desc">
        <div>{{ store.addressDetail }}</div>
        <div>{{ $concatString([store.extra?.storeZipCode, store.cityName, store.countryName], ',') }}</div>
      </div>
      <a
        class="e-store-info__link"
        :href="`https://www.google.com/maps/place/${encodeURIComponent(store.addressDetail)}/@${encodeURIComponent($concatString([store.latitude, store.longitude], ','))}`"
        :title="fields.directionText.value"
        target="_blank"
      >
        <icon :field="fields.directionIcon" />
        <jss-text class="e-store-info__link-text" :field="fields.directionText" tag="span" />
      </a>
      <template v-if="!$isNullOrEmpty(store.extra?.salesLine)">
        <jss-text class="text-desc" :field="fields.phoneTitle" />
        <a class="e-store-info__link" :href="`tel:${store.extra?.salesLine}`">
          <icon :field="fields.phoneIcon" />
          <span class="e-store-info__link-text">{{ store.extra?.salesLine }}</span>
        </a>
      </template>
    </div>
    <div class="e-store-info__schedule">
      <jss-text class="__ft-cs fw-700 ttu" :field="fields.hoursTitle" />
      <div class="e-store-info__times">
        <div class="e-store-info__time" v-for="item in salesSchedule" :key="item.day">
          <label>{{ item.day }}</label>
          <span>{{ item.openTime }}</span>
        </div>
      </div>
      <div class="mg-t-8 fs-12 __c-grey-next" v-html="store.salesComment" />
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-store-info {
  $c: &;
  padding: 64px 24px 40px 24px;
  &__title {
    text-transform: uppercase;
  }
  &__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    .e-icon {
      > svg {
        height: 18px;
      }
    }
  }
  &__schedule {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  &__link {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 40px;
    &-text {
      text-decoration: underline;
    }
  }
  &__times {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__time {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    color: $grey-next;
  }
  &.theme- {
    &black {
      background-color: $black;
      color: $white;
      .text-desc {
        color: $grey-taubmans;
      }
      #{$c}__time {
        color: $grey-taubmans;
      }
    }
  }
  @include tablet-landscape {
    padding: 64px 0 60px 0;
    @include grid-container;
    &__title {
      @include grid-block(3, 21, 1);
    }
    &__main {
      @include grid-block(3, 10, 2);
    }
    &__schedule {
      @include grid-block(15, 8, 2);
    }
  }
}
</style>
