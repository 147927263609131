<script setup>
import { computed } from 'vue';
import { ifEmpty } from '@/utils/string-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { merge } from 'lodash';

const props = defineProps({
  fields: {
    type: Object
  },
  href: {
    type: String
  }
});
const btnText = computed(() => ifEmpty(props.fields?.text.value, props.fields?.link.value?.text));
const componentType = computed(() => (isNullOrEmpty(props.href) || isNullOrEmpty(props.fields?.link.value?.href) ? 'button' : 'site-link'));
const componentProps = computed(() => {
  if (componentType.value === 'button') return null;
  else {
    const { link } = props.fields || {};
    return merge({}, link, {
      value: {
        href: ifEmpty(props.href, props.fields?.link.value?.href)
      }
    });
  }
});
</script>

<template>
  <component :is="componentType" class="e-icon-link">
    <icon />
    <span v-html="btnText" />
  </component>
</template>

<style lang="scss">
.e-icon-link {
  display: flex;
  gap: 24px;
  line-height: 24px;
  .e-icon {
    > svg {
      width: auto;
      height: 24px;
    }
  }
}
</style>
