<template>
  <div v-bind="$data" class="e-site-switch" :class="{ checked: value }" @click="toggle" />
</template>

<script>
export default {
  name: 'SiteSwitch',
  emits: ['change'],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const methods = {
      toggle(e) {
        ctx.emit('change', !props.value, e);
      }
    };
    return {
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-site-switch {
  width: 44px;
  height: 26px;
  border-radius: 12.5px;
  position: relative;
  background: $grey-light;
  border: 1px solid $grey-gainsboro;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    display: block;
    border-radius: 50%;
    background: $grey-medium;
    position: absolute;
  }
  &:before {
    width: 22px;
    height: 22px;
    opacity: 1;
    left: 1px;
    top: 1px;
  }
  &:after {
    width: 0;
    height: 0;
    opacity: 0;
    right: 11px;
    top: 11px;
  }
  &.checked {
    background: $yellow-light;
    &:before,
    &:after {
      background: $yellow;
    }
    &:before {
      width: 0;
      height: 0;
      opacity: 0;
      left: 11.5px;
    }
    &:after {
      top: 1px;
      right: 1px;
      width: 22px;
      height: 22px;
      opacity: 1;
    }
  }
}
</style>
