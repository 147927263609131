<template>
  <div class="e-form-textarea">
    <div class="e-form-textarea__label" v-html="`${label}${required ? '*' : ''}`" v-if="label" />
    <div class="e-form-textarea__wrapper">
      <div class="e-form-textarea__main">
        <textarea
          class="e-form-textarea__input"
          :class="[{ resizable }]"
          :value="value"
          :rows="rows"
          :placeholder="placeholder"
          :disabled="disabled"
          :maxlength="maxLength"
          @input="onInput"
          @focus="onFocus"
          @blur="onBlur"
        />
        <div class="e-form-textarea__limit" v-if="showLimitation">{{ value?.length || 0 }} / {{ maxLength }}</div>
      </div>
      <div class="e-form-textarea__side" v-if="$slots.default">
        <slot />
      </div>
    </div>
    <div class="e-dropdown__error-box">
      <div class="e-form-textarea__error-msg" v-if="errorMsg" v-html="errorMsg" />
      <div class="e-form-textarea__info-msg" v-else-if="infoMsg" v-html="infoMsg" />
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';

export default {
  name: 'FormTextarea',
  props: {
    theme: {
      type: Object
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    rows: {
      type: Number,
      default: 3
    },
    maxLength: {
      type: Number
    },
    errorMsg: {
      type: String
    },
    infoMsg: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    resizable: {
      type: Boolean,
      default: true
    },
    showLimitation: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const state = reactive({
      /**@type {String|null}*/
      error: null,
      focused: false
    });
    const computes = {
      rootClasses: computed(() => [
        {
          focus: state.focused,
          [`theme-${props.theme?.fields.phrase.value}`]: props.theme?.fields.phrase.value,
          'has-value': !!props.value,
          'has-error': !!props.errorMsg
        }
      ])
    };
    const methods = {
      onInput: (e) => {
        if (e.target.value === undefined) return;
        ctx.emit('change', e, e.target.value);
      },
      onFocus(e) {
        ctx.emit('focus', e);
        state.focused = true;
      },
      onBlur(e) {
        state.focused = false;
      }
    };
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-form-textarea {
  $c: &;
  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }
  &__main {
    flex-grow: 1;
    border: 1px solid currentColor;
    transition: all 0.2s linear;
  }
  &__limit {
    display: flex;
    padding-right: 15px;
    padding-bottom: 10px;
    justify-content: flex-end;
  }
  &__input {
    display: block;
    min-height: 40px;
    padding: 10px 15px !important;
    width: 100%;
    border: none !important;
    outline-width: 0 !important;
    margin-bottom: 0 !important;
    resize: none;
    &::placeholder {
      @include h9;
    }
    &.resizable {
      resize: vertical;
    }
  }
  &__label {
    margin-bottom: 4px;
  }
  &__input {
    background: transparent;
    border: none;
    color: currentColor;
    width: 100%;
    &::placeholder {
      color: $grey;
      @include h9;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
  &__side {
    margin-left: 16px;
    width: 65px;
    flex-shrink: 0;
    .e-site-button {
      width: 100%;
      min-width: auto !important;
      height: 40px !important;
    }
    &:empty {
      display: none;
    }
  }
  &__error-box {
    height: 40px;
    padding-top: $space-8;
    text-align: end;
  }
  &__error-msg {
    font-size: 12px;
    color: $red;
  }
  &__info-msg {
    font-size: 12px;
    line-height: 20px;
    color: $grey-next;
  }
  &.has-error {
    #{$c}__main {
      border-color: $red;
    }
  }
  &.focus {
    #{$c}__wrapper {
      border-color: $blue-sky;
    }
  }
  &.disabled {
    #{$c}__main {
      background: $grey-light;
    }
  }
  @include tablet-landscape {
    &__input {
      padding: 10px 20px;
    }
    &__side {
      width: 100px;
    }
  }
}
</style>
