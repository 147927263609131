<template>
  <div v-bind="$data" class="e-tag" :class="rootClasses">
    <Icon class="e-tag__icon" size="large-x" :svg="fields.icon?.value.svgCode" />
    <JssText class="e-tag__text" :field="fields.text" tag="div" />
  </div>
</template>
<script>
import { computed } from 'vue';
import { themeClass } from '@/utils/site-utils';
export default {
  name: 'Tag',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const computes = {
      rootClasses: computed(() => themeClass(props))
    };
    return {
      ...computes
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-tag {
  @include component-overall-settings;
  @include component-themes;
  width: 30px;
  height: 30px;
  @include tablet-landscape {
    width: 40px;
    height: 40px;
  }
  &__text {
    @include h9;
  }
}
</style>
