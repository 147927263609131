import { getBrowserInfo } from '@/utils/dom-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { debounce } from 'lodash';
import { nextTick } from 'vue';
const AspectRatio = {
  created(el, binding) {
    const browser = getBrowserInfo();
    if (/safari/gi.test(browser?.name) || binding.arg === 'unlimited') {
      el.updateMyHeight = () => {
        let ratio = binding.value;
        if (isNullOrEmpty(ratio)) return;
        let [w, h] = ratio.split('_').map((x) => Number(x) ?? 0);
        const rect = el.getBoundingClientRect();
        el.style.height = Math.floor(((rect.width || el.scrollWidth) * h) / w) + 'px';
      };
      el.winResize = debounce(el.updateMyHeight, 100);
    }
  },
  mounted(el, binding) {
    const browser = getBrowserInfo();
    if (/safari/gi.test(browser?.name) || binding.arg === 'unlimited') {
      window.addEventListener('resize', el.winResize);
      nextTick().then(() => {
        el.updateMyHeight();
      });
    }
  },
  beforeUnmount(el, binding) {
    const browser = getBrowserInfo();
    if (/safari/gi.test(browser?.name) || binding.arg === 'unlimited') {
      window.removeEventListener('resize', el.winResize);
    }
  }
};
export default AspectRatio;
