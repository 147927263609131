<template>
  <jss-rich-text class="e-heading" :class="[{ [`__ff-${fontFamily}`]: fontFamily }]" v-bind="$attrs" :field="field" :tag="tag" v-if="rich" :id="id" />
  <jss-text class="e-heading" v-bind="$attrs" :field="field" :tag="tag" :id="id" v-else />
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'Heading',
  inheritAttrs: false,
  props: {
    field: {
      type: Object
    },
    type: {
      type: Object
    },
    rich: {
      type: Boolean,
      default: false
    },
    fontFamily: {
      type: String,
      default: ''
    },
    id: null
  },
  setup(props) {
    const computes = {
      tag: computed(() => props.type?.fields?.phrase.value ?? props.type ?? 'div')
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
</style>
