<template>
  <div v-bind="$data" class="e-form-checkbox" :class="[{ checked: value, disabled, 'has-error': errorMsg, 'switch-like': switchLike, rtl }]" ref="rootEl">
    <div class="e-form-checkbox__title" v-html="title" v-if="!$isNullOrEmpty(title)" />
    <div class="e-form-checkbox__wrapper" :class="[{ bordered }]">
      <div class="e-form-checkbox__main">
        <div class="e-form-checkbox__check" @click="toggle">
          <Icon name="check" />
        </div>
        <div class="e-form-checkbox__text">
          <div class="e-form-checkbox__label-title" v-html="mandatoryTitle" />
          <div class="e-form-checkbox__label" v-html="errorMsg ?? mandatoryText" v-if="mandatoryText" @click="toggle" />
        </div>
      </div>
      <template v-if="$slots.content">
        <transition :css="false" @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
          <div class="e-form-checkbox__content" v-show="value">
            <slot name="content" />
          </div>
        </transition>
      </template>
    </div>
  </div>
</template>

<script>
import { transitions } from '@/utils/transitions';
import { onMounted, reactive, toRefs } from 'vue';
import utils from '@/utils';

export default {
  name: 'Checkbox',
  computed: {
    transitions() {
      return transitions;
    }
  },
  emits: ['change', 'update:value', 'a-click'],
  props: {
    value: {
      type: Boolean
    },
    title: {
      type: String
    },
    mandatoryTitle: {
      type: String
    },
    mandatoryText: {
      type: String
    },
    errorMsg: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    switchLike: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    rtl: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const methods = {
      toggle(e) {
        if (e.target?.tagName === 'A') {
          ctx.emit('a-click', e);
          return;
        }
        if (!props.disabled) {
          ctx.emit('update:value', !props.value);
          ctx.emit('change', e, !props.value);
        }
      }
    };
    const state = reactive({
      rootEl: null
    });
    onMounted(() => {
      if (props.switchLike) {
        const parentEl = utils.dom.getParentEl(state.rootEl, (el) => el?.classList.contains('s-dynamic-form__item'));
        if (parentEl) {
          parentEl.classList.add('switch');
        }
      }
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-form-checkbox {
  $c: &;
  &.rtl {
    direction: rtl;
  }
  &__title {
    margin-bottom: $space-24;
  }
  &__main {
    display: flex;
    gap: $space-20;
  }
  &__text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__label-title {
    color: $grey-night;
    font-weight: 500;
    &:empty {
      display: none;
    }
  }
  &__check {
    width: 24px;
    height: 24px;
    border: 1px solid $grey-taubmans;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    transition: all 0.2s ease;
    .e-icon {
      opacity: 0;
      transition: all 0.2s ease;
      transform: scale(0.5);
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  &__label {
    cursor: pointer;
    flex-grow: 1;
    color: $grey-next;
    user-select: none;
    line-height: 24px;
    a {
      color: $grey-dark;
      text-decoration: underline;
    }
  }
  &.checked {
    #{$c}__check {
      background: $blue-night;
      border-color: $blue-night;
      color: $yellow;
      .e-icon {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  &.has-error {
    #{$c}__label {
      color: $red;
    }
  }
  &.disabled {
    color: $grey;
    #{$c}__check {
      background: $grey-light;
      border-color: $grey-light;
      cursor: default;
    }
    #{$c}__label {
      cursor: default;
    }
  }
  &__wrapper {
    &.bordered {
      border: 1px solid $grey-89;
      padding: 16px;
      .e-site-accordion {
        border-color: $grey-89;
        border-bottom-width: 0;
        padding: 0 8px;
      }
    }
  }
  &.switch-like {
    margin-bottom: 0;
    #{$c}__main {
      flex-direction: row-reverse;
    }
    #{$c}__label {
      color: $black;
    }
    #{$c}__check {
      width: 54px;
      height: 27px;
      background-image: radial-gradient(53.07% 79.17% at 50% 100%, rgba(95, 95, 95, 0.6) 0%, rgba(46, 46, 46, 0.8) 100%);
      background-color: transparent;
      background-repeat: no-repeat;
      position: relative;
      border: 1px solid $grey-neutral;
      border-radius: 13.5px;
      .e-icon {
        width: 22.5px;
        height: 22.5px;
        border-radius: 100%;
        background: $grey-light;
        position: absolute;
        left: 2px;
        top: 50%;
        transition: all 0.2s cubic-bezier(0.38, 0.015, 0, 0.995);
        opacity: 1;
        transform: scale(1) translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          height: 14px;
          color: $black;
          opacity: 0;
          transition: all 0.2s cubic-bezier(0.38, 0.015, 0, 0.995);
        }
      }
    }
    &.checked {
      #{$c}__check {
        .e-icon {
          left: 28.5px;
          background-image: linear-gradient(40deg, #fff200 35.92%, #fcff6a 93.86%);
          filter: drop-shadow(0 4.5px 6.75px rgba(0, 0, 0, 0.25));
          svg {
            opacity: 1;
          }
        }
      }
    }
    + #{$c}.switch-like {
      background: $yellow;
      #{$c}__wrapper {
        border-top-width: 0 !important;
      }
    }
  }
}
.s-dynamic-form__item {
  &.switch {
    & + & {
      .e-form-checkbox {
        .e-form-checkbox__wrapper {
          &.bordered {
            border-top-width: 0;
          }
        }
      }
    }
  }
}
</style>
