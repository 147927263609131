<template>
  <div
    v-bind="$data"
    class="e-dropdown"
    :class="[{ disabled, 'has-error': errorMsg, [`theme-${theme}`]: theme, active: deviceType.includes('desktop') && active }]"
    tabindex="-1"
    @blur="blur"
    ref="rootEl"
  >
    <div class="e-dropdown__title" v-html="title" />
    <div class="e-dropdown__label" v-html="`${label}${required ? '*' : ''}`" v-if="label" />
    <div class="e-dropdown__main" :class="[{ active: deviceType.includes('desktop') && active }]">
      <div class="e-dropdown__selection" @click="toggle">
        <div class="e-dropdown__text" v-if="selectedOption" title="selectedOption.text">{{ selectedOption.text }}</div>
        <div class="e-dropdown__placeholder" v-else>{{ placeholder }}</div>
        <icon class="e-dropdown__arrow" name="down" />
      </div>
      <transition name="options-ani">
        <div class="e-dropdown__options-wrapper" v-if="deviceType.includes('desktop') && active">
          <div class="e-dropdown__search" v-if="showSearch">
            <div class="e-dropdown__search-control">
              <icon name="search" />
              <input type="text" autocomplete="newPassword" v-model="keyword" ref="searchEl" @blur="onSearchBlur" />
            </div>
          </div>
          <div class="e-dropdown__options">
            <div class="e-dropdown__option placeholder" @click="clearSelection" v-if="clearable && !$isNullOrEmpty(selectedOption?.code)">{{ $ifEmpty(clearText, placeholder) }}</div>
            <div
              class="e-dropdown__option"
              :class="{ selected: option?.code === selectedOption?.code }"
              v-for="(option, index) in filteredOptions"
              :key="index"
              @click="(e) => onOptionClick(e, option)"
            >
              <slot name="option" v-if="$slots.option" :option="option" />
              <div v-else>{{ option.text }}</div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="e-dropdown__desc" v-if="!$isNullOrEmpty(description)" v-html="description" />
    <div class="e-dropdown__error-box">
      <div class="e-dropdown__error-msg" v-if="errorMsg">
        <span class="e-dropdown__msg">{{ errorMsg }}</span>
        <icon name="close-circle-solid" size="tiny" />
      </div>
    </div>
    <modal class="e-dropdown__options-modal" ref="optionsModal" @closed="onModalClosed" animation="bottom-slide-in" :fire-gtm="false" :sticky="false" closable>
      <div class="e-dropdown__options-modal__search" v-if="showSearch">
        <div class="e-dropdown__options-modal__search-control">
          <icon name="search" />
          <input type="text" v-model="keyword" />
        </div>
      </div>
      <div class="e-dropdown__options-modal__content">
        <div class="e-dropdown__options-modal__item placeholder" @click="clearSelection" v-if="clearable && !$isNullOrEmpty(selectedOption?.code)">
          <div class="e-dropdown__options-modal__option">
            {{ $ifEmpty(clearText, placeholder) }}
          </div>
        </div>
        <div
          class="e-dropdown__options-modal__item"
          :class="{ selected: option?.code === selectedOption?.code }"
          v-for="(option, index) in filteredOptions"
          :key="index"
          @click="(e) => onOptionClick(e, option)"
        >
          <slot name="option" v-if="$slots.option" :option="option" />
          <div class="e-dropdown__options-modal__option" v-else>{{ option.text }}</div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick, reactive, toRefs, watch } from 'vue';
import useDevice from '@/hooks/useDevice';
import { isNullOrWhitespace } from '@/utils/obj-utils';
import { debounce } from 'lodash';

export default defineComponent({
  name: 'Dropdown',
  emits: ['blur', 'change'],
  props: {
    title: {
      type: String
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    description: {
      type: String
    },
    options: {
      type: Array
    },
    selectedOption: {
      type: Object
    },
    errorMsg: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    clearText: {
      type: String
    },
    hideSelected: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: null
    }
  },
  setup(props, ctx) {
    let blurTimeout = null;
    const { deviceState } = useDevice();
    const state = reactive({
      rootEl: null,
      keyword: null,
      active: false,
      contentEl: null,
      optionsModal: null,
      searchEl: null
    });
    const computes = {
      filteredOptions: computed(() => {
        let opts = props.options;
        if (props.hideSelected) {
          opts = opts.filter((x) => x.code !== props.selectedOption?.code);
        }
        if (!isNullOrWhitespace(state.keyword)) {
          opts = opts.filter((x) => new RegExp(`^${state.keyword}`, 'gi').test(x.text));
        }
        return opts;
      }),
      showSearch: computed(() => props.options?.length > 10)
    };
    watch(
      () => state.active,
      () => {
        methods.checkMobileMotion();
      }
    );
    watch(
      () => deviceState.deviceType,
      () => {
        methods.checkMobileMotion();
      }
    );
    const methods = {
      onSearchBlur() {
        state.active = false;
      },
      blur(e) {
        if (blurTimeout) {
          clearTimeout(blurTimeout);
        }
        blurTimeout = setTimeout(() => {
          if (document.activeElement === state.searchEl) return;
          if (deviceState.deviceType.includes('desktop')) {
            state.active = false;
            ctx.emit('blur');
          }
        }, 50);
      },
      toggle() {
        if (props.disabled) return;
        state.active = !state.active;
      },
      checkMobileMotion: debounce(async () => {
        if (!deviceState.deviceType.includes('desktop')) {
          if (state.active) {
            state.optionsModal.open();
            await nextTick();
          } else {
            state.optionsModal.close();
          }
        } else {
          if (state.optionsModal) {
            state.optionsModal.close();
          }
        }
      }, 20),
      onOptionClick(e, option) {
        ctx.emit('change', e, option);
        state.active = false;
      },
      clearSelection(e) {
        ctx.emit('change', e, null);
        state.active = false;
      },
      onModalClosed() {
        state.active = false;
      }
    };
    return {
      ...toRefs(state),
      ...toRefs(deviceState),
      ...computes,
      ...methods
    };
  }
});
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-dropdown {
  $c: &;
  position: relative;
  &__title {
    margin-bottom: 16px;
    font-size: 26px;
    font-weight: 500;
    &:empty {
      display: none;
    }
  }
  &__label {
    margin-bottom: 4px;
    &:empty {
      display: none;
    }
  }
  &__placeholder {
    @include h9;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $grey;
  }
  &__main {
    position: relative;
    &.active {
      z-index: 2;
      #{$c}__selection {
        border-color: $grey-dark;
        .e-icon {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__selection {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border: 1px solid currentColor;
    cursor: pointer;
    padding: 5px 50px 5px 15px;
    tab-index: -1;
  }
  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__arrow {
    position: absolute;
    right: 15px;
    > svg {
      height: 20px;
    }
  }
  &__options-wrapper {
    box-shadow: 0 1px 2px rgba($white, 0.5);
    position: absolute;
    z-index: 1;
    width: 100%;
    border: 1px solid $black;
  }
  &__search {
    padding: $space-16;
    background: $grey-light;
  }
  &__search-control {
    background: $white;
    color: $black;
    display: flex;
    padding: $space-4 $space-16;
    > input {
      flex-grow: 1;
      background: transparent;
      border: none !important;
      outline-width: 0 !important;
      padding: 0 !important;
      margin-bottom: 0 !important;
    }
    .e-icon {
      margin-right: $space-16;
      color: $grey;
    }
  }
  &__options {
    max-height: 200px;
    background-color: $white;
    overflow-y: auto;
  }
  &__option {
    height: 40px;
    line-height: 40px;
    background: $white;
    color: $black;
    padding: 0 20px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.selected {
      background: $black;
      color: $white;
    }
    &.placeholder {
      background: $white;
      color: $grey;
    }
    &:empty {
      display: none;
    }
    &:hover {
      font-weight: bold;
    }
  }
  &__desc {
    font-size: 12px;
    padding-top: 4px;
    margin-bottom: 4px;
  }
  &__error-box {
    height: 40px;
    padding-top: $space-8;
    text-align: end;
  }
  &__error-msg {
    font-size: 12px;
    color: $red;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  &.has-error {
    #{$c}__selection {
      border-color: $red;
      border-bottom-width: 2px;
    }
  }
  &.disabled {
    #{$c}__selection {
      border-color: $grey-light;
      background: $grey-light;
      cursor: default;
    }
    #{$c}__arrow {
      color: $grey-taubmans;
    }
  }
  &.theme {
    &-search-grey {
      #{$c}__search {
        display: none;
      }
      #{$c}__error-box {
        display: none;
      }
      #{$c}__selection {
        height: 40px;
        color: $black;
      }
      #{$c}__placeholder {
        color: $black;
      }
      #{$c}__options-wrapper {
        min-width: 100%;
        width: auto;
        box-shadow: none;
      }
      #{$c}__options {
        background: $grey-neutral;
        border-color: $grey-neutral;
        max-height: 400px;
      }
      #{$c}__option {
        background: $grey-neutral;
        color: $white;
        position: relative;
        z-index: 1;
        border-right: 2px solid $black;
        padding: 16px;
        height: auto;
        line-height: 1;
        &:not(:last-child) {
          &:after {
            content: '';
            position: absolute;
            left: 16px;
            bottom: 0;
            width: calc(100% - 32px);
            height: 2px;
            box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.16) inset;
          }
        }
        &.placeholder {
          display: none;
        }
        &.selected {
          background: $grey-neutral;
          border-right-color: $yellow;
        }
      }
    }
  }
  @include desktop {
    &.active {
      z-index: 4;
    }
  }
}
.e-dropdown__options-modal {
  align-items: flex-end;
  $m: '.e-dropdown__options-modal';
  .e-modal__content {
    width: 100%;
    background: $white;
    color: $black;
    padding: $space-40 0 0;
  }
  .e-modal__close {
    top: 12px;
  }
  &__content {
    max-height: 72vh;
    min-height: 40vh;
    overflow: auto;
  }
  &__search {
    padding: $space-16;
    background: $grey-light;
  }
  &__search-control {
    background: $white;
    color: $black;
    display: flex;
    padding: $space-4 $space-16;
    > input {
      flex-grow: 1;
      background: transparent;
      border: none !important;
      outline-width: 0 !important;
      padding: 0 !important;
      margin-bottom: 0 !important;
    }
    .e-icon {
      margin-right: $space-16;
      color: $grey;
    }
  }
  &__option {
    text-align: center;
    padding: 12px 20px;
    &:empty {
      display: none;
    }
  }
  &__item {
    &.selected {
      background-color: $black;
      color: $white;
    }

    &.placeholder {
      background: $white;
      color: $grey;
    }
  }
}
</style>
