import nativeCode from '@/js-bridge/nativeCode';
import { register } from '@/js-bridge/h5Interface';
import nativeCommon from '@/js-bridge/nativeCommon';
import { names } from '@/utils/dom-utils';
import { canUseDOM } from '@/utils/dom-utils';

export let isAndroid = false;
export let isIOS = false;
export let isH5 = false;
export let isWx = false;
export let isAppBrowser = false;

let _invoke = null;
let hasInit = false;
export const invoke = (nativeParams, params, callbackData = null, isJsonValOrParams = {}) => {
  init();
  if (!nativeParams) return;
  if (typeof nativeParams === 'string') {
    nativeParams = { methodName: nativeParams };
  }
  if (params !== '') nativeParams.data = params;
  if (!callbackData) {
    callbackData = () => {};
  }
  if (typeof callbackData === 'function') {
    callbackData = { handle: callbackData };
    const bit = 10000;
    let rd1 = new Date().getTime() % bit;
    let rd2 = Math.floor(Math.random() * bit);
    callbackData.type = 'callback_' + rd1 + '_' + rd2;
    callbackData.isJson = false;
  }
  let { type, handle, isJson } = callbackData;
  nativeParams.callbackId = type;
  let errorData = {};
  if (typeof isJsonValOrParams === 'boolean') {
    isJson = isJsonValOrParams;
  } else if (typeof isJsonValOrParams === 'object') {
    if (typeof isJsonValOrParams.isJson === 'boolean') isJson = isJsonValOrParams.isJson;
    errorData = isJsonValOrParams;
    errorData.methodName = nativeParams.methodName;
  }
  register(type, wrapperHandleError(errorData, handle), isJson);
  _invoke(nativeParams);
};
// export const nativeCommon = {};
export const $bridge = {
  nativeCode,
  invoke,
  register
};
// 处理App反馈异常
function wrapperHandleError(errorData, handle) {
  return (res) => {
    const isErrorVerion = res.err != null;
    console.log('jsbridge is error version', isErrorVerion);

    let data = res;

    //  app支持最新版本v1.3 异常反馈
    if (isErrorVerion) {
      const { error, notExist } = errorData || {};
      if (res.err) {
        if (notExist && res.err === 'api_not_exist') {
          notExist.call(null, res);
        } else if (error) {
          error.call(null, res);
        } else {
          // Toast({ message: '当前版本过低，请至应用商店下载最新版本' });
          console.log(`${errorData.methodName}方法当前版本不支持~`);
        }
        return;
      }
      // 最新版本 v1.3及以上的回调数据是 res.data
      data = res.data;
    }

    // 正常回调处理
    handle && handle.call(null, data);
  };
}
export function getIsApp() {
  init();
  return isAppBrowser;
}

export function invokeAndroid(params) {
  if (canUseDOM()) {
    if (window.android && window.android.callNative) {
      params = JSON.stringify(params);
      console.log('invoke android data', params);
      window.android && window.android.callNative(params);
      console.log(`invoke success`);
    } else {
      console.log(`android对应接口未注入`);
    }
  }
}

export function invokeIOS(params) {
  const iosCmd = names(`webkit.messageHandlers.callNative`);
  if (iosCmd) {
    console.log('invoke ios data', params);
    iosCmd.postMessage(params);
    console.log(`invoke success`);
  } else {
    console.log(`IOS对应接口未注入`);
  }
}

function init() {
  if (canUseDOM()) {
    if (hasInit) return;
    hasInit = true;

    // #ifdef H5
    const ua = navigator.userAgent;
    isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1 || ua.indexOf('Linux') > -1;
    isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    isWx = ua.toLowerCase().indexOf('micromessenger') !== -1;

    const androidCmd = window.android && window.android.callNative;
    const iosCmd = names(`webkit.messageHandlers.callNative`);
    if ((isAndroid && !androidCmd) || (isIOS && !iosCmd)) {
      isH5 = true;
      console.log('当前为 - 浏览器打开！');
    } else {
      // init(androidCmd, iosCmd);
    }
    isAppBrowser = !!(androidCmd || iosCmd);

    if (!isH5) {
      _invoke = isAndroid ? invokeAndroid : invokeIOS;
    }
    // #endif

    // 注册messageHandler
    register('messageHandler', async (params) => {
      // console.log('store', store);
      // if (store.state.isBigScreen) {
      //   // 大屏的处理
      //   bigscreen.bigScreenHandler(params);
      //   return;
      // }
      // console.log('message handler', params);
      let { methodName, data, callbackId, responseId } = params;

      let method = null;
      if (responseId) {
        // 原生回调
        if (canUseDOM()) {
          method = window.lotusH5[responseId];
        }
      } else {
        if (canUseDOM()) {
          method = window.lotusH5[methodName];
          console.log('native call', method);
        }
      }

      let result = null;
      if (method) {
        result = method(data);
      }

      console.log('result', result);
      if (callbackId) {
        // 回调原生
        setTimeout(() => {
          invoke({ responseId: callbackId }, result);
        }, 0);
      }
    });

    // 自定事件 app登录
    // const appLoginCallbackEvent = new CustomEvent('appLoginCallback');
    // // 监听登录会掉事件 loginCallback
    // register('loginCallback', () => {
    //   console.log('loginCallback');
    //   appUpdateToken((res) => {
    //     const appLoginGetTokenCallbackEvent = new CustomEvent('appLoginGetTokenCallbackEvent', { detail: res });
    //     window.dispatchEvent(appLoginGetTokenCallbackEvent);
    //   });
    //   window.dispatchEvent(appLoginCallbackEvent);
    // });

    // 隐藏头
    // nativeCommon.hideNavBar();
  }
}

export default {
  install: (app) => {
    init();
    app.config.globalProperties.$bridge = $bridge;
    app.config.globalProperties.$nativeCommon = nativeCommon;
  }
};
