<template>
  <background-image
    ref="rootEl"
    :is-background="isBackground"
    :lazy-load="lazyLoad"
    :position="backgroundPosition"
    :image="$adaptiveImageField(field?.fields?.desktopImage, field?.fields?.mobileImage, field?.fields?.tabletImage) ?? fallbackImage"
    :full-image="$adaptiveImageField(fieldFull?.fields?.desktopImage, fieldFull?.fields?.mobileImage, fieldFull?.fields?.tabletImage) ?? fallbackImageFull"
    :fullscreen-enabled="fullscreenEnabled"
  >
    <slot v-if="$slots.default" />
  </background-image>
</template>

<script>
/**
 * @typedef AdaptiveImage
 * @extends Component
 * @property {Boolean} isBackground - Rendered as Background Image
 * @property {{
 *   fields: AdaptiveImageFields
 * }} field
 * */
/**
 * @typedef AdaptiveImageFields
 * @property {ImageField} mobileImage
 * @property {ImageField} tabletImage
 * @property {ImageField} desktopImage
 * */
import { reactive, toRefs } from 'vue';

export default {
  name: 'AdaptiveImage',
  props: {
    isBackground: {
      type: Boolean,
      default: true
    },
    backgroundPosition: {
      type: String,
      default: 'center'
    },
    lazyLoad: {
      type: Boolean,
      default: true
    },
    field: {
      type: Object
    },
    fieldFull: {
      type: Object
    },
    fullscreenEnabled: {
      type: Boolean,
      default: false
    },
    fallbackImage: {
      type: Object
    },
    fallbackImageFull: {
      type: Object
    }
  },
  setup() {
    const state = reactive({
      rootEl: null
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss"></style>
