import { computed, reactive } from 'vue';
import { canUseDOM, getDeviceInfo, isDesktop, isMobile, isTablet, isTabletLandscape } from '@/utils/dom-utils';

const useDevice = () => {
  let watching = false;
  const state = reactive({
    deviceType: 'mobile',
    os: 'Android',
    browser: 'Chrome'
  });
  const computes = {
    isMobileDevice: computed(() => state.deviceType === 'mobile'),
    isTabletDevice: computed(() => state.deviceType === 'tablet'),
    isTabletLandscapeDevice: computed(() => state.deviceType === 'tablet-landscape'),
    isDesktopDevice: computed(() => state.deviceType === 'desktop'),
    isDesktopLargeDevice: computed(() => state.deviceType === 'desktop-large'),
    isMobileOrTablet: computed(() => ['mobile', 'tablet'].includes(state.deviceType)),
    largeThanTablet: computed(() => !computes.isMobileOrTablet.value),
    largeOrEqualDesktop: computed(() => !['mobile', 'tablet', 'tablet-landscape'].includes(state.deviceType))
  };
  const methods = {
    getDeviceType() {
      if (isMobile()) {
        return 'mobile';
      } else if (isTablet()) {
        return 'tablet';
      } else if (isTabletLandscape()) {
        return 'tablet-landscape';
      } else if (isDesktop()) {
        return 'desktop';
      } else {
        return 'desktop-large';
      }
    },
    resize() {
      const deviceInfo = getDeviceInfo();
      state.deviceType = methods.getDeviceType();
      state.os = deviceInfo.os.name;
      state.browser = deviceInfo.browser.name;
      state.browserVersion = deviceInfo.browser.version;
    }
  };
  const startDeviceHook = () => {
    if (!watching) {
      watching = true;
      global.addEventListener('resize', methods.resize);
    }
  };
  const destroyDeviceHook = () => {
    if (watching) {
      watching = false;
      global.removeEventListener('resize', methods.resize);
    }
  };
  if (canUseDOM()) {
    const deviceInfo = getDeviceInfo();
    state.deviceType = methods.getDeviceType();
    state.os = deviceInfo.os.name;
    state.browser = deviceInfo.browser.name;
    state.browserVersion = deviceInfo.browser.version;
    startDeviceHook();
  }
  return {
    deviceState: state,
    deviceComputes: computes,
    startDeviceHook,
    destroyDeviceHook
  };
};
export default useDevice;
