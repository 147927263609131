<template>
  <div v-bind="$data" class="e-form-input" :class="[...rootClasses, { disabled, active: focused }]">
    <div class="e-form-input__title" v-html="title" />
    <div class="e-form-input__label" v-html="`${label}${required ? '*' : ''}`" v-if="label" />
    <div class="e-form-input__wrapper">
      <div class="e-form-input__main">
        <div class="e-form-input__main-input">
          <input
            class="e-form-input__input"
            :value="value"
            :name="dataName"
            :placeholder="placeholder"
            :maxlength="maxLength"
            :data-type="inputType"
            :type="inputType"
            :disabled="disabled"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
            @mousewheel.prevent
          />
          <div class="e-form-input__icon" v-if="icon || $slots.icon">
            <icon :svg="icon?.value?.svgCode ?? icon" v-if="icon" />
            <slot name="icon" />
          </div>
        </div>
      </div>
      <div class="e-form-input__side" v-if="$slots.default">
        <slot />
      </div>
    </div>
    <div class="e-form-input__desc" v-html="description" />
    <div class="e-form-input__messages">
      <div class="e-form-input__error-msg" v-if="errorMsg">
        <span class="e-form-input__msg">{{ errorMsg }}</span>
        <icon name="close-circle-solid" size="tiny" />
      </div>
      <div class="e-form-input__info-msg" v-if="infoMsg">{{ infoMsg }}</div>
      <div class="e-form-input__success-msg" v-if="successMsg">{{ successMsg }}</div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';

export default {
  name: 'FormInput',
  emits: ['change', 'focus', 'blur'],
  props: {
    theme: {
      type: Object
    },
    title: {
      type: String
    },
    label: {
      type: String
    },
    dataName: {
      type: String
    },
    inputType: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    errorMsg: {
      type: String
    },
    infoMsg: {
      type: String
    },
    successMsg: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Object
    },
    reverse: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: null
    },
    description: {
      type: String
    }
  },
  setup(props, ctx) {
    const state = reactive({
      /**@type {String|null}*/
      error: null,
      focused: false
    });
    const computes = {
      rootClasses: computed(() => [
        {
          focus: state.focused,
          [`theme-${props.theme?.fields.phrase.value}`]: props.theme?.fields.phrase.value,
          'has-value': !!props.value,
          'has-error': !!props.errorMsg,
          succeed: !props.errorMsg && !!props.successMsg,
          'has-icon': !!props.icon,
          reverse: props.reverse?.value ?? props.reverse
        }
      ])
    };
    const methods = {
      onInput: (e) => {
        let val = e.target.value;
        if (props.inputType === 'email') {
          val = val.replace(/\s*/gi, '');
        }
        e.target.value = val;
        ctx.emit('change', e, e.target.value);
      },
      onFocus(e) {
        ctx.emit('focus', e);
        state.focused = true;
      },
      onBlur(e) {
        state.focused = false;
        ctx.emit('blur', e, e.target.value);
      }
    };
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-form-input {
  $c: &;
  &__wrapper {
    display: flex;
    position: relative;
  }
  &__main {
    flex-grow: 1;
  }
  &__main-input {
    height: 56px;
    display: flex;
    align-items: center;
    border: 1px solid currentColor;
    padding: 0 15px;
    transition: all 0.2s linear;
  }
  &__title {
    margin-bottom: 24px;
    font-size: 26px;
    font-weight: 500;
    &:empty {
      display: none;
    }
  }
  &__label {
    margin-bottom: 4px;
  }
  &__input {
    background: transparent;
    border: none !important;
    outline-width: 0 !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
    color: currentColor;
    width: 100%;
    appearance: textfield;
    &::placeholder {
      color: $grey;
      @include h9;
    }
    &:focus {
      border: none;
      outline: none;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }
  &__desc {
    font-size: 12px;
    color: $grey-next;
    margin-top: 2px;
  }
  &__side {
    margin-left: 16px;
    width: 65px;
    flex-shrink: 0;
    .e-site-button {
      width: 100%;
      min-width: auto !important;
      height: 56px !important;
    }
    &:empty {
      display: none;
    }
  }
  &__messages {
    @include h11;
    height: 40px;
    padding-top: $space-8;
    text-align: end;
    .e-icon {
      align-self: center;
    }
  }
  &__msg {
    margin-top: 1px;
  }
  &__error-msg {
    font-size: 12px;
    color: $red;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  &.has-error {
    #{$c}__main-input {
      border-color: $red;
      border-bottom-width: 2px;
    }
  }
  &.succeed {
    #{$c}__main-input {
      border-color: $blue-sky;
    }
  }
  &__success-msg {
    color: $turquoise;
  }
  &.active {
    #{$c}__main-input {
      border-color: $grey-dark;
    }
  }
  &.has-icon {
    #{$c}__main {
      padding: 0;
    }
    #{$c}__input {
      padding-right: 20px;
    }
  }
  &__icon {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  &.reverse {
    &.has-icon {
      #{$c}__main {
        padding-right: 0;
        padding-left: 50px;
      }
    }
    #{$c}__icon {
      left: 0;
      right: unset;
    }
  }
  &.disabled {
    #{$c}__main {
      &-input {
        background: $grey-light;
        border-color: $grey-light;
      }
    }
  }
  @include tablet-landscape {
    &__main-input {
      padding: 0 20px;
    }
    &__side {
      width: 140px;
    }
  }
}
</style>
