<template>
  <div v-bind="$data" class="e-accordion-toggle" @click="onClick">
    <div class="e-accordion-toggle__content" :class="[{ active }]" />
  </div>
</template>

<script>
export default {
  name: 'AccordionToggle',
  emits: ['click'],
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const methods = {
      onClick(e) {
        ctx.emit('click', e);
      }
    };
    return {
      ...methods
    };
  }
};
</script>

<style lang="scss">
.e-accordion-toggle {
  $c: &;
  width: 16px;
  height: 16px;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;
  &__content {
    width: 16px;
    height: 16px;
    position: relative;
    transition: all 0.2s linear;
    &:before,
    &:after {
      content: '';
      background: currentColor;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.2s linear;
    }
    &:before {
      width: 100%;
      height: 2px;
    }
    &:after {
      width: 2px;
      height: 100%;
    }
    &.active {
      transform: rotate(90deg);
      &:before {
        width: 0;
      }
    }
  }
}
</style>
