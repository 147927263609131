<template>
  <div class="e-youtube-video-player" :class="[{ ready }]" ref="rootEl">
    <div class="e-youtube-video-player__frame" ref="frameEl" />
    <!--    <icon class="e-youtube-video__mute" name="mute" v-if="muted" @click="unmute" />-->
  </div>
</template>

<script>
import { inject, onMounted, reactive, toRefs } from 'vue';
import { getCurrentAlpha2Code, getRandomId, loadYoutubeApi } from '@/utils/site-utils';
import { merge } from 'lodash';
import { webStorage } from '@/utils/web-storage';
import { S_LANG } from '@/utils/web-storage-keys';

export default {
  name: 'YoutubeVideo',
  props: {
    videoId: {
      type: String,
      default: 'dr3MvXkb7UQ'
    },
    controls: {
      type: Boolean
    },
    autoplay: {
      type: Boolean
    },
    mute: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object
    }
  },
  setup(props) {
    const toast = inject('toast');
    const _methods = {
      observePlayer() {
        if (!state.player) return;
        console.log('state.player.isMuted()', state.player.isMuted());
        state.muted = state.player.isMuted();
        setTimeout(() => {
          _methods.observePlayer();
        }, 1000);
      }
    };
    const methods = {
      unmute() {
        if (!state.player) return false;
        state.player.unMute();
        return true;
      }
    };
    const state = reactive({
      ready: false,
      rootEl: null,
      frameEl: null,
      player: null,
      muted: false
    });
    onMounted(async () => {
      const id = getRandomId();
      const lang = webStorage.get(S_LANG);
      const alpha2Code = getCurrentAlpha2Code();
      state.frameEl.id = id;
      window.YTConfig = { host: 'http://www.youtube.com' };
      const [YT, ex] = await loadYoutubeApi();
      if (ex) {
        // await toast.showEx(ex);
        return;
      }
      state.ready = true;
      YT.ready(() => {
        const muted = props.mute || props.autoplay;
        state.muted = muted;
        const options = merge(
          {
            videoId: props.videoId,
            width: '100%',
            height: '100%',
            playerVars: {
              playsinline: 1,
              controls: props.controls ? 1 : 0,
              autoplay: props.autoplay ? 1 : 0,
              loop: props.loop ? 1 : 0,
              modestbranding: 1,
              rel: 0,
              mute: muted ? 1 : 0,
              showinfo: 0,
              cc_lang_pref: alpha2Code ?? 'US',
              hl: lang,
              iv_load_policy: 3,
              enablejsapi: 1,
              listType: 'playlist',
              playlist: [],
              origin: window.location.origin
            }
          },
          props.options
        );
        console.log('options', options);
        state.player = new YT.Player(id, options);
      });
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-youtube-video-player {
  $c: &;
  position: relative;
  background: $black;
  &__frame {
    display: block;
    height: 100%;
  }
  &__mute {
    color: $white;
    display: none;
  }
  &.ready {
    height: calc(100vw * 9 / 16);
    #{$c}__mute {
      position: absolute;
      top: 60px;
      right: 60px;
      display: block;
    }
  }
  @include tablet-landscape {
    &.ready {
      height: 100vh;
    }
  }
}
</style>
