<template>
  <site-link v-bind="comAttrs" class="e-image-link" :class="{ disabled: fields.disabled?.value }" v-if="fields && $appVisible($route, fields)" @click="onClick">
    <AdaptiveImage :field="fields.image" :isBackground="false" />
  </site-link>
</template>
<script>
import { computed, getCurrentInstance } from 'vue';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { qtUtils } from '@/utils/ali-tracker-utils';
import * as querystring from 'querystring';
import { merge, debounce } from 'lodash';
export default {
  name: 'ImageLink',
  emits: ['click'],
  props: {
    fields: {
      type: Object
    },
    qtData: {
      type: Object
    }
  },
  setup(props, ctx) {
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const computes = {
      comAttrs: computed(() => {
        if (!isNullOrEmpty(props?.fields?.link?.value?.href)) {
          const { value } = props?.fields?.link;
          let { href } = value;
          if (/http:\/\/(mailto|tel):/gi.test(href)) {
            href = href.replace(/http:\/\//gi, '');
          }
          return {
            link: {
              value: {
                ...value,
                href
              }
            }
          };
        }
        return null;
      })
    };
    const _methods = {
      qtTrackClick() {
        const page = $jss.routeData();
        const { aliQtEnabled } = props.fields || {};
        if (aliQtEnabled?.value) {
          const qtEventCode = qtUtils.getBtnCode(page, props);
          if (isNullOrEmpty(qtEventCode)) return;
          const qtParams = methods.getTrackParams();
          qtUtils.trackClick(qtEventCode, qtParams);
        }
      }
    };
    const methods = {
      onClick: debounce((e) => {
        if (props.disabled) return;
        _methods.qtTrackClick();
        const { anchorId: id } = props.fields || {};
        if (id?.value) {
          const anchor = document.getElementById(id?.value);
          const offset = anchor.getBoundingClientRect();
          window.scrollTo(0, offset.top);
          return;
        }
        ctx.emit('click', e);
      }, 50),
      getTrackParams() {
        const { aliQtParams } = props.fields || {};
        const qtParams = {
          button_id: props.id ?? props?.fields?.link?.value.href,
          button_name: props.name ?? props?.fields?.link?.value.href,
          customized_button_name: props.fields?.text.value
        };
        if (!isNullOrEmpty(aliQtParams?.value)) {
          merge(qtParams, querystring.parse(aliQtParams?.value));
        }
        merge(qtParams, props.qtData);
        return qtParams;
      }
    };
    return {
      ...computes,
      ...methods
    };
  }
};
</script>
