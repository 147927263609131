<template>
  <div class="e-block-summary" :class="[{ [`size-${size}`]: size }]">
    <div class="e-block-summary__title" v-if="$slots.title"><slot name="title" /></div>
    <div class="e-block-summary__title" v-html="title" v-else />
    <div class="e-block-summary__main"><slot /></div>
  </div>
</template>

<script>
export default {
  name: 'BlockSummary',
  props: {
    title: {
      type: String
    },
    size: {
      type: String
    }
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-block-summary {
  $c: &;
  &__title {
    padding: 16px 0;
    &:empty {
      display: none;
    }
  }
  &__main {
    background: $grey-night-l;
    color: $white;
    .e-label-value {
      padding-left: 16px;
      padding-right: 16px;
      + .e-label-value {
        border-top: 2px solid $grey-night;
      }
      &.size {
        &-small {
          font-size: 12px;
          padding: 8px 16px;
        }
      }
    }
  }
  &.size {
    &-small {
      font-size: 12px;
      #{$c}__title {
        padding: 8px 0;
      }
    }
  }
}
</style>
