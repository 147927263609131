<script setup>
import { equalString } from '@/utils/string-utils';
const emit = defineEmits(['change']);
const props = defineProps({
  options: {
    type: Array
  },
  value: {
    type: Object
  }
});
const onCtClick = (e, ct) => {
  emit('change', ct);
};
</script>

<template>
  <div class="e-ct-selector" v-if="options?.length > 0">
    <div class="e-ct-selector__title" v-if="$slots.title">
      <slot name="title" />
    </div>
    <div class="e-ct-selector__options">
      <div class="e-ct-selector__option" :class="[{ active: $equalString(value?.code, ct.code) }]" v-for="ct in options" :key="ct.id" @click.stop="onCtClick($event, ct)">
        <icon :svg="ct.icon?.svgCode" size="tiny" v-if="$equalString(value?.code, ct.code)" />
        <span v-html="ct.name" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-ct-selector {
  &__options {
    display: flex;
  }
  &__option {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid $grey-taubmans;
    padding: 16px 24px;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.38, 0.015, 0, 0.995);
    user-select: none;
    &:not(:first-child) {
      border-left: none;
    }
    .e-icon {
      color: $yellow;
      filter: drop-shadow(0px 0px 12px $yellow);
      transition: all 0.2s cubic-bezier(0.055, 0.215, 0, 1);
    }
    &.active {
      border-color: $black;
      background: radial-gradient(85.19% 85.19% at 50.83% 0%, rgba(46, 46, 46, 0.8) 0%, #000000 100%);
      color: $white;
    }
  }
  @include desktop {
    &__options {
      row-gap: 8px;
      flex-wrap: wrap;
    }
    &__option {
      flex-basis: fit-content;
      flex-grow: unset;
      min-width: 150px;
    }
  }
}
</style>
