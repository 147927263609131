<template>
  <div v-bind="$data" class="e-text-animate" :class="{ animate: inScreen }" ref="rootEl">
    <slot></slot>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onBeforeUnmount } from 'vue';
export default {
  name: 'TextAnimate',
  setup(props) {
    const state = reactive({
      rootEl: null,
      inScreen: false
    });
    const onscroll = async (e) => {
      const rect = state.rootEl.getBoundingClientRect();
      if (rect.top < window.innerHeight) {
        state.inScreen = true;
      } else {
        state.inScreen = false;
      }
    };
    onMounted(() => {
      window.addEventListener('scroll', onscroll, { passive: false });
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onscroll);
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
.e-text-animate {
  $c: &;
  position: relative;
  &.animate {
    &:before {
      opacity: 0;
      width: 0;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #fff;
    opacity: 1;
    transition: all 1s linear;
  }
}
</style>
