<script setup>
const props = defineProps({
  customerTypes: {
    type: Array
  },
  value: {
    type: Object
  }
});
const emit = defineEmits(['item-click']);
const onCtClick = (e, ct) => {
  if (ct.disabled) return;
  if (props.value?.id !== ct.id) emit('item-click', ct);
};
</script>

<template>
  <div class="e-c-type-selector">
    <label class="e-c-type-selector__label" v-if="$slots.default"><slot /></label>
    <div class="e-c-type-selector__items">
      <div
        class="e-c-type-selector__item"
        :class="[{ active: $equalString(value?.fields?.code.value, ct.fields.code.value), disabled: ct.disabled }]"
        v-for="ct in customerTypes"
        :key="ct.id"
        @click.stop="onCtClick($event, ct)"
      >
        <icon :field="ct.fields.icon" />
        <jss-text :field="ct.fields.name" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-c-type-selector {
  $c: &;
  &__label {
    display: block;
    color: $grey-next;
    margin-bottom: 16px;
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 60px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 16px 12px 16px;
    border: 1px solid $grey-taubmans;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s cubic-bezier(0.055, 0.215, 0, 1);
    .e-icon {
      margin-bottom: 8px;
      color: $yellow;
      filter: drop-shadow(0px 0px 12px $yellow);
      opacity: 0;
      transition: all 0.2s cubic-bezier(0.055, 0.215, 0, 1);
    }
    &.active {
      border-color: $black;
      background: radial-gradient(85.19% 85.19% at 50.83% 0%, rgba(46, 46, 46, 0.8) 0%, #000000 100%);
      color: $white;
      .e-icon {
        opacity: 1;
      }
    }
    + #{$c}__item {
      border-left-width: 0;
    }
    &.disabled {
      background-color: rgba(75, 75, 75, 0.3);
      cursor: not-allowed;
    }
  }
}
</style>
