<script setup>
import { ref, inject } from 'vue';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { toElement } from '@/utils/dom-utils';
import { ERROR_CODES } from '@/utils/constants';

const props = defineProps({
  fields: {
    type: Object
  },
  dealer: {
    type: Object
  }
});
const emit = defineEmits(['change']);
const toast = inject('toast');
const showRequiredMessage = ref(false);
const rootEl = ref();
const onChangeDealer = () => {
  if (!props.fields?.editable.value) return;
  emit('change');
};
const validate = () => {
  let invalid = true;
  if (props.fields?.required.value) {
    invalid = isNullOrEmpty(props.dealer?.storeName);
  } else {
    invalid = false;
  }
  showRequiredMessage.value = invalid;
  if (invalid) {
    toast.showEx({ code: ERROR_CODES.FormDefaultError }).catch();
    toElement(rootEl.value);
  }
  return !invalid;
};
defineExpose({ validate });
</script>

<template>
  <div class="e-dealer-selector" :class="{ 'has-error': showRequiredMessage }" v-if="fields" ref="rootEl">
    <jss-rich-text class="text-secondary mg-b-24" :field="fields.title" />
    <div>
      <jss-rich-text class="text-desc mg-b-24" :field="fields.body" />
      <div class="e-dealer-selector__main" @click="onChangeDealer">
        <div class="e-dealer-selector__info">
          <icon name="pin-hollow" />
          <div class="e-dealer-selector__name">{{ $ifEmpty(dealer?.storeName, fields.placeholder.value) }}</div>
        </div>
        <jss-rich-text class="e-dealer-selector__btn" :field="fields[dealer ? 'changeText' : 'selectText']" v-if="fields.editable.value" />
      </div>
      <div class="e-dealer-selector__message">
        <jss-rich-text class="e-dealer-selector__error" :field="fields.requiredMessage" v-if="showRequiredMessage" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-dealer-selector {
  &__main {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
  &__name {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    padding-top: 2px;
  }
  &__btn {
    font-weight: bold;
    user-select: none;
    cursor: pointer;
    box-shadow: inset 0 0 0 $yellow;
    transition: 0.2s all cubic-bezier(0, 0, 0.4, 0.2);
    border-bottom: 1px solid $black;
    align-self: center;
    &:hover {
      box-shadow: inset 200px 0 0 $yellow;
      color: $black;
    }
  }
  &__message {
    height: 40px;
    position: relative;
    font-size: 12px;
  }
  &__error {
    position: absolute;
    right: 0;
    top: 4px;
    color: $red;
  }
}
</style>
