<script setup>
import { inject, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { debounce } from 'lodash';

const { deviceComputes } = inject('device-common');
const emit = defineEmits(['search', 'change']);
const props = defineProps({
  title: {
    type: String
  },
  label: {
    type: String
  },
  dataName: {
    type: String
  },
  placeholder: {
    type: String
  },
  icon: {
    type: String
  },
  options: {
    type: Array,
    default: () => []
  },
  value: {
    type: String
  },
  errorMsg: {
    type: String
  },
  infoMsg: {
    type: String
  },
  successMsg: {
    type: String
  },
  required: {
    type: Boolean,
    default: false
  },
  maxLength: null
});
const rootEl = ref();
const modalSearchEl = ref();
const val = ref('');
const focused = ref(false);
/**@type Ref<ModalRef>*/
const optionsModalRef = ref();
const onInput = debounce((e) => {
  emit('search', e, e.target.value);
}, 200);
const onFocus = async (e) => {
  focused.value = true;
  if (deviceComputes.isMobileOrTablet.value) {
    await optionsModalRef.value.open();
    modalSearchEl.value?.focus();
  }
};
const onOptionClick = (e, option) => {
  focused.value = false;
  val.value = option.code;
  emit('change', e, option);
  if (deviceComputes.isMobileOrTablet.value) {
    optionsModalRef.value.close();
  }
};
const onSearchIconClick = async (e) => {
  focused.value = true;
  if (deviceComputes.isMobileOrTablet.value) {
    await optionsModalRef.value.open();
    modalSearchEl.value?.focus();
  } else {
    e.currentTarget.previousSibling.focus();
  }
};
const onBodyClick = (e) => {
  if (!rootEl.value.contains(e.target) && rootEl.value !== e.target && !optionsModalRef.value?.rootEl.contains(e.target) && optionsModalRef.value?.rootEl !== e.target) {
    focused.value = false;
  }
};
watch(
  () => props.value,
  (_value) => {
    val.value = _value;
  },
  {
    immediate: true
  }
);
watch(
  () => [deviceComputes.isMobileOrTablet, focused],
  ([_mt, _f]) => {
    if (_mt.value && _f.value) {
      optionsModalRef.value.open();
    } else {
      optionsModalRef.value.close();
    }
  }
);
onMounted(() => {
  document.body.addEventListener('click', onBodyClick);
});
onBeforeUnmount(() => {
  document.body.removeEventListener('click', onBodyClick);
});
</script>

<template>
  <div class="e-autocomplete-input" ref="rootEl">
    <div class="e-autocomplete-input__title" v-html="title" />
    <label class="e-autocomplete-input__label" v-if="label">
      {{ label }}
      <span class="e-autocomplete-input__required" v-if="required">*</span>
    </label>
    <div class="e-autocomplete-input__content" :class="[{ active: focused }]">
      <div class="e-autocomplete-input__main">
        <icon :svg="icon" size="tiny" @click="onSearchIconClick" />
        <input type="text" :placeholder="placeholder" :maxlength="maxLength" @input="onInput" @focus="onFocus" v-model="val" />
      </div>
      <transition name="options-ani">
        <div class="e-autocomplete-input__options" v-if="!$deviceComputes.isMobileOrTablet.value && options?.length > 0 && focused">
          <template v-for="(option, index) in options" :key="index">
            <div class="e-autocomplete-input__option" :class="{ selected: option.code === value }" @click="(e) => onOptionClick(e, option)">
              <div class="e-autocomplete-input__option-text" v-html="option.text" />
              <icon name="forward" size="tiny" />
            </div>
          </template>
        </div>
      </transition>
    </div>
    <div class="e-autocomplete-input__messages">
      <div class="e-form-input__error-msg" v-if="errorMsg">{{ errorMsg }}</div>
      <div class="e-form-input__info-msg" v-if="infoMsg">{{ infoMsg }}</div>
      <div class="e-form-input__success-msg" v-if="successMsg">{{ successMsg }}</div>
    </div>
    <modal class="e-autocomplete-input__modal" ref="optionsModalRef" animation="bottom-slide-in" :fire-gtm="false" closable :sticky="false">
      <div class="e-autocomplete-input__modal-search">
        <div class="e-autocomplete-input__modal-input">
          <icon :svg="icon" @click="onSearchIconClick" />
          <input type="text" :placeholder="placeholder" @input="onInput" v-model="val" ref="modalSearchEl" />
        </div>
      </div>
      <div class="e-autocomplete-input__modal-options">
        <template v-for="(option, index) in options" :key="index">
          <div class="e-autocomplete-input__modal-option-custom" v-if="$slots.option">
            <slot name="option" :option="option" />
          </div>
          <div class="e-autocomplete-input__modal-option" :class="{ selected: option.code === val }" @click="(e) => onOptionClick(e, option)" v-else>
            <div class="e-autocomplete-input__modal-option-text" v-html="option.text" />
            <icon name="forward" size="tiny" />
          </div>
        </template>
      </div>
    </modal>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-autocomplete-input {
  $c: &;
  $m: '.e-modal';
  &__content {
    position: relative;
    &.active {
      z-index: 3;
    }
  }
  &__title {
    margin-bottom: 24px;
    font-size: 26px;
    font-weight: 500;
    &:empty {
      display: none;
    }
  }
  &__label {
    display: block;
    line-height: 1.3;
    margin-bottom: 4px;
  }
  &__main {
    height: 56px;
    border: 1px solid currentColor;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    gap: 8px;
    input {
      border: none !important;
      outline-width: 0 !important;
      padding: 0 !important;
      margin-bottom: 0 !important;
      flex-grow: 1;
      &::placeholder {
        color: $grey;
        @include h9;
      }
    }
  }
  &__messages {
    height: 40px;
    line-height: 1.3;
    font-size: 12px;
    padding-top: 8px;
    text-align: end;
  }
  &__error-msg {
    color: $red;
  }
  &.has-error {
    #{$c}__main {
      border-color: $red;
    }
  }
  &__options {
    position: absolute;
    top: 55px;
    width: 100%;
    border: 1px solid $black;
    padding: 0 15px;
    background: $white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  }
  &__option {
    padding: 16px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    &-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    + #{$c}__option {
      border-top: 1px solid $grey-taubmans;
    }
  }
  &__modal {
    align-items: flex-end;
    &.e-modal {
      #{$m}__content {
        width: 100%;
        padding: 48px 0 24px 0;
      }
    }
    &-search {
      background: $grey-light;
      padding: 16px;
    }
    &-input {
      height: 32px;
      border: 1px solid transparent;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      gap: 8px;
      background: $white;
      input {
        border: none !important;
        outline-width: 0 !important;
        padding: 0 !important;
        margin-bottom: 0 !important;
        flex-grow: 1;
        &::placeholder {
          color: $grey;
          @include h9;
        }
      }
      .e-icon {
        color: $grey;
        svg {
          height: 16px;
        }
      }
    }
    &-options {
      max-height: 320px;
      min-height: 40vh;
      padding-top: 8px;
      overflow-y: auto;
    }
    &-option {
      padding: 12px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
