import { settingValue } from '@/utils/site-utils';
import { largeThanTablet } from '@/utils/dom-utils';

const GridLayout = (el, binding) => {
  let defaultVal = el.dataset?.layoutDefault ?? '0-24';
  let gridVal = settingValue(binding.value, defaultVal);
  let [start, span] = gridVal.split('-').map((x) => Number(x) ?? 0);
  start += 1;
  let style;
  if (largeThanTablet()) {
    style = `auto/${start}/auto/span ${span}`;
  } else {
    style = `auto/${Math.max(Math.floor(start / 2), 1)}/auto/span ${Math.max(Math.floor(span / 2), 1)}`;
  }
  el.style.gridArea = style;
  el.classList.add(`grid-${gridVal}`);
};
export default GridLayout;
