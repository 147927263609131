<template>
  <BackgroundImage v-bind="$data" :hasAnimation="hasAnimation" :enlargement="enlargement" :enlarge="enlarge" :parallax="parallax" :isBackground="isBackground" :image="image">
    <slot />
  </BackgroundImage>
</template>

<script>
import { onBeforeMount, onBeforeUnmount, reactive, toRefs } from 'vue';
import { BREAKPOINTS } from '@/utils/constants';

export default {
  name: 'AdaptiveBackImage',
  props: {
    pcImage: {
      type: Object
    },
    mobileImage: {
      type: Object
    },
    hasAnimation: {
      type: Boolean,
      default: false
    },
    enlargement: {
      type: Boolean,
      default: false
    },
    enlarge: {
      type: Boolean,
      default: true
    },
    parallax: {
      type: Boolean,
      default: false
    },
    isBackground: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const state = reactive({
      image: props.mobileImage
    });
    const judgeImage = () => {
      if (window.innerWidth >= BREAKPOINTS.tablet) {
        state.image = props.pcImage;
      } else {
        state.image = props.mobileImage;
      }
    };
    const onResize = () => {
      judgeImage();
    };
    onBeforeMount(() => {
      window.addEventListener('resize', onResize);
      judgeImage();
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize);
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss"></style>
