<script setup>
import { getCurrentInstance, ref } from 'vue';
import services from '@/services';
import { getAgentListByTypes, getSalesAgentList } from '@/services/agentService';
import { getPageAlpha2Code, loadGMap } from '@/utils/site-utils';
import useAppStore from '@/store/appStore';
import { merge } from 'lodash';

/**
 * @typedef DealerSelectModalFields
 * @property {SimpleField} title
 * @property {SimpleField} description
 * */
let timeout;
const emit = defineEmits(['opened', 'get-geo']);
const props = defineProps({
  /**@type DealerSelectModalFields*/
  fields: {
    type: Object
  },
  purpose: {
    type: String,
    default: 'get-dealer'
  },
  dealerTypes: {
    type: Array,
    default: () => [1]
  },
  series: {
    type: String
  }
});
/**@type AppStore*/
const appStore = useAppStore();
const { proxy } = getCurrentInstance();
const { $jss } = proxy;
const modalRef = ref();
const modalFormRef = ref();
const dealers = ref([]);
const dealer = ref(null);
const payable = ref(true);
const dealerClick = ref(() => null);
const setKeyword = async (type, keyword) => {
  modalFormRef.value.updateItem('keyword', {
    controlProps: {
      value: keyword
    }
  });
  const page = $jss.routeData();
  const alpha2Code = getPageAlpha2Code(page);
  const gmaps = await loadGMap(page);
  if (!gmaps?.places.AutocompleteService) return;
  const service = new gmaps.places.AutocompleteService();
  const types = [];
  switch (type) {
    case 'zipCode': {
      types.push('postal_code');
      break;
    }
    case 'city': {
      types.push('postal_code', 'locality');
      break;
    }
  }
  service.getPlacePredictions({ input: keyword, types, componentRestrictions: { country: alpha2Code } }, (predictions, status) => {
    if (status !== gmaps.places.PlacesServiceStatus.OK || !predictions) return;
    const options = (predictions ?? []).map((x) => ({
      code: x.structured_formatting.main_text,
      text: `${x.structured_formatting.main_text}<span style="color: #B3B3B3">, ${x.structured_formatting.secondary_text}</span>`,
      data: {
        placeId: x.place_id
      }
    }));

    modalFormRef.value.updateItem('keyword', {
      controlProps: {
        options
      }
    });
  });
};
const onClose = () => {
  modalRef.value.close();
};
const onOpened = () => {
  emit('opened');
};
const open = async (selectedDealer, _payable = false, autoFetch = false) => {
  const page = $jss.routeData();
  const alpha2Code = getPageAlpha2Code(page);
  if (timeout) clearTimeout(timeout);
  dealer.value = selectedDealer;
  payable.value = _payable;
  if (autoFetch && !dealers.value?.length > 0) {
    const query = {
      radius: 1000000
    };
    if (appStore.hasGeoLocation) {
      merge(query, {
        longitude: appStore.geoLocation.longitude,
        latitude: appStore.geoLocation.latitude
      });
    }
    let dealerList = await getAgentListByTypes(props.page, alpha2Code, query, props.series, props.dealerTypes);
    setDealerList(dealerList);
  }
  if (props.purpose === 'get-dealer') {
    return new Promise((resolve) => {
      dealerClick.value = (clickedDealer) => {
        clearTimeout(timeout);
        resolve(clickedDealer);
        modalRef.value.close();
      };
      modalRef.value.open();
      timeout = setTimeout(() => {
        resolve(selectedDealer);
        modalRef.value.close();
      }, 1000 * 60 * 5);
    });
  } else {
    return modalRef.value.open();
  }
};
const close = async () => {
  modalRef.value.close();
};
const setDealers = (dealerList, _payable = false) => {
  payable.value = _payable;
  setDealerList(dealerList);
};
const setDealerList = (dealerList) => {
  if (payable.value) {
    dealerList = dealerList?.filter((x) => !!x.stripeOpen);
  }
  dealers.value = dealerList;
  if (dealerList?.length) {
    dealer.value = dealerList.find((x) => x.code === props.dealer?.code);
  }
};
const onSearch = async (value, item) => {
  const page = $jss.routeData();
  const alpha2Code = getPageAlpha2Code(page);
  if (props.purpose === 'get-dealer') {
    if (value?.length < 3) {
      const query = {
        radius: 1000000
      };
      if (appStore.hasGeoLocation) {
        merge(query, {
          longitude: appStore.geoLocation.longitude,
          latitude: appStore.geoLocation.latitude
        });
      }
      let dealerList = await getAgentListByTypes(props.page, alpha2Code, query, props.series, props.dealerTypes);
      setDealerList(dealerList);
      return;
    }
  }
  if (!item.controlProps.selectedOption) return;
  const geoResult = await services.map.geoDecode(page, {
    placeId: item.controlProps.selectedOption.data.placeId
  });
  if (!geoResult) return;
  emit('get-geo', geoResult);
  if (props.purpose === 'get-dealer') {
    let dealerList = await getAgentListByTypes(
      props.page,
      alpha2Code,
      {
        latitude: geoResult.lat,
        longitude: geoResult.lng,
        radius: 1000000
      },
      props.series,
      props.dealerTypes
    );
    setDealerList(dealerList);
  }
};
const onFormChange = async (name, value, item) => {
  if (name === 'keyword') {
    await onSearch(value, item);
  }
};
defineExpose({ setKeyword, setDealers, open, close });
</script>

<template>
  <modal class="e-dealer-select-modal" animation="right-slide-in" :sticky="false" ref="modalRef" @opened="onOpened">
    <div class="e-dealer-select-modal__header mg-b-8">
      <div class="e-dealer-select-modal__top">
        <icon class="e-dealer-select-modal__close" name="close" @click="onClose" />
      </div>
    </div>
    <jss-rich-text class="fs-26 ls-2 mg-b-16" :field="fields?.title" />
    <jss-rich-text class="text-desc" :field="fields?.description" />
    <dynamic-form class="e-dealer-select-modal__form" ref="modalFormRef" :form="fields?.form" @change="onFormChange" />
    <dealer-radio-list
      :class="{ 'list-only': !fields?.form }"
      :fields="fields ?? {}"
      :render-type="$settingValue(fields?.dealerCardStyle, 'dealer-card-v2')"
      :dealers="dealers"
      :dealer="dealer"
      v-if="purpose === 'get-dealer'"
      @dealer-click="dealerClick"
    />
  </modal>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-dealer-select-modal {
  $m: '.e-modal';
  justify-content: flex-end;
  &.e-modal {
    #{$m}__content {
      width: 100%;
      height: 100vh;
      padding: 0 24px 24px;
    }
  }
  &__header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
    background-color: $white;
  }
  &__close {
    cursor: pointer;
    svg {
      height: 32px;
    }
  }
  &__form {
    position: sticky;
    top: 40px;
    background: $white;
    padding: 40px 0 0 0;
  }
  .e-dealer-radio-list {
    &__dealer {
      &:first-child {
        padding-top: 0;
      }
    }
  }
  @include tablet-landscape {
    &.e-modal {
      #{$m}__close {
        top: 36px;
        right: grid-width(1);
      }
      #{$m}__content {
        width: grid-width(10);
        padding: 0 grid-width(1) 24px grid-width(1);
      }
    }
  }
}
</style>
