<template>
  <div v-bind="$data" class="e-menu-trigger" :class="[...rootClasses]">
    <span />
    <span />
    <span />
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'MenuTrigger',
  props: {
    active: {
      type: Boolean
    },
    theme: {
      type: String,
      default: 'black'
    }
  },
  setup(props) {
    const computes = {
      rootClasses: computed(() => [
        {
          active: props.active,
          [`theme-${props.theme}`]: props.theme
        }
      ])
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-menu-trigger {
  position: relative;
  width: 18px;
  height: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
  > span {
    display: block;
    width: 18px;
    height: 2px;
    margin-top: 4px;
    background: $white;
    transform-origin: center;
    transition: all 0.2s linear;
    &:first-child {
      margin-top: 0;
    }
  }
  &.theme- {
    &black {
      > span {
        background: $black;
      }
    }
    &yellow,
    &white {
      > span {
        background: $black;
      }
    }
  }
  &.active {
    > span {
      position: absolute;
      margin: 0;
      &:nth-child(1) {
        transform: rotate(-45deg);
      }
      &:nth-child(2) {
        width: 0;
      }
      &:nth-child(3) {
        transform: rotate(45deg);
      }
    }
  }
}
</style>
