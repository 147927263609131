<template v-if="field?.fields">
  <youtube-video-player
    :video-id="field.fields.videoId?.value"
    :controls="field.fields.controls?.value"
    :autoplay="field.fields.autoplay?.value"
    :mute="field.fields.mute?.value"
    :modestbranding="isNaN(field.fields.modestbranding?.value) ? 1 : Number(field.fields.modestbranding?.value)"
  />
</template>

<script>
export default {
  name: 'YoutubeVideoField',
  props: {
    field: {
      type: Object
    }
  }
};
</script>

<style lang="scss"></style>
