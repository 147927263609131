<template>
  <rich-text :tag="tag" :editable="editable" :field="richField" class="e-rich-text" :class="[{ 'has-link-animation': hasLinkAnimation }]" ref="rootEl" />
</template>

<script>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { reactive, toRefs, watch } from 'vue';
import { getBetterUrl } from '@/utils/uri-utils';
import { canUseDOM } from '@/utils/dom-utils';
import { debounce } from 'lodash';
import { APP_CONST } from '@/utils/constants';
export default {
  name: 'SiteRichText',
  components: { RichText },
  props: {
    field: {
      type: Object
    },
    hasLinkAnimation: {
      type: Boolean,
      default: true
    },
    tag: {
      type: String
    },
    editable: {
      type: Boolean
    }
  },
  setup(props) {
    const state = reactive({
      richField: props.field
    });
    const _methods = {
      update: debounce(() => {
        if (!canUseDOM() || !props.field || props.editable) {
          state.richField = props.field;
          return;
        }
        const { value, ...rest } = props.field;
        const div = document.createElement('DIV');
        div.innerHTML = value;
        const links = div.getElementsByTagName('A');
        for (let link of links) {
          link.href = getBetterUrl(link.href);
        }
        state.richField = {
          value: div.innerHTML,
          ...rest
        };
        div.remove();
      }, 100)
    };
    watch(
      () => props.field,
      (field) => {
        _methods.update();
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-rich-text {
  &.has-link-animation {
    a[href] {
      display: inline-block;
      text-decoration: underline;
      position: relative;
      box-shadow: inset 0 0 0 0 $yellow;
      transition: all 600ms cubic-bezier(0.355, 0.005, 0.26, 1);
      &:hover {
        box-shadow: inset 500px 0 0 0 $yellow;
        color: $black;
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}
</style>
