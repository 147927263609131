<template>
  <div class="e-radio-list">
    <div class="e-radio-list__overline" v-html="overline" v-if="overline" />
    <div class="e-radio-list__label" v-html="`${label}${required ? '*' : ''}`" v-if="label" />
    <div class="e-radio-list__items" :style="columnCountStyle">
      <div
        class="e-radio-list__item"
        :class="[{ checked: option?.code === selectedOption?.code }]"
        @click="(e) => onCheckChange(e, index, option)"
        v-for="(option, index) in options"
        :key="option.code"
      >
        <site-radio :checked="option?.code === selectedOption?.code" />
        <div class="e-radio-list__item-label" :class="[{ clickable: labelClickable }]" v-html="option.text" @click="onLabelClick" />
      </div>
    </div>
    <div class="e-radio-list__error-box">
      <div class="e-radio-list__error-msg" v-if="errorMsg">{{ errorMsg }}</div>
    </div>
  </div>
</template>

<script>
import useDevice from '@/hooks/useDevice';
import { computed, onUpdated, reactive, toRefs, watch } from 'vue';
import { appendTextToHtmlEnd, canUseDOM } from '@/utils/dom-utils';

export default {
  name: 'CheckList',
  emits: ['change'],
  props: {
    overline: {
      type: String
    },
    label: {
      type: String
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    selectedOption: null,
    required: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String
    },
    columnCountMobile: {
      type: Number,
      default: 2
    },
    columnCountTablet: {
      type: Number,
      default: 2
    },
    columnCountDesktop: {
      type: Number,
      default: 2
    },
    labelClickable: {
      type: Boolean,
      default: true
    },
    free: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const { deviceComputes } = useDevice();
    const computes = {
      columnCountStyle: computed(() => {
        const count = (deviceComputes.isMobileDevice.value ? props.columnCountMobile : deviceComputes.isTabletDevice.value ? props.columnCountTablet : props.columnCountDesktop) ?? 3;
        return {
          'grid-template-columns': `repeat(${count}, 1fr)`
        };
      })
    };
    const methods = {
      onCheckChange(e, index, option) {
        if (props.selectedOption?.code === option?.code && !props.free) return;
        ctx.emit('change', e, index, option);
      },
      onLabelClick(e) {
        if (e.target.tagName === 'A' || !props.labelClickable) {
          e.stopPropagation();
        }
      }
    };
    return {
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-radio-list {
  $c: &;
  &__overline {
    margin-bottom: $space-16;
  }
  &__label {
    margin-bottom: $space-20;
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: $space-20;
    grid-column-gap: $space-20;
  }
  &__item {
    display: flex;
    align-items: center;
    gap: $space-16;
    &-label {
      padding-top: 1px;
      &.clickable {
        user-select: none;
        cursor: pointer;
      }
    }
  }
  &__error-box {
    height: 40px;
    text-align: end;
    padding-top: $space-8;
  }
  &__error-msg {
    color: $red;
    @include h11;
  }
}
</style>
