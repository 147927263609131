<template>
  <div v-bind="$data" class="e-video-player" :class="[...rootClasses]" ref="rootEl">
    <div class="e-video-player__content" v-if="showPlayButton" @click="onPlay">
      <div class="e-video-player__play-btn" :style="{ display: playBtnVisible ? 'block' : 'none' }">
        <icon name="play" size="auto" v-if="playing" />
        <icon name="pause" size="auto" v-else />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, reactive, toRefs, watch, computed, nextTick } from 'vue';
import videojs from 'video.js';
import { gtmPush } from '@/utils/gtm-utils';
import lottie from 'lottie-web';
import animationData from '../assets/loading.json';

export default {
  name: 'VideoPlayer',
  emits: ['canplay', 'play', 'pause', 'ended', 'loadeddata'],
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    showPlayButton: {
      type: Boolean,
      default: false
    },
    hasAnimation: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const state = reactive({
      rootEl: null,
      videoEl: null,
      player: null,
      playBtnVisible: false,
      inScreen: false,
      width: null,
      height: null,
      playing: null,
      played: false
    });
    const computes = {
      rootClasses: computed(() => [
        {
          'has-animation': props.hasAnimation,
          'in-screen': state.inScreen,
          'disable-fullscreen': props.options?.disableFullscreen ?? false,
          'disable-unmute': props.options?.disableUnmute ?? false
        }
      ])
    };
    const methods = {
      initPlayer() {
        if (state.player) {
          videojs(state.videoEl).dispose();
        }
        state.videoEl = document.createElement('video');
        state.videoEl.classList.add('video-js');
        state.rootEl?.appendChild(state.videoEl);
        state.playing = props.options?.autoplay ?? false;
        state.player = videojs(state.videoEl, { ...(props.options || {}), playsinline: true }, function onPlayerReady() {
          state.width = this.videoWidth();
          state.height = this.videoHeight();
          state.player.on('canplay', (e) => {
            ctx.emit('canplay', this, e);
          });
          state.player.on('play', (e) => {
            ctx.emit('play', this, e);
            state.playing = true;
          });
          state.player.on('pause', (e) => {
            ctx.emit('pause', this, e);
            state.playing = false;
          });
          state.player.on('ended', (e) => {
            ctx.emit('ended', this, e);
            state.playing = false;
            state.played = true;
          });
          state.player.on('loadeddata', (e) => {
            ctx.emit('loadeddata', this, e);
          });
        });
        const progressEl = state.player.el_?.querySelector('.vjs-progress-control');
        progressEl.addEventListener(
          'click',
          (e) => {
            const time = e.target.querySelector('.vjs-time-tooltip')?.innerText;
            const timeArr = time.split(':');
            const sec = timeArr.reduce((total, part, index) => {
              return total + parseInt(part) * Math.pow(60, timeArr.length - 1 - index);
            }, 0);
            state.player.currentTime(sec);
          },
          true
        );
        methods.addLotusLoading();
      },
      addLotusLoading: () => {
        const loadingWrapEl = state.rootEl.querySelector('.vjs-loading-spinner');
        const loadingEl = document.createElement('div');
        loadingEl.classList.add('e-video-player__loading');
        lottie.loadAnimation({
          container: loadingEl,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: animationData
        });
        loadingWrapEl.appendChild(loadingEl);
      },
      onPlay() {
        if (state.playing) {
          if (props.showPlayButton) {
            state.playBtnVisible = true;
            setTimeout(() => {
              state.playBtnVisible = false;
            }, 500);
          }
          state.player.pause();
          state.playing = false;
        } else {
          if (props.showPlayButton) {
            state.playBtnVisible = true;
            setTimeout(() => {
              state.playBtnVisible = false;
            }, 500);
          }
          state.player.play().then(() => {
            state.playing = true;
          });
          gtmPush({
            event: 'video_play_icon'
          });
        }
      }
    };
    watch(
      () => props.options,
      () => {
        methods.initPlayer();
      },
      {
        deep: true
      }
    );
    // f: scroll flag
    const judgeVideo = (f) => {
      const rect = state.rootEl?.getBoundingClientRect();
      state.inScreen = rect.top < window.innerHeight && rect.bottom > 50;
      if (state.player) {
        if (state.inScreen && props.options?.autoplay) {
          if (f && !state.playing && state.played && !props.options?.loop) {
            return;
          }
          state.player.play().then(() => {
            state.playing = true;
          });
        } else {
          if (f && state.playing) {
            state.player.pause();
            state.playing = false;
          }
        }
      }
    };
    const onscroll = async () => {
      judgeVideo(true);
    };
    onMounted(() => {
      nextTick(() => {
        methods.initPlayer();
      });
      window.addEventListener('scroll', onscroll, { passive: false });
      judgeVideo(false);
    });
    onBeforeUnmount(() => {
      if (state.player) {
        if (state.playing) {
          state.player.pause();
          state.playing = false;
        }
        state.player.currentTime(0);
        state.player.dispose();
      }
      window.removeEventListener('scroll', onscroll);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
.e-video-player {
  $e: &;
  position: relative;
  height: 100%;
  &.has-animation {
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      height: 100%;
      background: rgba($blue-night, 0.9);
      box-shadow: 0 2px 2px $blue-night;
      transition: all 1s linear;
    }
    &.in-screen {
      &:before {
        opacity: 0;
      }
    }
  }
  .vjs-poster {
    background-size: cover;
    background-position: center;
    background-color: transparent;
  }
  &__content {
    position: absolute;
    top: 0;
    bottom: 50px;
    left: 0;
    right: 0;
    z-index: 1;
  }
  &__play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-left: -25px;
    margin-top: -25px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: btn-fadeout 0.5s linear 1 normal forwards;
  }
  .video-js {
    width: 100%;
    height: 100%;
    .vjs-loading-spinner {
      width: 100px;
      height: 100px;
      margin: -50px 0 0 -50px;
      border: 0;
      border-radius: 50px;
      &:before,
      &:after {
        display: none !important;
      }
    }
    .vjs-big-play-button {
      display: none;
    }
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    .vjs-control-bar {
      .vjs-volume-panel {
        right: 54px !important;
        .vjs-volume-control .vjs-control .vjs-volume-horizontal {
          display: none;
        }
      }
      &:after {
        width: 100%;
        height: 80px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
      }
      .vjs-button > .vjs-icon-placeholder:before {
        font-size: 2.4em;
        line-height: 1.26;
      }
    }
    button {
      font-size: 10px !important;
    }
  }
  &.disable-fullscreen {
    .vjs-fullscreen-control {
      display: none !important;
    }
  }
  &.disable-unmute {
    .vjs-volume-panel {
      display: none !important;
    }
  }
  @include tablet-landscape {
    .video-js {
      .vjs-control-bar {
        .vjs-volume-panel {
          right: 80px !important;
        }
        &:after {
          height: 160px;
        }
        .vjs-button > .vjs-icon-placeholder:before {
          font-size: 3.2em;
          line-height: 0.9;
        }
      }
      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  @keyframes btn-fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: scale(2);
    }
  }
}
</style>
