<script setup>
import { isNullOrEmpty } from '@/utils/obj-utils';
import { sitecoreProps } from '@/utils/constants';
import { settingValue } from '@/utils/site-utils';
import { computed } from 'vue';
import { getBetterUrl } from '@/utils/uri-utils';
import { ifEmpty } from '@/utils/string-utils';
const props = defineProps({
  fields: {
    type: Object
  },
  loading: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  ...sitecoreProps
});
const emit = defineEmits(['click']);
const rootClasses = computed(() => {
  const { type, theme } = props.fields ?? {};
  const typeVal = settingValue(type, 'primary-button');
  const themeVal = settingValue(theme, 'yellow');
  return [typeVal, `theme-${themeVal}`, { disabled: props.disabled }];
});
const href = computed(() => getBetterUrl(props.fields?.link.value?.href));
const btnText = computed(() => ifEmpty(props.fields?.text.value, props.fields?.link.value?.text));
const componentType = computed(() => (isNullOrEmpty(href.value) ? 'button' : 'site-link'));
const componentAttrs = computed(() => ({
  link: props.fields?.link
}));
const onClick = (e) => {
  if (props.disabled) {
    e.preventDefault();
    return false;
  }
  emit('click', e);
  return true;
};
</script>

<template>
  <component class="e-menu-button" :is="componentType" v-bind="componentAttrs" :class="rootClasses" v-if="!isNullOrEmpty(btnText)" @click="onClick">
    <spinner name="ball-beat" size="small" v-if="loading" />
    <span v-else>{{ btnText }}</span>
  </component>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-menu-button {
  display: inline-flex;
  padding: 12px 30px;
  font-size: 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  .e-spinner {
    opacity: 0.7;
  }
  &.primary-button {
    background: $yellow;
    border: 1px solid $yellow;
    color: $black;
    &.theme {
      &-light {
        background: $white;
        border-color: $white;
        color: $black;
      }
      &-dark {
        background: $black;
        border-color: $black;
        color: $white;
      }
    }
    &.disabled {
      background-color: $grey-light;
      border-color: $grey-light;
      color: $grey-next;
    }
  }
  &.secondary-button {
    border: 1px solid $black;
    background: transparent;
    color: $black;
    &.theme {
      &-yellow {
        border-color: $yellow;
        color: $yellow;
      }
      &-light {
        border-color: $white;
        color: $white;
      }
      &-dark {
        border-color: $black;
        color: $black;
      }
    }
    &.disabled {
      background-color: $grey-light;
      border-color: $grey-light;
      color: $grey-next;
    }
  }
}
</style>
