<template>
  <div v-bind="$data" class="e-date-input" :class="[...rootClasses, { disabled }]">
    <div class="e-date-input__label" v-html="`${label}${required ? '*' : ''}`" v-if="label" />
    <div class="e-date-input__wrapper">
      <div class="e-date-input__main">
        <div class="e-date-input__main-input">
          <input
            class="e-date-input__input"
            :value="value"
            :name="dataName"
            :placeholder="placeholder"
            :maxlength="10"
            type="text"
            :disabled="disabled"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
          />
        </div>
      </div>
      <div class="e-date-input__side" v-if="$slots.default">
        <slot />
      </div>
      <div class="e-date-input__icon" v-if="icon">
        <Icon :svg="icon.value.svgCode" />
      </div>
    </div>
    <div class="e-date-input__messages">
      <div class="e-date-input__error-msg" v-if="errorMsg">{{ errorMsg }}</div>
      <div class="e-date-input__info-msg" v-if="infoMsg">{{ infoMsg }}</div>
      <div class="e-date-input__success-msg" v-if="successMsg">{{ successMsg }}</div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue';
import { formatDate } from '@/utils/date-utils';

export default {
  name: 'DateInput',
  emits: ['change', 'focus', 'blur'],
  props: {
    theme: {
      type: Object
    },
    label: {
      type: String
    },
    dataName: {
      type: String
    },
    inputType: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd'
    },
    value: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String
    },
    infoMsg: {
      type: String
    },
    successMsg: {
      type: String
    },
    icon: {
      type: Object
    },
    reverse: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const state = reactive({
      /**@type {String|null}*/
      error: null,
      focused: false
    });
    const computes = {
      rootClasses: computed(() => [
        {
          focus: state.focused,
          [`theme-${props.theme?.fields.phrase.value}`]: props.theme?.fields.phrase.value,
          'has-value': !!props.value,
          'has-error': !!props.errorMsg,
          'has-icon': !!props.icon,
          reverse: props.reverse?.value ?? props.reverse
        }
      ])
    };
    const _methods = {
      formatDateValue(value) {
        if (value.length >= 10) {
          value = formatDate(value.substr(0, 10), props.format);
        }
        if (value[value.length - 1] === state.separator) {
          value = value.substr(0, value.length - 1);
        }

        if (/\D\/$/.test(value)) {
          value = value.substr(0, value.length - 3);
        }
        const values = value.split(state.separator).map(function(v) {
          return v.replace(/\D/g, '');
        });

        const maxYear = 4000;
        let year = null,
          month = null,
          day = null;
        if (values[state.dateFormat.year.index]) {
          values[state.dateFormat.year.index] = year = _methods.checkValue(values[state.dateFormat.year.index], maxYear);
        }
        if (values[state.dateFormat.month.index]) {
          values[state.dateFormat.month.index] = month = _methods.checkValue(values[state.dateFormat.month.index], 12);
        }

        if (values[state.dateFormat.day.index]) {
          values[state.dateFormat.day.index] = day = _methods.checkValue(values[state.dateFormat.day.index], 31);
        }

        const output = values.map(function(v, i) {
          if (
            (i === state.dateFormat.year.index && v.length === state.dateFormat.year.length) ||
            (i === state.dateFormat.month.index && v.length === state.dateFormat.month.length) ||
            (i === state.dateFormat.day.index && v.length === state.dateFormat.day.length)
          ) {
            return v + state.separator;
          } else {
            return v;
          }
        });
        let timeStamp = null;
        if (year && month && day) {
          timeStamp = new Date(year, month, day).getTime();
        }
        return [output.join('').substr(0, 10), timeStamp];
      },
      checkValue(str, max) {
        if (str.charAt(0) !== '0' || str === '00') {
          let num = parseInt(str);
          if (isNaN(num) || num <= 0 || num > max) num = 1;
          str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
        }
        return str;
      }
    };
    const methods = {
      onInput: (e) => {
        let { value } = e.target;
        let timeStamp = null;
        [value, timeStamp] = _methods.formatDateValue(value);
        if (e.keyCode === 8 || e.inputType === 'deleteContentBackward') {
          if (value[value.length - 1] === state.separator) {
            e.target.value = value.substring(0, value.length - 2);
          }
        } else {
          e.target.value = value;
        }
        ctx.emit('change', e, e.target.value, timeStamp);
      },
      onChange: (e) => {
        let { value } = e.target;
        let timeStamp = null;
        [value, timeStamp] = _methods.formatDateValue(value);
        ctx.emit('change', e, value, timeStamp);
      },
      onFocus(e) {
        ctx.emit('focus', e);
        state.focused = true;
      },
      onBlur(e) {
        let { value } = e.target;
        state.focused = false;
        let timeStamp = null;
        [value, timeStamp] = _methods.formatDateValue(value);
        ctx.emit('blur', e, value, timeStamp);
      }
    };
    watch(
      () => props.format,
      (format) => {
        const separatorMatch = format.match(/[^a-zA-Z\d\s:]/);
        if (separatorMatch.length) {
          const parts = format.split(separatorMatch[0]);
          state.separator = separatorMatch[0];
          state.dateFormat = {
            year: {
              index: parts.findIndex((item) => item.match(/y/i)),
              length: parts[parts.findIndex((item) => item.match(/y/i))].length
            },
            month: {
              index: parts.findIndex((item) => item.match(/m/i)),
              length: parts[parts.findIndex((item) => item.match(/m/i))].length
            },
            day: {
              index: parts.findIndex((item) => item.match(/d/i)),
              length: parts[parts.findIndex((item) => item.match(/d/i))].length
            }
          };
        }
      },
      {
        immediate: true
      }
    );
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-date-input {
  $c: &;
  &__wrapper {
    display: flex;
    position: relative;
  }
  &__main {
    flex-grow: 1;
  }
  &__main-input {
    height: 56px;
    display: flex;
    align-items: center;
    border: 1px solid currentColor;
    padding: 0 15px;
    transition: all 0.2s linear;
  }
  &__label {
    margin-bottom: 4px;
  }
  &__input {
    background: transparent;
    border: none !important;
    outline-width: 0 !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
    color: currentColor;
    width: 100%;
    &::placeholder {
      color: $grey;
      @include h9;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
  &__side {
    margin-left: 16px;
    width: 65px;
    flex-shrink: 0;
    .e-site-button {
      width: 100%;
      min-width: auto !important;
      height: 56px !important;
    }
    &:empty {
      display: none;
    }
  }
  &__messages {
    @include h11;
    height: 40px;
    padding-top: $space-8;
    text-align: end;
  }
  &__error-msg {
    font-size: 12px;
    color: $red;
  }
  &.has-error {
    #{$c}__main {
      border-color: $red;
    }
  }
  &__success-msg {
    color: $turquoise;
  }
  &.focus {
    #{$c}__main-input {
      border-color: $grey-dark;
    }
  }
  &.has-icon {
    #{$c}__main {
      padding: 0;
    }
    #{$c}__input {
      padding-right: 20px;
    }
  }
  &__icon {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  &.reverse {
    &.has-icon {
      #{$c}__main {
        padding-right: 0;
        padding-left: 50px;
      }
    }
    #{$c}__icon {
      left: 0;
      right: unset;
    }
  }
  &.disabled {
    #{$c}__main {
      background: $grey-light;
    }
  }
  @include tablet-landscape {
    &__main-input {
      padding: 0 20px;
    }
    &__side {
      width: 100px;
    }
  }
}
</style>
