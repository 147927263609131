<script setup>
/**@typedef DealerPickerValue
 * @property {string} code
 * @property {string} text
 * @property {LotusStore} data
 * */
import { computed, getCurrentInstance, nextTick, ref, watch } from 'vue';
import { buildConfigField } from '@/utils/sitecore-utils';
const emit = defineEmits(['change']);
const props = defineProps({
  title: {
    type: String
  },
  placeholder: {
    type: String
  },
  icon: {
    type: String
  },
  selectText: {
    type: String
  },
  changeText: {
    type: String
  },
  dealers: {
    type: Array
  },
  dealerModal: {
    type: Object
  },
  /**@type DealerPickerValue*/
  value: {
    type: Object
  },
  errorMsg: {
    type: String
  },
  infoMsg: {
    type: String
  },
  successMsg: {
    type: String
  },
  required: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  }
});
const { proxy } = getCurrentInstance();
const $jss = proxy;
const dealer = ref(props.value);
/**@type Ref<DealerSelectModalRef>*/
const dealerModalRef = ref();
/**@type ComputedRef<ButtonField>*/
const selectButton = computed(() => ({
  buttonType: buildConfigField('secondary-button'),
  size: buildConfigField('small'),
  text: { value: props.selectText }
}));
const setDealers = (dealers) => {
  dealerModalRef.value?.setDealers(dealers);
};
const onSelect = async (e) => {
  const dealer = await dealerModalRef.value.open();
  emit('change', e, dealer);
};
const onChange = async (e) => {
  const dealer = await dealerModalRef.value.open();
  emit('change', e, dealer);
};
defineExpose({ setDealers });
</script>

<template>
  <div class="e-dealer-picker" :class="[{ 'has-error': !$isNullOrEmpty(props.errorMsg) }]">
    <div class="text-secondary" v-html="title" />
    <div class="flex fdc g24" v-if="$isNullOrEmpty(value?.storeName)">
      <div class="text-desc" v-html="placeholder" />
      <site-button class="e-dealer-picker__button" :fields="selectButton" @click="onSelect" v-if="!readonly" />
    </div>
    <div class="flex g8" v-else>
      <icon :svg="icon" />
      <div class="flex fdc">
        <div class="fs-14 fw-600 lh-24">{{ value.storeName }}</div>
        <div class="e-dealer-picker__button tdl hand fs-12" v-html="changeText" @click="onChange" v-if="!readonly" />
      </div>
    </div>
    <div class="e-dealer-picker__messages">
      <div class="e-dealer-picker__message error" v-if="!$isNullOrEmpty(errorMsg)" v-html="errorMsg" />
    </div>
    <dealer-select-modal v-bind="dealerModal" ref="dealerModalRef" />
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-dealer-picker {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__messages {
    height: 40px;
    font-size: 12px;
    margin-top: -12px;
  }
  &__message {
    &.error {
      color: $red;
    }
  }
}
</style>
