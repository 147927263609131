<template>
  <div class="e-label-value" :class="[{ [`theme-${theme}`]: theme }, { [`size-${size}`]: size }, bordered]">
    <div class="e-label-value__left">
      <div class="e-label-value__label" v-if="$slots.label"><slot name="label" /></div>
      <div class="e-label-value__label" v-else v-html="label" />
      <div class="e-label-value__label-desc" v-if="$slots.labelDesc"><slot name="labelDesc" /></div>
      <div class="e-label-value__label-desc" v-else v-html="labelDesc" />
    </div>
    <div class="e-label-value__right">
      <div class="e-label-value__desc" v-if="$slots.desc"><slot name="desc" /></div>
      <div class="e-label-value__desc" v-html="desc" v-else />
      <div class="e-label-value__value" v-if="$slots.default"><slot /></div>
      <div class="e-label-value__value" v-else v-html="value" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LabelValue',
  props: {
    label: {
      type: String
    },
    labelDesc: {
      type: String
    },
    labelRemark: {
      type: String
    },
    labelDescOnTop: {
      type: Boolean,
      default: false
    },
    desc: {
      type: String
    },
    value: {
      type: String
    },
    theme: {
      type: String
    },
    size: {
      type: String
    },
    bordered: {
      type: Boolean
    }
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-label-value {
  padding: 16px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  &__label {
    text-transform: capitalize;
    &-desc {
      font-size: 12px;
      color: $grey-taubmans;
      &:empty {
        display: none;
      }
    }
  }
  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__desc {
    font-size: 12px;
    margin-right: 16px;
    &:empty {
      display: none;
    }
  }
  &.bordered {
    border-top: 1px solid $grey-light;
    &:last-child {
      border-bottom: 1px solid $grey-light;
    }
  }
  &.theme {
    &-night {
      &.bordered {
        border-top: 1px solid $grey-dark;
        &:last-child {
          border-bottom: 1px solid $grey-dark;
        }
      }
    }
  }
  &.size {
    &-small {
      font-size: 12px;
      padding: 8px 0;
    }
  }
}
</style>
