import { invoke, isH5, isAppBrowser, isWx } from '@/js-bridge';
import nativeCode from '@/js-bridge/nativeCode';

/** 取得token */
function getToken(handle, loginParams, notCallbackLogin) {
  // 处理未登录的问题
  const wapperHandle = (params) => {
    const { token } = params || {};
    if (token) {
      handle(params);
    } else {
      // 去登录
      if (notCallbackLogin) {
        return;
      } else {
        login(loginParams);
      }
    }
  };

  if (isH5) {
    // 简单处理
    return wapperHandle({
      token: ''
    });
  }
  return invoke(nativeCode.GET_TOKEN, '', wapperHandle, true);
}

function getTokenAsync() {
  return new Promise((resolve) => {
    getToken((params) => {
      resolve(params);
    });
  });
}

/** 返回或关闭 当页面栈为1时为关闭页 */
function backOrClose() {
  return invoke(nativeCode.BACK_OR_CLOSE, '');
}
/**
 * 去分享
 */
function onShare(params) {
  getToken(() => {
    invoke(nativeCode.SHARE, params);
  });
}

/** 去登录 */
function login() {
  if (isH5) {
    if (isWx) {
      // MessageAspopBrowser();
    } else {
      // h5Nav.push('/pagesMobile/download/index', params);
    }
    console.log('h5登录目前不处理');
    return;
  } else {
    return invoke(nativeCode.LOGIN, '');
  }
}

/**获取DEVICE_ID**/
function getDeviceId(handle) {
  if (isH5) {
    return handle({ deviceId: '' });
  } else {
    return invoke(nativeCode.DEVICE_ID, '', handle);
  }
}

/**获取状态栏高度**/
function getStatusBarHeight(handle) {
  if (isH5) {
    return handle({
      statusBarHeight: 0
    });
  } else {
    return invoke(nativeCode.STATUS_BAR_HEIGHT, null, handle, true);
  }
}

/** 隐藏导航条 */
function hideNavBar() {
  if (isAppBrowser) {
    return invoke(nativeCode.HIDE_NAV_BAR);
  }
}

// 金融预审
function financialInquiry(params) {
  if (isH5) {
    return;
  } else {
    return invoke(nativeCode.FINANCIAL_INQUIRY, params);
  }
}
// 金融预审 选择吉致金融判断发票信息状态

function financialTaskCode(params) {
  if (isH5) {
    return;
  } else {
    return invoke(nativeCode.FINANCIAL_TASKCODE, params);
  }
}
/**关闭H5页面**/
function closeH5Page() {
  return invoke(nativeCode.CLOSE_H5_PAGE);
}

/** 检测App 当前版本对应的 methodName 是否支持 **/
function checkMethodValid(params, handle) {
  if (isH5) {
    console.log('checkMethodValid H5不处理');
    return;
  }
  return invoke(nativeCode.CHECK_METHOD_VALID, params, handle);
}

/**emira车详点击按钮事件**/
function emiraClickAction(params) {
  if (isH5) {
    console.log('emiraClickAction H5不处理');
    return;
  }
  return invoke(nativeCode.EMIRA_CLICK_ACTION, params);
}

/**eletre车详点击按钮事件**/
function eletreClickAction(params) {
  if (isH5) {
    console.log('eletreClickAction H5不处理');
    return;
  }
  return invoke(nativeCode.ELETRE_CLICK_ACTION, params);
}

/**eletre车详点击按钮事件**/
function evijaClickAction(params) {
  if (isH5) {
    console.log('evijaClickAction H5不处理');
    return;
  }
  return invoke(nativeCode.EVIJA_CLICK_ACTION, params);
}

// APP打开新页面
function openNewPage(params) {
  if (isH5) {
    return;
  } else {
    return invoke(nativeCode.OPEN_NEW_PAGE, params);
  }
}
function openApplyDetail(params) {
  if (isH5) {
    return;
  } else {
    return invoke(nativeCode.OPEN_APPLY_DETAIL, params);
  }
}
// APP打开页面
function goPage(params, handle) {
  if (isH5) {
    return;
  } else {
    return invoke(nativeCode.GO_PAGE, params, handle);
  }
}
function goAppPage(params, handle) {
  if (isH5) {
    return;
  } else {
    return invoke(nativeCode.GO_APP_PAGE, params, handle);
  }
}
//获取app版本号
function getAppVersion(params, handle) {
  if (isH5) {
    return;
  } else {
    return invoke(nativeCode.GET_APP_VERSION, params, handle);
  }
}
export default {
  getToken,
  getTokenAsync,
  backOrClose,
  login,
  onShare,
  getDeviceId,
  getStatusBarHeight,
  closeH5Page,
  financialInquiry,
  financialTaskCode,
  checkMethodValid,
  emiraClickAction,
  eletreClickAction,
  evijaClickAction,
  hideNavBar,
  openNewPage,
  openApplyDetail,
  goPage,
  goAppPage,
  getAppVersion
};
