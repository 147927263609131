<template>
  <div v-bind="$data" class="e-video-player-v2" :class="[...rootClasses]" ref="rootEl">
    <div class="e-video-player-v2__content" @click="onPlay" v-if="!$equalString(type, 'view')"></div>
    <div class="e-video-player-v2__play-btn" :class="type" @click="onPlay" v-if="playBtnVisible">
      <icon :name="iconHover ? 'hover-play' : 'play'" v-show="!playing && !showReplay" @mouseenter="(e) => iconMouseover(e)" @mouseleave="(e) => iconMouseout(e)" />
      <icon :name="iconHover ? 'hover-replay' : 'replay'" v-show="showReplay && played" @mouseenter="(e) => iconMouseover(e)" @mouseleave="(e) => iconMouseout(e)" />
    </div>
    <slot />
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, reactive, toRefs, watch, computed } from 'vue';
import videojs from 'video.js';
import { useI18n } from 'vue-i18n';
import { merge, debounce } from 'lodash';
import { gtmPush } from '@/utils/gtm-utils';
import lottie from 'lottie-web';
import animationData from '../assets/loading.json';
import svgIcons from '../svg-icons';

export default {
  name: 'VideoPlayerV2',
  emits: ['canplay', 'play', 'pause', 'ended', 'loadeddata'],
  props: {
    type: {
      type: String,
      default: 'full'
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    hasAnimation: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const { t } = useI18n();
    const state = reactive({
      rootEl: null,
      videoEl: null,
      player: null,
      playBtnVisible: true,
      inScreen: false,
      width: null,
      height: null,
      playing: null,
      played: false,
      showReplay: false,
      muted: false,
      isFullScreen: false,
      iconHover: false,
      // 设置控制条组件
      defaultOptions: {
        language: 'site',
        languages: {
          site: {
            Play: t('Play'),
            Pause: t('Pause'),
            Replay: t('Replay'),
            Fullscreen: t('Fullscreen'),
            'Non-Fullscreen': t('Non Fullscreen'),
            Mute: t('Mute'),
            Unmute: t('Unmute')
          }
        },
        controlBar: {
          children: [
            { name: 'playToggle' },
            { name: 'currentTimeDisplay' },
            { name: 'timeDivider' },
            { name: 'durationDisplay' },
            { name: 'progressControl' },
            { name: 'volumePanel' },
            { name: 'FullscreenToggle' }
          ]
        },
        playsinline: true
      }
    });
    const computes = {
      rootClasses: computed(() => [
        {
          'has-animation': props.hasAnimation,
          'in-screen': state.inScreen,
          'disable-fullscreen': props.options?.disableFullscreen ?? false,
          'disable-unmute': props.options?.disableUnmute ?? false
        }
      ])
    };
    const methods = {
      iconMouseover: debounce((e) => {
        e.preventDefault();
        state.iconHover = true;
      }, 20),
      iconMouseout: debounce((e) => {
        e.preventDefault();
        state.iconHover = false;
      }, 20),
      initPlayer() {
        if (state.player) {
          videojs(state.videoEl).dispose();
        }
        state.videoEl = document.createElement('video');
        state.videoEl.classList.add('video-js');
        state.rootEl?.appendChild(state.videoEl);
        state.playing = props.options?.autoplay ?? false;
        state.muted = props.options.muted ?? false;
        state.player = videojs(state.videoEl, merge(props.options, state.defaultOptions), function onPlayerReady() {
          state.width = this.videoWidth();
          state.height = this.videoHeight();
          state.player.on('canplay', (e) => {
            ctx.emit('canplay', this, e);
          });
          state.player.on('play', (e) => {
            ctx.emit('play', this, e);
            state.playing = true;
            state.showReplay = false;
          });
          state.player.on('pause', (e) => {
            ctx.emit('pause', this, e);
            state.playing = false;
            state.playBtnVisible = true;
            state.showReplay = false;
          });
          state.player.on('ended', (e) => {
            ctx.emit('ended', this, e);
            state.playing = false;
            state.playBtnVisible = true;
            if (!props.options?.loop) {
              state.iconHover = false;
              state.showReplay = true;
            }
          });
          state.player.on('loadeddata', (e) => {
            ctx.emit('loadeddata', this, e);
          });
          state.player.on('volumechange', (e) => {
            state.muted = e.target.player.muted();
          });
          const progressEl = state.player.el_?.querySelector('.vjs-progress-control');
          progressEl.addEventListener(
            'click',
            (e) => {
              const time = e.target.querySelector('.vjs-time-tooltip')?.innerText;
              const timeArr = time.split(':');
              const sec = timeArr.reduce((total, part, index) => {
                return total + parseInt(part) * Math.pow(60, timeArr.length - 1 - index);
              }, 0);
              state.player.currentTime(sec);
            },
            true
          );
        });
        methods.addLotusLoading();
      },
      addLotusLoading: () => {
        const loadingWrapEl = state.rootEl.querySelector('.vjs-loading-spinner');
        const loadingEl = document.createElement('div');
        loadingEl.classList.add('e-video-player__loading');
        lottie.loadAnimation({
          container: loadingEl,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: animationData
        });
        loadingWrapEl.appendChild(loadingEl);
      },
      onPlay() {
        if (state.playing) {
          state.player.pause();
          state.playing = false;
        } else {
          state.player.play().then(() => {
            state.playing = true;
            state.played = true;
          });
          gtmPush({
            event: 'video_play_icon'
          });
        }
      },
      addKeyboardListener: debounce((event) => {
        const e = event;
        if (state.isFullScreen && e) {
          switch (e.keyCode) {
            case 38:
              state.videoEl.volume !== 1 ? (state.videoEl.volume += 0.1).toFixed(1) : 1;
              break;
            case 40:
              state.videoEl.volume !== 0 ? (state.videoEl.volume -= 0.1).toFixed(1) : 1;
              break;
            case 37:
              state.videoEl.currentTime = state.videoEl.currentTime - 5;
              break;
            case 39:
              state.videoEl.currentTime = state.videoEl.currentTime + 5;
              break;
            case 75:
              methods.onPlay();
              break;
            case 77:
              state.videoEl.muted = !state.videoEl.muted;
              break;
            case 70:
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
              } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
              } else if (document.oRequestFullscreen) {
                document.oCancelFullScreen();
              } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
              } else {
                document.IsFullScreen = false;
                state.isFullScreen = false;
              }
              break;
          }
        }
      }, 20),
      checkFullScreen: () => {
        let isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        state.isFullScreen = !isFullScreen;
      }
    };
    watch(
      () => props.options,
      () => {
        methods.initPlayer();
      },
      {
        deep: true
      }
    );
    const judgeVideo = () => {
      const rect = state.rootEl?.getBoundingClientRect();
      state.inScreen = rect.top < window.innerHeight && rect.bottom > 10;
      if (state.player) {
        if (state.inScreen) {
          if (!state.playing && props.options?.autoplay) {
            state.player.play().then(() => {
              state.playing = true;
            });
          }
        } else {
          if (state.playing) {
            state.player.pause();
            state.playing = false;
          }
        }
      }
    };
    const onscroll = debounce(() => {
      judgeVideo();
    }, 50);
    watch(
      () => state.playing,
      (v) => {
        const controlBarEl = state.rootEl.querySelector('.vjs-control-bar');
        const playBtn = controlBarEl.querySelector('.vjs-play-control .vjs-icon-placeholder');
        if (v) {
          playBtn.innerHTML = svgIcons['pause-mini'];
        } else {
          playBtn.innerHTML = state.showReplay ? svgIcons['replay-mini'] : svgIcons['play-mini'];
        }
      }
    );
    watch(
      () => state.muted,
      (v) => {
        const muteBtn = state.rootEl.querySelector('.vjs-mute-control .vjs-icon-placeholder');
        muteBtn.innerHTML = v ? svgIcons['mute-mini'] : svgIcons['unmute-mini'];
      }
    );
    watch(
      () => state.isFullScreen,
      (v) => {
        const fullscreenBtn = state.rootEl.querySelector('.vjs-fullscreen-control');
        fullscreenBtn.innerHTML = v ? svgIcons['unfullscreen-mini'] : svgIcons['fullscreen-mini'];
      }
    );
    onMounted(() => {
      methods.initPlayer();
      window.addEventListener('scroll', onscroll, { passive: false });
      window.addEventListener('keyup', methods.addKeyboardListener, { passive: false });
      const fullscreenBtn = state.rootEl.querySelector('.vjs-fullscreen-control');
      fullscreenBtn.innerHTML = svgIcons['fullscreen-mini'];
      fullscreenBtn.addEventListener('click', methods.checkFullScreen, { passive: false });
      fullscreenBtn.addEventListener('touchstart', methods.checkFullScreen, { passive: false });
      judgeVideo();
    });
    onBeforeUnmount(() => {
      if (state.player) {
        if (state.playing) {
          state.player.pause();
          state.playing = false;
        }
        state.player.currentTime(0);
        state.player.dispose();
      }
      window.removeEventListener('scroll', onscroll, { passive: false });
      window.removeEventListener('keyup', methods.addKeyboardListener, { passive: false });
      const fullscreenBtn = state.rootEl.querySelector('.vjs-fullscreen-control');
      fullscreenBtn.removeEventListener('click', methods.checkFullScreen, { passive: false });
      fullscreenBtn.removeEventListener('touchstart', methods.checkFullScreen, { passive: false });
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
.e-video-player-v2 {
  $e: &;
  position: relative;
  height: 100%;
  &.has-animation {
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      height: 100%;
      background: rgba($blue-night, 0.9);
      box-shadow: 0 2px 2px $blue-night;
      transition: all 1s linear;
    }
    &.in-screen {
      &:before {
        opacity: 0;
      }
    }
  }
  .vjs-poster {
    background-size: cover;
    background-position: center;
    background-color: transparent;
  }
  &__content {
    position: absolute;
    top: 0;
    bottom: 50px;
    left: 0;
    right: 0;
    z-index: 1;
  }
  &__play-btn {
    position: absolute;
    bottom: 48px;
    right: 48px;
    z-index: 1;
    cursor: pointer;
    .e-icon {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: transparent;
    }
    &.full {
      bottom: 50%;
      right: 50%;
      transform: translate3d(50%, 50%, 0);
    }
  }
  .video-js {
    width: 100%;
    height: 100%;
    .vjs-loading-spinner {
      width: 100px;
      height: 100px;
      margin: -50px 0 0 -50px;
      border: 0;
      border-radius: 50px;
      &:before,
      &:after {
        display: none !important;
      }
    }
    .vjs-big-play-button {
      display: none;
    }
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    .vjs-control {
      &-bar {
        position: absolute;
        bottom: 8px;
        left: 0;
        right: 0;
        z-index: 3;
        background-color: transparent;
        .vjs-play-control {
          display: none;
          position: absolute;
          top: 0;
          left: 32px;
          .vjs-icon-placeholder {
            width: 25px;
            height: 25px;
            overflow: hidden;
            &:before {
              content: none;
            }
            > svg {
              transform: translate(calc(-50% + 13px), calc(-50% + 12px));
            }
          }
        }
        .vjs-current-time {
          width: fit-content;
          position: absolute;
          top: 0;
          left: 20px;
          display: block;
        }
        .vjs-time-divider {
          position: absolute;
          top: 0;
          left: 50px;
          display: inline-block;
        }
        .vjs-duration {
          width: fit-content;
          position: absolute;
          top: 0;
          left: 60px;
          display: inline-block;
        }
        .vjs-volume-panel {
          width: 40px;
          position: absolute;
          top: 0;
          right: 54px;
          .vjs-mute-control {
            .vjs-icon-placeholder {
              width: 26px;
              height: 26px;
              overflow: hidden;
              &:before {
                content: none;
              }
              > svg {
                transform: translate(calc(-50% + 13px), calc(-50% + 13px));
              }
            }
          }
        }
        .vjs-fullscreen-control {
          position: absolute;
          top: 0;
          right: 12px;
          width: 25px;
          height: 25px;
          overflow: hidden;
          &:before {
            content: none;
          }
          > svg {
            transform: translate(calc(-50% + 13px), calc(-50% + 12px));
          }
        }
        .vjs-progress-control {
          height: 2px;
          width: 100%;
          position: absolute;
          bottom: -8px;
          left: 0;
          .vjs-progress-holder {
            height: 2px;
            width: 100%;
            margin: 0;
            background-color: rgba($white, 0.3);
            .vjs-play-progress {
              background-color: $yellow;
              &:before {
                color: $yellow;
                top: -0.2em;
                opacity: 0;
              }
            }
            .vjs-load-progress {
              background: rgba($white, 0.5);
            }
          }
          &:hover {
            height: 5px;
            .vjs-progress-holder {
              height: 5px;
              .vjs-play-progress {
                &:before {
                  opacity: 1;
                }
              }
            }
          }
        }
        &:after {
          content: '';
          width: 100%;
          height: 80px;
          position: absolute;
          bottom: -12px;
          left: 0;
          z-index: -1;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
        }
      }
      &.vjs-button > .vjs-icon-placeholder:before {
        font-size: 2.4em;
        line-height: 1.26;
      }
    }
  }
  &.disable-fullscreen {
    .vjs-fullscreen-control {
      display: none !important;
    }
  }
  &.disable-unmute {
    .vjs-volume-panel {
      display: none !important;
    }
  }
  @include tablet-landscape {
    &__play-btn {
      transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
      .e-icon {
        width: 131px;
        height: 131px;
      }
      &:hover {
        .e-icon {
          transform: scale(1.2);
        }
      }
    }
    .video-js {
      .vjs-control {
        &-bar {
          animation: fade-out 4s forwards linear;
          .vjs-play-control {
            position: absolute;
            top: 0;
            left: 32px;
            display: block;
          }
          .vjs-current-time {
            width: fit-content;
            position: absolute;
            top: 0;
            left: 66px;
            display: block;
          }
          .vjs-time-divider {
            position: absolute;
            top: 0;
            left: 96px;
            display: inline-block;
          }
          .vjs-duration {
            width: fit-content;
            position: absolute;
            top: 0;
            left: 106px;
            display: inline-block;
          }
          .vjs-volume-panel {
            width: 40px;
            position: absolute;
            top: 0;
            right: 140px;
            display: flex;
            .vjs-volume-horizontal {
              width: 50px;
              opacity: 1;
            }
          }
          .vjs-fullscreen-control {
            position: absolute;
            top: 0;
            right: 32px;
          }
          &:after {
            height: 160px;
          }
        }
        &.vjs-button > .vjs-icon-placeholder:before {
          font-size: 3.2em;
          line-height: 0.9;
        }
      }
      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &:hover {
      .video-js {
        .vjs-control-bar {
          display: flex;
          animation: fade-in 0.2s forwards linear;
        }
      }
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
