<template>
  <div class="e-check-list" :class="[{ 'has-error': errorMsg }]">
    <div class="e-check-list__label" v-html="`${label}${required ? '*' : ''}`" v-if="label" />
    <div class="e-check-list__items" :style="columnCountStyle">
      <div class="e-check-list__item" v-for="(option, index) in options" :key="option.code">
        <checkbox :value="option.checked" :mandatory-text="option.text" @change="(e, checked) => onCheckChange(e, index, checked)" />
      </div>
    </div>
    <div class="e-check-list__error-box">
      <div class="e-check-list__error-msg" v-if="errorMsg">{{ errorMsg }}</div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue';
import { appendTextToHtmlEnd, canUseDOM } from '@/utils/dom-utils';
import useDevice from '@/hooks/useDevice';

export default {
  name: 'CheckList',
  emits: ['change'],
  props: {
    label: {
      type: String
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String
    },
    columnCountMobile: {
      type: Number
    },
    columnCountTablet: {
      type: Number
    },
    columnCountDesktop: {
      type: Number
    },
    labelClickable: {
      type: Boolean
    }
  },
  setup(props, ctx) {
    const { deviceComputes } = useDevice();
    const computes = {
      columnCountStyle: computed(() => {
        const count = (deviceComputes.isMobileDevice.value ? props.columnCountMobile : deviceComputes.isTabletDevice.value ? props.columnCountTablet : props.columnCountDesktop) ?? 3;
        return {
          'grid-template-columns': `repeat(${count}, 1fr)`
        };
      })
    };

    const methods = {
      onCheckChange(e, index, checked) {
        ctx.emit('change', e, index, checked);
      },
      onLabelClick(e, index, option) {
        console.log('e.target.tagName', e.target.tagName);
        if (e.target.tagName === 'A') return;
        methods.onCheckChange(e, index, option);
      }
    };
    return {
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-check-list {
  $c: &;
  position: relative;
  padding-bottom: $space-40;
  &__label {
    margin-bottom: $space-20;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: $space-20;
    grid-column-gap: $space-20;
  }
  &__error-box {
    height: 40px;
    padding-top: $space-8;
    text-align: end;
  }
  &__error-msg {
    font-size: 12px;
    color: $red;
  }
  &.has-error {
    #{$c}__label {
      color: $red;
      * {
        color: $red !important;
      }
    }
  }
}
</style>
