export default {
  // 获取token
  GET_TOKEN: 'getToken',
  // 关闭页面
  CLOSE: 'closePage',
  // 分享
  SHARE: 'share',
  // 登陆
  LOGIN: 'login',
  // 返回，当为最后一页时，关闭页面
  BACK_OR_CLOSE: 'backOrClosePage',
  //获取状态栏高度
  STATUS_BAR_HEIGHT: 'statusBarHeight',
  // 隐藏源生导航条
  HIDE_NAV_BAR: 'hideToolbar',
  //关闭H5页面
  CLOSE_H5_PAGE: 'closeH5Page',
  //金融预审
  FINANCIAL_INQUIRY: 'financialInquiry',
  //金融预审 选择吉致金融判断发票信息状态
  FINANCIAL_TASKCODE: 'financialTaskCode',
  // 检测App 当前版本对应的 methodName 是否支持
  CHECK_METHOD_VALID: 'checkMethodValid',
  //emira车详点击按钮事件
  EMIRA_CLICK_ACTION: 'emiraClickAction',
  //eletre车详点击按钮事件
  ELETRE_CLICK_ACTION: 'eletreClickAction',
  //evija车详点击按钮事件
  EVIJA_CLICK_ACTION: 'evijaClickAction',
  // 打开新页面
  OPEN_NEW_PAGE: 'openNewPage',
  //打开正审详情
  OPEN_APPLY_DETAIL: 'openApplyDetail',
  //打开app页面
  GO_PAGE: 'goPage',
  GO_APP_PAGE: 'goAppPage',
  //获取app版本号
  GET_APP_VERSION: 'getAppVersion'
};
