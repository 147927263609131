<template>
  <div class="e-site-accordion" :class="[{ [`theme-${theme}`]: theme, active, disabled }]" ref="rootEl">
    <div class="e-site-accordion__header" @click="toggle">
      <div class="e-site-accordion__title" v-if="$slots.title">
        <slot name="title" />
      </div>
      <div class="e-site-accordion__title" v-html="title" v-else />
      <div class="e-site-accordion__summary" v-if="$slots.summary">
        <slot name="summary" />
      </div>
      <div class="e-site-accordion__summary" v-html="summary" v-else />
      <icon class="e-site-accordion__icon" name="down" />
    </div>
    <div class="e-site-accordion__ext" v-if="$slots.ext"><slot name="ext" /></div>
    <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
      <div class="e-site-accordion__main" v-show="active">
        <div class="e-site-accordion__content">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { transitions } from '@/utils/transitions';
import { isNullOrWhitespace } from '@/utils/obj-utils';
import { reactive, toRefs } from 'vue';
export default {
  name: 'SiteAccordion',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String
    },
    title: {
      type: String
    },
    summary: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const methods = {
      toggle() {
        if (!state.rootEl || props.disabled) return;
        const $content = state.rootEl.querySelector('.e-site-accordion__content');
        if (isNullOrWhitespace($content.innerHTML)) return;
        ctx.emit('update:active', !props.active);
      }
    };
    const state = reactive({
      /**@type HTMLDivElement*/
      rootEl: null
    });
    return {
      ...toRefs(state),
      ...methods,
      transitions
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-site-accordion {
  $c: &;
  border-bottom: 1px solid $grey-taubmans;
  &:first-child {
    border-top: 1px solid $grey-taubmans;
  }
  &__title {
    font-size: 16px;
    text-transform: capitalize;
  }
  &__content {
    padding: 16px 0;
    &:empty {
      display: none;
    }
    .e-label-value {
      padding-left: 16px;
    }
    .e-block-summary {
      &__title {
        padding-left: 16px;
      }
      &__main {
        .e-label-value {
          padding-left: 16px;
        }
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px 16px 0;
    cursor: pointer;
    position: relative;
    gap: 24px;
  }
  &__icon {
    position: absolute;
    right: 0;
    top: calc(50% - 8px);
    height: 16px;
    transform-origin: center;
    transition: all 0.4s cubic-bezier(0.055, 0.215, 0, 1);
    svg {
      height: 16px;
    }
  }
  &__summary {
    font-size: 12px;
    color: $grey-next;
    padding-top: 2px;
    text-align: end;
  }
  &.theme {
    &-black {
      color: $white;
      border-bottom: 1px solid $grey-night;
      &:first-child {
        border-top: 1px solid $grey-night;
      }
    }
  }
  &.active {
    #{$c}__icon {
      transform: rotate(-180deg);
    }
  }
  &.disabled {
    #{$c}__icon {
      opacity: 0.2;
    }
  }
}
</style>
