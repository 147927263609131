<template>
  <div class="e-google-recaptcha" ref="rootEl" />
</template>

<script>
/**
 * @typedef GoogleRecaptchaRef
 * @property {GoogleRecaptchaExecute} getToken
 * @callback GoogleRecaptchaExecute
 * @returns {Promise<*>}
 * */
import { getCurrentInstance, onMounted, reactive, toRefs } from 'vue';
import { getGlobalConfigs, loadGRecaptcha } from '@/utils/site-utils';

export default {
  name: 'GoogleRecaptcha',
  emits: ['validated'],
  props: {
    action: {
      type: String
    }
  },
  setup(props, ctx) {
    const { proxy } = getCurrentInstance();
    const _methods = {
      async getRecaptcha() {
        const { $jss } = proxy;
        const page = $jss.routeData();
        const [gRecaptchaKey] = getGlobalConfigs(page, 'gRecaptchaKey');
        const siteKey = gRecaptchaKey?.value;
        const gRecaptcha = await loadGRecaptcha(siteKey, page.itemLanguage);
        return [gRecaptcha, siteKey];
      }
    };
    const methods = {
      getToken() {
        return new Promise((resolve) => {
          _methods.getRecaptcha().then(([gRecaptcha, siteKey]) => {
            gRecaptcha.enterprise.ready(() => {
              gRecaptcha.enterprise
                .execute(siteKey, {
                  action: props.action
                })
                .then((token) => {
                  console.log('token', token);
                  resolve(token);
                })
                .catch(() => {
                  resolve(null);
                });
            });
          });
        });
      }
    };
    const state = reactive({
      rootEl: null
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss"></style>
