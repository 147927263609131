<script setup>
import { computed, reactive } from 'vue';
import { settingValue } from '@/utils/site-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { debounce } from 'lodash';

const emit = defineEmits(['change']);
const props = defineProps({
  label: {
    type: String
  },
  placeholder: {
    type: String
  },
  description: {
    type: String
  },
  required: {
    type: Boolean
  },
  unmatchedMsg: {
    type: String
  },
  errorMsg: {
    type: String
  },
  successMsg: {
    type: String
  },
  options: {
    type: Array
  },
  value: {
    type: String
  }
});
const state = reactive({
  focused: false,
  filterValue: props.value
});
const computes = {
  rootClasses: computed(() => {
    const themeVal = settingValue(props.theme);
    return [
      {
        focus: state.focused,
        [`theme-themeVal}`]: !isNullOrEmpty(themeVal),
        'has-value': !!props.value,
        'has-error': !isNullOrEmpty(props.errorMsg),
        succeed: isNullOrEmpty(props.errorMsg) && !isNullOrEmpty(props.successMsg)
      }
    ];
  })
};
const onInput = debounce((e) => {
  emit('change', e, e.target.value);
  state.filterValue = e.target.value;
}, 100);
const onFocus = () => {
  state.focused = true;
};
const onBlur = debounce((e) => {
  if (!props.options.some((x) => new RegExp(`^${props.value}$`).test(x.code))) {
    emit('change', e, '');
  }
  state.focused = false;
}, 200);
const onOptionClick = debounce((e, option) => {
  emit('change', e, option.code);
  setFilterValue(option.code);
}, 100);
const setFilterValue = debounce((val) => {
  state.filterValue = val;
}, 600);
</script>

<template>
  <div class="e-form-pick-up" :class="computes.rootClasses">
    <div class="e-form-pick-up__label" v-html="`${label}${required ? '*' : ''}`" v-if="label" />
    <div class="e-form-pick-up__main">
      <div class="e-form-pick-up__main-input">
        <input type="text" :placeholder="placeholder" :value="value" @input="onInput" @focus="onFocus" @blur="onBlur" />
      </div>
      <transition name="options-ani">
        <div class="e-form-pick-up__options-wrapper" v-if="state.focused && !isNullOrEmpty(value)">
          <div class="e-form-pick-up__options">
            <template v-for="item in options" :key="item.code">
              <div class="e-form-pick-up__option" @click="onOptionClick($event, item)" v-if="new RegExp(`^${state.filterValue}`, 'gi').test(item.code)">{{ item.code }}</div>
            </template>
            <div class="e-form-pick-up__unmatched" v-if="!options.some((x) => new RegExp(`^${state.filterValue}`, 'gi').test(x.code))" v-html="unmatchedMsg" />
          </div>
        </div>
      </transition>
    </div>
    <div class="e-form-pick-up__description" v-html="description" v-if="description" />
    <div class="e-form-pick-up__messages">
      <div class="e-form-pick-up__error-msg" v-if="errorMsg">
        <span class="e-form-pick-up__msg" v-html="errorMsg" />
        <icon name="close-circle-solid" size="tiny" />
      </div>
      <div class="e-form-pick-up__success-msg" v-html="successMsg" v-else-if="successMsg" />
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-form-pick-up {
  $c: &;
  &__main {
    position: relative;
  }
  &__main-input {
    height: 56px;
    display: flex;
    align-items: center;
    border: 1px solid currentColor;
    padding: 0 15px;
    transition: all 0.2s linear;
    input {
      background: transparent;
      border: none !important;
      outline-width: 0 !important;
      padding: 0 !important;
      margin-bottom: 0 !important;
      color: currentColor;
      width: 100%;
      appearance: textfield;
      &::placeholder {
        color: $grey;
        @include h9;
      }
      &:focus {
        border: none;
        outline: none;
      }
    }
  }
  &__options-wrapper {
    position: absolute;
    top: 55px;
    box-shadow: 0 1px 2px rgba($white, 0.5);
    z-index: 1;
    width: 100%;
    border: 1px solid $black;
    background: $white;
  }
  &__options {
    max-height: 200px;
    overflow-y: auto;
  }
  &__option {
    height: 40px;
    line-height: 40px;
    background: $white;
    color: $black;
    padding: 0 20px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.selected {
      background: $black;
      color: $white;
    }
    &.placeholder {
      background: $white;
      color: $grey;
    }
    &:empty {
      display: none;
    }
    &:hover {
      font-weight: bold;
    }
  }
  &__unmatched {
    height: 40px;
    line-height: 40px;
    background: $white;
    color: $grey-neutral;
    padding: 0 20px;
  }
  &__messages {
    @include h11;
    height: 40px;
    padding-top: $space-8;
    text-align: end;
    .e-icon {
      align-self: center;
    }
  }
  &__error-msg {
    font-size: 12px;
    color: $red;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  &.has-error {
    #{$c}__main {
      border-color: $red;
      border-bottom-width: 2px;
    }
  }
  &.focus {
    z-index: 1;
  }
}
</style>
