import { canUseDOM } from '@/utils/dom-utils';

const handlesKey = Symbol('handle');

let lotusH5 = { [handlesKey]: {} };

const proxyHandle = (target) => {
  const handler = {
    get(target, propKey) {
      let handleTarget = target[handlesKey];
      let result = Reflect.get(handleTarget, propKey);
      if (!result) {
        throw new ReferenceError(propKey + '方法未实现');
      }
      return result;
    }
  };
  return new Proxy(target, handler);
};

const proxy = proxyHandle(lotusH5);
if (canUseDOM()) {
  window.lotusH5 = proxy;
}

// 注册未定义类型的接口，h5调用原生时，原生不存在对应的命令类型
register(
  'callMsgEmpty',
  (data) => {
    // Toast.fail('当前版本不支持~', 1);
    console.log('invoke callMsgEmpty', data);
    return data;
  },
  false
);

export function register(type, handle, isJson = true) {
  if (!type || !handle) {
    console.warn('接口注册名或处理函数不能为空');
    return;
  }
  console.log(`register ${type} success!`);
  lotusH5[handlesKey][type] = (params) => {
    if (isJson && typeof params == 'string') {
      params = JSON.parse(params);
    }
    handle.call(null, params);
  };
}
export default proxy;
