import { Link, Text, File, Placeholder, Image } from '@sitecore-jss/sitecore-jss-vue';
import Avatar from './Avatar';
import BackgroundImage from './BackgroundImage';
import BlockSummary from './BlockSummary';
import Checkbox from './Checkbox';
import CheckList from './CheckList';
import CtSelector from './CtSelector';
import CustomerTypeSelector from './CustomerTypeSelector';
import Icon from './Icon';
import SiteButton from './SiteButton';
import MenuButton from './MenuButton';
import BackButton from './BackButton';
import SiteRichText from './SiteRichText';
import SiteLink from './SiteLink';
import VideoPlayer from './VideoPlayer';
import VideoPlayerV2 from './VideoPlayerV2';
import Modal from './Modal';
import AdaptiveBackImage from './AdaptiveBackImage';
import Fade from './Fade';
import Tag from './Tag';
import Heading from './Heading';
import RadioList from './RadioList';
import FormInput from './FormInput';
import FormPickUp from './FormPickUp';
import FormTextarea from './FormTextarea';
import AccordionToggle from './AccordionToggle';
import Dropdown from './Dropdown';
import SiteRadio from './SiteRadio';
import MenuTrigger from './MenuTrigger';
import TextAnimate from './TextAnimate';
import SiteSwitch from './SiteSwitch';
import PhoneInput from './PhoneInput';
import AutocompleteInput from './AutocompleteInput';
import NumStepper from './NumStepper';
import AnimatedContent from './AnimatedContent';
import KvSlideBg from './KvSlideBg';
import Spinner from './Spinner';
import AdaptiveImage from './AdaptiveImage';
import AdaptiveMedia from './AdaptiveMedia';
import LabelValue from './LabelValue';
import FlippedNumber from './FlippedNumber';
import YoutubeVideoPlayer from './YoutubeVideoPlayer';
import YoutubeVideoField from './YoutubeVideoField';
import DateInput from './DateInput';
import NoticeModal from './NoticeModal';
import GoogleRecaptcha from './GoogleRecaptcha';
import GeeCaptcha from './GeeCaptcha';
import CaptchaInput from './CaptchaInput';
import SiteAccordion from './SiteAccordion';
import Notification from './Notification';
import SiteLoading from './SiteLoading';
import DealerRadioList from './DealerRadioList';
import DealerSelectModal from './DealerSelectModal';
import DealerSelector from './DealerSelector';
import ImageLink from './ImageLink';
import HelpContent from './HelpContent';
import FinalPayModal from './FinalPayModal';
import StoreInfo from './StoreInfo';
import TemplateString from './TemplateString';
import LinkedOutCard from './LinkedOutCard';
import IconLink from './IconLink';
import PopMenu from './PopMenu';
import DealerPicker from '@/elements/DealerPicker.vue';
export default {
  install(Vue) {
    Vue.component('Avatar', Avatar);
    Vue.component('BackgroundImage', BackgroundImage);
    Vue.component('BlockSummary', BlockSummary);
    Vue.component('Checkbox', Checkbox);
    Vue.component('CheckList', CheckList);
    Vue.component('CtSelector', CtSelector);
    Vue.component('CustomerTypeSelector', CustomerTypeSelector);
    Vue.component('DealerPicker', DealerPicker);
    Vue.component('JssLink', Link);
    Vue.component('JssText', Text);
    Vue.component('JssRichText', SiteRichText);
    Vue.component('JssFile', File);
    Vue.component('JssImage', Image);
    Vue.component('Placeholder', Placeholder);
    Vue.component('Icon', Icon);
    Vue.component('SiteLink', SiteLink);
    Vue.component('SiteButton', SiteButton);
    Vue.component('MenuButton', MenuButton);
    Vue.component('BackButton', BackButton);
    Vue.component('IconLink', IconLink);
    Vue.component('AdaptiveBackImage', AdaptiveBackImage);
    Vue.component('VideoPlayer', VideoPlayer);
    Vue.component('VideoPlayerV2', VideoPlayerV2);
    Vue.component('Modal', Modal);
    Vue.component('Fade', Fade);
    Vue.component('Tag', Tag);
    Vue.component('Heading', Heading);
    Vue.component('FormInput', FormInput);
    Vue.component('FormPickUp', FormPickUp);
    Vue.component('FormTextarea', FormTextarea);
    Vue.component('PhoneInput', PhoneInput);
    Vue.component('AutocompleteInput', AutocompleteInput);
    Vue.component('Dropdown', Dropdown);
    Vue.component('SiteRadio', SiteRadio);
    Vue.component('SiteSwitch', SiteSwitch);
    Vue.component('RadioList', RadioList);
    Vue.component('DateInput', DateInput);
    Vue.component('NumStepper', NumStepper);
    Vue.component('AccordionToggle', AccordionToggle);
    Vue.component('MenuTrigger', MenuTrigger);
    Vue.component('TextAnimate', TextAnimate);
    Vue.component('AnimatedContent', AnimatedContent);
    Vue.component('KvSlideBg', KvSlideBg);
    Vue.component('Spinner', Spinner);
    Vue.component('AdaptiveImage', AdaptiveImage);
    Vue.component('AdaptiveMedia', AdaptiveMedia);
    Vue.component('LabelValue', LabelValue);
    Vue.component('FlippedNumber', FlippedNumber);
    Vue.component('YoutubeVideoPlayer', YoutubeVideoPlayer);
    Vue.component('YoutubeVideoField', YoutubeVideoField);
    Vue.component('NoticeModal', NoticeModal);
    Vue.component('GoogleRecaptcha', GoogleRecaptcha);
    Vue.component('GeeCaptcha', GeeCaptcha);
    Vue.component('CaptchaInput', CaptchaInput);
    Vue.component('SiteAccordion', SiteAccordion);
    Vue.component('Notification', Notification);
    Vue.component('SiteLoading', SiteLoading);
    Vue.component('DealerRadioList', DealerRadioList);
    Vue.component('DealerSelectModal', DealerSelectModal);
    Vue.component('DealerSelector', DealerSelector);
    Vue.component('ImageLink', ImageLink);
    Vue.component('HelpContent', HelpContent);
    Vue.component('FinalPayModal', FinalPayModal);
    Vue.component('StoreInfo', StoreInfo);
    Vue.component('TemplateString', TemplateString);
    Vue.component('LinkedOutCard', LinkedOutCard);
    Vue.component('PopMenu', PopMenu);
  }
};
