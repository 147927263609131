<template>
  <div ref="rootEl" class="e-animated-content" v-bind="$attrs" :class="[{ animating: hideContent && animating }]" :style="rootStyles">
    <div class="e-animated-content__main" :class="[{ visible, standstill, [type]: type, 'at-front': atFront }]" @animationstart="onTransitionStart" @animationend="onTransitionEnd">
      <slot />
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, onBeforeUnmount, onMounted, reactive, toRefs, watch } from 'vue';
import { isNull } from '@/utils/obj-utils';
export default {
  name: 'AnimatedContent',
  inheritAttrs: false,
  emits: ['animate', 'animated'],
  props: {
    offset: {
      type: String,
      default: '95%'
    },
    duration: {
      type: Number,
      default: 600
    },
    delay: {
      type: Number,
      default: 0
    },
    type: {
      type: String
    },
    hideContent: {
      type: Boolean,
      default: true
    },
    ease: {
      type: String,
      default: 'ease'
    },
    turnOff: {
      type: Boolean,
      default: true
    },
    replay: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const state = reactive({
      /**@type HTMLElement*/
      rootEl: null,
      rootStyles: null,
      atFront: false,
      visible: false,
      animating: false,
      standstill: false
    });
    watch(
      () => props.replay,
      (v) => {
        if (v) {
          state.standstill = false;
          state.visible = false;
          setTimeout(() => {
            methods.checkAnimation();
          }, 20);
        }
      }
    );
    const methods = {
      onScroll(e) {
        methods.checkAnimation();
      },
      checkAnimation() {
        if (props.turnOff) {
          state.standstill = true;
          state.visible = false;
          return;
        }
        const rect = state.rootEl.getBoundingClientRect();
        const winHeight = window.innerHeight;
        let offset = 0.9 * winHeight;
        if (!isNull(props.offset)) {
          if (props.offset.endsWith('%')) {
            offset = (parseInt(props.offset) * winHeight) / 100;
          } else if (!isNaN(offset)) {
            offset = parseInt(props.offset);
          }
        }
        state.standstill = false;
        state.visible = rect.top <= offset;
      },
      onTransitionStart(e) {
        state.animating = true;
        ctx.emit('animate', e);
      },
      onTransitionEnd(e) {
        e.stopPropagation();
        state.animating = false;
        ctx.emit('animated', e);
      }
    };
    onBeforeMount(() => {
      state.rootStyles = {
        '--duration': `${props.duration}ms`,
        '--delay': `${props.delay}ms`,
        '--ease': `${props.ease}`
      };
      state.atFront = true;
    });
    onMounted(() => {
      window.addEventListener('scroll', methods.onScroll);
      window.addEventListener('resize', methods.checkAnimation);
      methods.checkAnimation();
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', methods.onScroll);
      window.removeEventListener('resize', methods.checkAnimation);
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-animated-content {
  $c: &;
  &.animating {
    overflow: hidden;
  }
  &__main {
    &.at-front {
      opacity: 0;
    }
    &.open-curtain {
      position: relative;
      opacity: 1;
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: rgba($blue-night, 1);
        box-shadow: 0 2px 2px $blue-night;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($black, 0.9);
      }
    }
    &.standstill {
      opacity: 1;
    }
    &.visible {
      transform-origin: left top;
      animation-duration: var(--duration);
      animation-fill-mode: forwards;
      animation-delay: var(--delay);
      animation-timing-function: var(--ease);
      &.skew-in {
        animation-name: skew-in;
        transform-origin: left center;
      }
      &.bottom-fade-in {
        animation-name: bottom-fade-in;
      }
      &.bottom-fade-out {
        animation-name: bottom-fade-out;
      }
      &.left-fade-in-little {
        animation-name: left-fade-in-little;
      }
      &.fade-in {
        animation-name: fade-in;
      }
      &.slide-in-left {
        animation-name: slide-in-left;
      }
      &.slide-in-right {
        animation-name: slide-in-right;
      }
      &.slide-in-up {
        animation-name: slide-in-up;
      }
      &.rotate-in-up-left-10 {
        animation-name: rotate-in-up-left-10;
        transform-origin: left bottom;
      }
      &.fade-in-top-left {
        animation-name: fade-in-top-left;
      }
      &.open-curtain {
        &:before {
          animation-name: open-curtain;
          animation-duration: var(--duration);
          animation-fill-mode: forwards;
          animation-delay: var(--delay);
        }
        &:after {
          animation-name: open-curtain-shadow;
          animation-duration: var(--duration);
          animation-fill-mode: forwards;
          animation-delay: var(--delay);
        }
      }
      &.scale-up {
        opacity: 1;
        animation-name: scale-up;
        transform-origin: center center;
      }
    }
  }
  @keyframes skew-in {
    0% {
      opacity: 0;
      transform: rotate(6deg);
    }
    100% {
      opacity: 1;
      transform: rotate(0);
    }
  }
  @keyframes bottom-fade-in {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes bottom-fade-out {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(100%);
    }
  }
  @keyframes left-fade-in-little {
    0% {
      opacity: 0;
      transform: translateX(-10px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-up {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes rotate-in-up-left-10 {
    0% {
      transform: rotate(6deg);
      opacity: 0;
    }
    100% {
      transform: rotate(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-top-left {
    0% {
      opacity: 0;
      transform: translate3d(-100%, -100%, 0);
    }
    100% {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  @keyframes open-curtain {
    0% {
      box-shadow: 0 2px 2px $blue-night;
      transform: translateY(0) translateZ(1px);
    }
    100% {
      box-shadow: 0 0 0 transparent;
      transform: translateY(-100%) translateZ(0px);
    }
  }
  @keyframes open-curtain-shadow {
    0% {
      opacity: 1;
      z-index: 1;
      filter: blur(1px);
    }
    100% {
      opacity: 0;
      filter: blur(0);
      z-index: -1;
    }
  }
  @keyframes scale-up {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
}
</style>
