<script setup>
import { onMounted, ref } from 'vue';
import lottie from 'lottie-web';
import animationData from '../assets/loading.json';
const rootEl = ref();
onMounted(() => {
  lottie.loadAnimation({
    container: rootEl.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: animationData
  });
});
</script>

<template>
  <div class="e-site-loading" ref="rootEl" />
</template>

<style lang="scss">
.e-site-loading {
  width: 120px;
  height: 120px;
}
</style>
