<template>
  <modal class="s-notice-modal" ref="modalRef" :animation="$deviceComputes.isMobileOrTablet.value ? 'bottom-slide-in' : 'bottom-fade-in'" @closed="onClosed" closable>
    <div class="s-notice-modal__title" v-html="$formatString(fields?.title.value, fData)" />
    <div class="s-notice-modal__body" v-html="$formatString(fields?.body.value, fData)" />
    <div class="s-notice-modal__buttons">
      <template v-for="buttonItem in buttonItems" :key="buttonItem.id">
        <site-button :fields="buttonItem.button" :data-code="buttonItem.buttonCode" @click="onButtonClick($event, buttonItem)" />
      </template>
    </div>
  </modal>
</template>

<script>
/**
 * @typedef NoticeModalFields
 * @property {SimpleField} title
 * @property {SimpleField} body
 * @property {ButtonField} buttons
 * */
/**
 * @typedef NoticeModal
 * @property {NoticeModalFields} fields
 * */
import { sitecoreProps } from '@/utils/constants';
import { computed, inject, reactive, toRefs } from 'vue';
import useAppStore from '@/store/appStore';
import { concatString } from '@/utils/string-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';

export default {
  name: 'NoticeModal',
  props: {
    fields: {
      type: Object
    },
    ...sitecoreProps
  },
  setup(props) {
    const appMethods = inject('appMethods');
    /**@type AppStore*/
    const appStore = useAppStore();
    const state = reactive({
      /**@type ModalRef*/
      modalRef: null,
      activeButton: null,
      onClosed: () => {}
    });
    const computes = {
      buttonItems: computed(() => {
        const { buttons } = props.fields || {};
        if (!buttons?.length) return [];
        const result = [];
        for (let buttonItem of buttons) {
          if (!buttonItem?.fields) continue;
          const { buttonCode, link, ...otherFields } = buttonItem.fields;
          const buttonCodeVal = buttonCode.value;
          result.push({
            link: link,
            buttonCode: buttonCodeVal,
            button: otherFields
          });
        }
        return result;
      }),
      fData: computed(() => {
        const { firstName, middleName, lastName, email } = appStore?.loginInfo || {};
        if (isNullOrEmpty(firstName)) return appStore?.loginInfo?.nickName;
        return {
          name: concatString([firstName, middleName, lastName], ' '),
          email
        };
      })
    };
    const methods = {
      async open() {
        return new Promise((resolve) => {
          if (!state.modalRef) resolve(null);
          state.onClosed = () => {
            resolve(state.activeButton);
            state.activeButton = null;
          };
          state.modalRef.open();
        });
      },
      async onButtonClick(e, buttonItem) {
        if (!buttonItem) return;
        state.activeButton = buttonItem;
        await state.modalRef?.close();
      }
    };
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      appStore
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-notice-modal {
  $c: &;
  $m: #{'.e-modal'};
  $btn: '.e-site-button';
  &.e-modal {
    align-items: flex-end;
    #{$m}__content {
      width: 100%;
      padding: 32px 24px 24px 24px;
    }
  }
  &__title {
    padding-bottom: 16px;
    margin-bottom: 20px;
    border-bottom: 2px solid $yellow;
  }
  &__body {
    margin-bottom: 60px;
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    gap: 24px;
    .e-site-button {
      width: 100% !important;
      &[data-code='refuse'],
      &[data-code='close'],
      &[data-code='no'] {
        color: $grey-next !important;
        #{$btn}__icon {
          display: none;
        }
      }
    }
  }
  @include tablet-landscape {
    &.e-modal {
      align-items: center;
      #{$m}__content {
        width: grid-width(8);
        padding: 40px 32px 32px 32px;
      }
    }
    &__buttons {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      .e-site-button {
        width: fit-content !important;
        flex-grow: 1;
        flex-shrink: 0;
        overflow: hidden;
      }
    }
  }
}
</style>
