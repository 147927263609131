<template>
  <teleport to="body">
    <!-- <div class="e-notification" ref="rootEl" :style="{ top: startY }"> -->
    <div class="e-notification" ref="rootEl">
      <transition-group name="list-fade" class="e-notification__content" tag="div">
        <div class="e-notification__message" :class="[item.type, `msg-${index}`]" v-for="(item, index) in messages" :key="item.message">
          <div class="e-notification__message-left">
            <icon class="e-notification__message-icon" :class="getIcon(item)" :name="getIcon(item)" />
            <div class="e-notification__message-body" v-html="item.message" />
          </div>
          <icon class="e-notification__message-close" name="close" size="tiny" @click="item.onClose($event, item)" v-if="item.type != 'normal'" />
        </div>
      </transition-group>
    </div>
  </teleport>
</template>

<script>
/**
 * @typedef NotificationRef
 * @property { ({ message: String, type: String, sticky: Boolean, delay: Number }) => Promise<void> } sendMessage
 * */
import { onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
import { equalString } from '@/utils/string-utils';
import { debounce } from 'lodash';
import { NOTIFICATION_TYPES } from '@/utils/constants';

export default {
  name: 'Notification',
  props: {
    rtl: {
      type: Boolean
    }
  },
  setup(props) {
    let depth = 0;
    const _methods = {
      checkMessagePos() {
        // let startY = 8;
        // const scrollTop = getScrollTop();
        // if (scrollTop <= 160) {
        //   const $header = document.body.querySelector('.c-header');
        //   const $dashboardMenu = document.body.querySelector('.s-dashboard-layout__header');
        //   if ($header) {
        //     startY += $header.scrollHeight;
        //   }
        //   if ($dashboardMenu) {
        //     startY += $dashboardMenu.scrollHeight;
        //   }
        // }
        // state.startY = startY;
        // state.rootEl.style.top = `${startY}px`;
      },
      onResize: debounce(() => {
        _methods.checkMessagePos();
      }, 200),
      removeMessage(item) {
        const index = state.messages.findIndex((x) => equalString(x.message, item.message) && x.type === item.type);
        if (index >= 0) {
          state.messages.splice(index, 1);
        }
      },
      onScroll: debounce(() => {
        _methods.checkMessagePos();
      }, 100)
    };
    const methods = {
      sendMessage({ message, type = NOTIFICATION_TYPES.success, sticky, delay = 3200 }) {
        return new Promise((resolve) => {
          depth += 1;
          setTimeout(() => {
            const messageTrimmed = message.trim();
            let item = state.messages.find((x) => equalString(x.message, messageTrimmed) && x.type === type);
            if (item) {
              clearTimeout(item.timeout);
              item.timeout = setTimeout(() => {
                _methods.removeMessage(item);
              }, delay);
              return;
            }
            depth -= 1;
            item = {
              type,
              message: messageTrimmed,
              onClose: ($event, _item) => {
                resolve(true);
                _methods.removeMessage(_item);
              }
            };
            state.messages.push(item);
            if (!sticky) {
              item.timeout = setTimeout(() => {
                _methods.removeMessage(item);
              }, delay);
            }
          }, depth * 200);
        });
      },
      getIcon(item) {
        let iconName = null;
        switch (item.type) {
          case NOTIFICATION_TYPES.success:
            iconName = 'notificationSuccess';
            break;
          case NOTIFICATION_TYPES.error:
            iconName = 'notificationError';
            break;
          case NOTIFICATION_TYPES.fail:
            iconName = 'notificationFail';
            break;
          case NOTIFICATION_TYPES.warn:
            iconName = 'notificationWarn';
            break;
          default:
            break;
        }
        return iconName;
      }
    };
    const state = reactive({
      rootEl: null,
      startY: 0,
      messages: []
    });
    onMounted(() => {
      _methods.checkMessagePos();
      window.addEventListener('scroll', _methods.onScroll, { passive: true });
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', _methods.onScroll);
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-notification {
  position: fixed;
  left: grid-width-m(1);
  top: 50vh;
  transform: translateY(50%);
  z-index: 15;
  width: grid-width-m(10);
  transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__message {
    position: absolute;
    margin: 0 auto;
    padding: 14px 16px;
    border: 1px solid #4f4f4f;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    background: radial-gradient(60.41% 90% at 49.82% 140%, rgba(46, 46, 46, 0.64) 0%, rgba(0, 0, 0, 0.8) 100%);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    border-radius: 2px;
    &-left {
      display: flex;
      align-items: flex-start;
      // align-items: center;
    }
    &-body {
      font-size: 12px;
      color: $white;
      line-height: 24px;
    }
    &-icon {
      margin-right: 8px;
      width: 24px;
      height: 24px;

      &.notificationError {
        svg {
          fill: #ff3837;
        }
      }

      .notificationSuccess {
        svg {
          fill: #00b148;
        }
      }

      .notificationFail {
        svg {
          fill: #ffa900;
        }
      }

      .notificationWarn {
        svg {
          fill: #fff200;
        }
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
    &-close {
      cursor: pointer;
      margin-left: 24px;
      color: $white;
    }
    &.error {
      color: $white;
    }
    &.success {
      color: $white;
    }
    & + & {
      margin-top: 8px;
    }
  }
  .list-fade-enter-from {
    opacity: 0;
  }
  .list-fade-leave-to {
    opacity: 0;
    transform: scaleY(0.1) translate(0, -30px);
  }
  .list-fade-leave-active {
    position: absolute;
  }
  @include tablet-landscape {
    left: grid-width(2);
    width: grid-width(20);
    &__message {
      &-close {
        // margin-top: 4px;
      }
    }
  }
}
</style>
